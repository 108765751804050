import React, { useContext, createContext, cloneElement } from 'react';
import styled from '@emotion/styled';
import { Theme } from '../../theme';
import { motion, AnimatePresence, AnimateSharedLayout } from 'framer-motion';

export interface TabsRenderProps {
  activeTab: number;
}

interface TabsContextParams {
  activeTab: number;
  setActiveTab: Function;
}

interface TabPanelProps {
  tabIndex?: number;
  children: React.ReactNode;
}

interface TabProps {
  tabIndex?: number;
  children: React.ReactNode;
}

const TabsContext = createContext<TabsContextParams>({
  activeTab: 0,
  setActiveTab: () => {},
});

const StyledTabsList = styled('ul')<{ theme: Theme }>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
}));

const StyledTab = styled('li')<{ theme: Theme; isActive: boolean }>(({ theme, isActive }) => ({
  width: '100%',
  paddingBottom: theme.gutters.base,
  borderBottom: isActive ? `1.5px solid ${theme.palette.main.brand}` : theme.border.checkbox,
  ':hover': {
    color: theme.palette.text.subtle,
  },
  cursor: 'pointer',
}));

const StyledTabPanel = styled(motion.div)<{ theme: Theme }>(({ theme }) => ({
  paddingTop: theme.gutters.base * 4,
}));

export const Tabs: React.FunctionComponent = ({ children }) => {
  const [activeTab, setActiveTab] = React.useState(0);

  if (typeof children === 'function') {
    return <TabsContext.Provider value={{ activeTab, setActiveTab }}>{children({ activeTab })}</TabsContext.Provider>;
  }

  return <TabsContext.Provider value={{ activeTab, setActiveTab }}>{children}</TabsContext.Provider>;
};

export const TabsList: React.FC = ({ children }) => {
  const cloned = React.Children.map(children, (child, tabIndex) => {
    const tab = child as React.ReactElement;
    return cloneElement(tab, { ...tab.props, tabIndex });
  });

  return <StyledTabsList>{cloned}</StyledTabsList>;
};

export const Tab: React.FunctionComponent<TabProps> = ({ children, tabIndex }) => {
  const { activeTab, setActiveTab } = useContext(TabsContext);

  return (
    <StyledTab isActive={activeTab === tabIndex} onClick={() => setActiveTab(tabIndex)}>
      {children}
    </StyledTab>
  );
};

export const TabsPanel: React.FC = ({ children }) => {
  const cloned = React.Children.map(children, (child, tabIndex) => {
    const tab = child as React.ReactElement;
    return cloneElement(tab, { ...tab.props, tabIndex });
  });

  return <div>{cloned}</div>;
};

export const TabPanel: React.FC<TabPanelProps> = ({ children, tabIndex }) => {
  const { activeTab } = useContext(TabsContext);

  return activeTab === tabIndex ? <StyledTabPanel>{children}</StyledTabPanel> : null;
};

export default Tabs;

import qs from 'querystring';
import { SetupIntent } from '@stripe/stripe-js';
import { action, computed, makeObservable, observable } from 'mobx';
import StoreBase from './StoreBase';
import { formatPrice } from './CheckoutStore';
import { editPayment, getTransactionInfo } from '../api/api';
import { Charge, ChargeStatus, InvoiceData, PaymentMethodName, SubmitPaymentRequest } from '../api/api.schema';
import { format } from 'date-fns';
import { cond, constant, stubTrue } from 'lodash/fp';

export interface InvoiceItem {
  description: string;
  currency: string;
  quantity: number;
  price: string;
  amount: string;
}

export type InvoiceStoreStatus =
  | 'loading'
  | 'error'
  | 'loaded'
  | 'alreadyPaid'
  | 'paymentInProcess'
  | 'paymentError'
  | 'paid'
  | 'canceled';

export type InvoiceStoreErrors = 'noCheckoutOrInvoiceProvided' | 'errorFetching';
const urlParams = new URLSearchParams(window.location.search);

class InvoiceStore extends StoreBase {
  public invoiceData: InvoiceData | null = null;
  public status: InvoiceStoreStatus = 'loading';
  public error: InvoiceStoreErrors | null = null;
  public items: InvoiceItem[] = [];
  public chargeId: string | undefined = urlParams.get('chargeId') ?? undefined;
  public currentCharge: Charge | undefined = undefined;

  constructor() {
    super();
    makeObservable(this, {
      // Observables
      status: observable,
      error: observable,
      // @ts-ignore
      invoiceData: observable,
      items: observable,
      chargeId: observable,
      currentCharge: observable,

      // Computed
      isUnableToFindInvoice: computed,
      id: computed,
      bankTransfer: computed,
      allowedPaymentMethods: computed,

      // Actions
      start: action,
      setStatus: action,
      setError: action,

      // XHR -
      getInvoiceData: action,
      payWithCreditCard: action,
    });
  }

  public start = async () => {
    try {
      const queryParams = qs.parse(window.location.search);

      const checkoutToken = queryParams['?cid'] as string;
      this.rootStore.checkout.checkoutToken = checkoutToken;

      await this.rootStore.checkout.login();
      await Promise.all([
        this.rootStore.checkout.getCheckoutData(),
        this.rootStore.pay.getChargesData(),
        this.rootStore.checkout.getPaymentMethods(),
      ]);

      if (this.chargeId) {
        this.currentCharge = this.rootStore.pay.charges.find(
          (charge: Charge) => String(charge.id) === String(this.chargeId)
        );
      }

      const stateFromPaymentStatus = cond<ChargeStatus | undefined, InvoiceStoreStatus>([
        [(status?: ChargeStatus) => status === 'canceled', constant('canceled')],
        [(status?: ChargeStatus) => status === 'charged' || status === 'refunded', constant('alreadyPaid')],
        [stubTrue, constant('loaded')],
      ]);

      this.setStatus(stateFromPaymentStatus(this.currentCharge?.status));

      await this.getInvoiceData();

      this.rootStore.checkout.setCreditCardMode('form');
      this.items = this.setItems();
      this.rootStore.checkout.creditCard.setShowCardForm(true);
      this.rootStore.checkout.generateClientSecret();
    } catch (err) {
      console.error('err', err);
      this.setStatus('error');
      this.setError('errorFetching');
    }
  };

  public setStatus = (state: InvoiceStoreStatus) => {
    this.status = state;
  };

  public setError(error: InvoiceStoreErrors) {
    this.error = error;
  }

  public getInvoiceData = async () => {
    try {
      const { data } = await getTransactionInfo(this.rootStore.checkout.checkoutToken);
      const checkoutData = this.rootStore.checkout.checkoutData;
      if (!checkoutData) return;
      if (!this.currentCharge) return;
      const invoiceData: InvoiceData = {
        invoice: {
          bankTransfer: data.bank.bankTransferDetails,
          prettyToken: `invoice-${this.currentCharge?.id}`,
          pdfLink: this.currentCharge?.invoicePdfLink,
          chargeDate: this.currentCharge?.chargeDate,
          instructions: { instructions: data.check.instructions, mailAddress: data.check.mailAddress },
          isPaid: this.currentCharge?.status === 'charged',
        },
      };

      this.invoiceData = invoiceData;
    } catch (err) {
      this.setStatus('error');
      this.setError('errorFetching');
      console.error('err', err);
    }
  };

  public payWithCreditCard = async (intent: SetupIntent) => {
    const { checkout } = this.rootStore;

    this.setStatus('paymentInProcess');

    if (!intent) {
      this.setStatus('paymentError');

      return;
    }

    try {
      const paymentRequest: SubmitPaymentRequest = {
        paymentGatewayId: intent.payment_method as string,
        gateway: 'stripe-cc',
        paymentTerm: 'IMMEDIATE',
        paymentTermNumericVal: 0,
      };

      await editPayment(this.chargeId, paymentRequest);

      this.setStatus('paid');
    } catch (error) {
      console.error(error);
      this.setStatus('paymentError');
    }
  };

  public payWithBank = async (intent: string) => {
    this.setStatus('paymentInProcess');

    if (!intent) {
      this.setStatus('paymentError');

      return;
    }

    try {
      const paymentRequest: SubmitPaymentRequest = {
        paymentGatewayId: intent as string,
        gateway: 'stripe-ach',
        paymentTerm: 'IMMEDIATE',
        paymentTermNumericVal: 0,
      };

      await editPayment(this.chargeId, paymentRequest);

      this.setStatus('paid');
    } catch (error) {
      console.error(error);
      this.setStatus('paymentError');
    }
  };

  setItems(): Array<InvoiceItem> {
    const { transactions, totalShipping } = this.rootStore.checkout;

    if (!transactions) {
      return [];
    }

    const formatPriceUsingCurrency = formatPrice(this.rootStore.checkout.currency);
    const items = transactions.reduce((acc, { lineItems }) => {
      lineItems?.map((lineItem) => {
        const quantity = lineItem.quantity || 0;
        const amount = (lineItem.price * quantity).toFixed(2);

        acc.push({
          description: lineItem.title,
          currency: 'USD',
          quantity: quantity,
          price: formatPriceUsingCurrency(lineItem.price),
          amount: formatPriceUsingCurrency(amount),
        });
      });

      return acc;
    }, [] as Array<InvoiceItem>);

    return items;
  }

  get checkInstructions(): string {
    return this.invoiceData?.invoice?.instructions.instructions ?? '';
  }

  get prettyToken(): string {
    return this.invoiceData?.invoice?.prettyToken ?? '';
  }

  get pdfLink(): string | undefined {
    return this.currentCharge?.invoicePdfLink;
  }

  get allowedPaymentMethods(): PaymentMethodName[] {
    const selectedPaymentMethod = this.rootStore.checkout.checkoutData?.selectedPaymentMethod;
    const paymentMethods = this.rootStore.checkout.paymentMethodsData?.allowedPaymentMethods;
    const termPaymentMethods = this.rootStore.checkout.paymentMethodsData?.allowedTermsPaymentMethods;
    const allowedPaymentMethods = selectedPaymentMethod === 'payWithTerms' ? termPaymentMethods : paymentMethods;
    return (allowedPaymentMethods || []).map((paymentMethod) => paymentMethod.name);
  }

  get chargeDate(): string {
    const chargeDate = this.currentCharge?.chargeDate ?? this.invoiceData?.invoice?.chargeDate;
    return chargeDate ? format(new Date(chargeDate), 'MMMM dd, yyyy') : '';
  }

  get chargeCreatedAt(): string {
    const chargeDate = this.currentCharge?.createdAt;
    return chargeDate ? format(new Date(chargeDate), 'MMMM dd, yyyy') : '';
  }

  get bankTransfer() {
    if (!this.invoiceData) {
      return;
    }
    return this.invoiceData.invoice.bankTransfer;
  }

  get id(): string | undefined {
    return this.chargeId;
  }

  get isUnableToFindInvoice(): boolean {
    return this.status === 'error' && this.error === 'noCheckoutOrInvoiceProvided';
  }

  get isErrorFetching(): boolean {
    return this.status === 'error' && this.error === 'errorFetching';
  }
}

export default InvoiceStore;

import React, { PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { Theme } from '../../theme';
export const StyledSpan = styled('span')<{ theme: Theme }>(({ theme }) => ({
  textDecoration: 'underline',
  cursor: 'pointer',
}));

export const ContactSupport = ({ children }: PropsWithChildren<{}>) => {
  return (
    <StyledSpan onClick={() => window.Intercom('show')} data-analytics-id="qualification-support-clicked">
      {children}
    </StyledSpan>
  );
};

import React, { VFC } from 'react';
import { StepCard } from '../../../../../Components/StepCard';
import BusinessSvg from '../../../../../assets/business.svg';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Box, Grid } from '@material-ui/core';
import { StyledTextField } from '../../../../../../ui-lib/TextField/StyledTextField';
import * as Yup from 'yup';
import { yupOptionalPhoneFieldSchema } from '../../../forms/phone.utils';
import { StyledSelect } from '../../../../../Components/StyledSelect';
import { InfoAdornment } from '../Wrappers';
import { getYupTestValidationForFromCountryConfig } from '../../../forms/validation.utils';
import { StyledNumberInput } from '../../../../../Components/StyledNumber';
import { yupDomainFieldSchema } from '../../../forms/domain.validation.util';
import { getCountryConfig } from '../../../countries/countries.utils';
import { COUNTRIES_CODES_MAP } from '../../../countries/countries';
import { BusinessInfoProps } from '../../../hooks/useVendorOnboarding/vendor-onboarding.types';
import { CountryCode, CountryFields } from '../../../countries/countries.interfaces';
import _ from 'lodash';

export const yupBusinessInfoValidationSchema = {
  country: Yup.string().required('Required'),
  emailAddress: Yup.string().trim().required('Required').email('Invalid email address'),
  name: Yup.string().required('Required'),
  businessDomain: yupDomainFieldSchema,
  dba: Yup.string(),
  ein: Yup.string().required('Required').test(getYupTestValidationForFromCountryConfig('ein')),
  phone: yupOptionalPhoneFieldSchema.test(getYupTestValidationForFromCountryConfig('phone')),
};

export const BUSINESS_INFO_DEFAULT_VALUES: BusinessInfoProps = {
  country: COUNTRIES_CODES_MAP[0].value,
  emailAddress: '',
  name: '',
  businessDomain: '',
  ein: '',
  phone: '',
  dba: '',
};

export type OnCountryChange = (updatedCountryCode: CountryCode) => void;

export interface BusinessInfoStepProps {
  onCountryChange?: OnCountryChange;
  fieldsRules?: Partial<CountryFields>;
}

export const BusinessInfo: VFC<BusinessInfoStepProps> = ({ onCountryChange, fieldsRules }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<BusinessInfoProps>();

  const country = useWatch({
    control,
    name: 'country',
  });

  const fields = _.merge(getCountryConfig(country), fieldsRules);

  const EinComponent = fields.ein?.Component ?? StyledNumberInput;

  return (
    <StepCard iconSrc={BusinessSvg} headerText={['Help us get to know', 'your business better']}>
      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <Box display="flex" flexDirection="column" height="100%">
            <StyledSelect
              name="country"
              items={COUNTRIES_CODES_MAP}
              error={!!errors.country?.message}
              label="Country"
              onChange={onCountryChange}
              {...fields.country}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="emailAddress"
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                {...field}
                inputRef={field.ref}
                margin="normal"
                fullWidth
                variant="standard"
                placeholder="Company email"
                label="test@business.com"
                error={Boolean(error)}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="name"
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                {...field}
                inputRef={field.ref}
                margin="normal"
                fullWidth
                variant="standard"
                placeholder="Legal business name"
                label="My Business"
                error={Boolean(error)}
                helperText={error?.message}
                InputProps={{
                  endAdornment: <InfoAdornment lines={['Must match the name listed', 'on your tax documents']} />,
                }}
                {...fields.name}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="dba"
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                {...field}
                inputRef={field.ref}
                margin="normal"
                fullWidth
                variant="standard"
                placeholder="DBA (Optional)"
                label="DBA"
                error={Boolean(error)}
                helperText={error?.message}
                InputProps={{
                  endAdornment: (
                    <InfoAdornment
                      lines={['The operating name of your company,', 'if different from the legal name']}
                    />
                  ),
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="ein"
            render={({ field, fieldState: { error } }) => (
              <EinComponent
                {...field}
                inputRef={field.ref}
                defaultValue={''}
                margin="normal"
                fullWidth
                variant="standard"
                pattern={fields.ein?.format}
                {...fields.ein}
                error={Boolean(error)}
                helperText={error?.message}
                InputProps={{
                  endAdornment: (
                    <InfoAdornment
                      lines={[
                        'If you use your social security number for',
                        'business tax purposes, you can enter',
                        'that instead',
                      ]}
                    />
                  ),
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="businessDomain"
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                {...field}
                inputRef={field.ref}
                margin="normal"
                fullWidth
                variant="standard"
                placeholder="Business website"
                label="www.yoursite.com"
                error={Boolean(error)}
                helperText={error?.message}
                InputProps={{
                  endAdornment: (
                    <InfoAdornment
                      lines={['You can also share an app store link or ', 'a business social media profile']}
                    />
                  ),
                }}
              />
            )}
          />
        </Grid>
      </Grid>
    </StepCard>
  );
};

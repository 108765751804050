import React, { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  BUSINESS_INFO_DEFAULT_VALUES,
  BusinessInfo,
  OnCountryChange,
  yupBusinessInfoValidationSchema,
} from './Steps/BusinessInfo';
import qs from 'qs';
import {
  BANK_ACCOUNT_DETAILS_DEFAULT_VALUES,
  BankAccountDetails,
  makeBankAccountDetailsDefaultValues,
  yupBankAccountDetailsValidationSchema,
} from './Steps/BankAccountDetails';
import { COUNTRIES_MAP } from '../../countries/countries';
import { Box } from '@material-ui/core';
import { Body } from './Wrappers';
import { QualificationButton } from '../../../../../sections/qualification/QualificationButton';
import _ from 'lodash';
import {
  BILLING_ADDRESS_DEFAULT_VALUE,
  BillingAddress,
  yupBillingAddressValidationSchema,
} from './Steps/BillingAddress';
import { OnboardingForm } from './VendorOnboardingForm';
import { PostVendor, VendorOnboardingProps } from '../../hooks/useVendorOnboarding/vendor-onboarding.types';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { transformVendor } from '../../forms/vendor.utils';

const createValidationSchema = () =>
  Yup.object().shape({
    ...yupBusinessInfoValidationSchema,
    address: yupBillingAddressValidationSchema,
    bankAccountDetails: yupBankAccountDetailsValidationSchema,
  });

const DEFAULT_VALUES: VendorOnboardingProps = {
  ...BUSINESS_INFO_DEFAULT_VALUES,
  address: BILLING_ADDRESS_DEFAULT_VALUE,
  bankAccountDetails: BANK_ACCOUNT_DETAILS_DEFAULT_VALUES,
};

const extractParamsFromUrl = (exclude: string) => {
  const { [exclude]: token, ...params } = qs.parse(window.location.search.slice(1), { allowDots: true });
  return params;
};

const getDefaultValues = () => {
  const values = extractParamsFromUrl('token');
  if (values.country && !Object.keys(COUNTRIES_MAP).includes(values.country.toString())) {
    delete values.country;
  }
  return _.merge(DEFAULT_VALUES, values);
};

interface VendorFormProps {
  onSubmitDetails: PostVendor;
  isSubmitLoading: boolean;
}

export const CreateVendorForm: FC<VendorFormProps> = ({ onSubmitDetails, isSubmitLoading }) => {
  const methods = useForm<VendorOnboardingProps>({
    resolver: yupResolver(createValidationSchema()),
    shouldFocusError: true,
    defaultValues: getDefaultValues(),
  });

  const { handleSubmit, setValue, clearErrors } = methods;

  const onCountryChange: OnCountryChange = (updatedCountryCode) => {
    setValue('ein', '');
    setValue('address', { ...BILLING_ADDRESS_DEFAULT_VALUE, countryCode: updatedCountryCode });
    setValue('bankAccountDetails', makeBankAccountDetailsDefaultValues(updatedCountryCode));
    clearErrors();
  };

  const onSubmit = handleSubmit(async (data) => {
    const transformedData = transformVendor(data);
    await onSubmitDetails(transformedData);
  });

  return (
    <OnboardingForm>
      <FormProvider {...methods}>
        <Body>
          <BusinessInfo onCountryChange={onCountryChange} />
          <BillingAddress />
          <BankAccountDetails />
          <Box display="flex" justifyContent={'flex-end'} mb={5}>
            <QualificationButton onClick={onSubmit} disabled={isSubmitLoading}>
              {isSubmitLoading ? 'Loading' : 'Submit'}
            </QualificationButton>
          </Box>
        </Body>
      </FormProvider>
    </OnboardingForm>
  );
};

import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import CheckoutStore from './../../../stores/CheckoutStore';
import { useStore } from '../../../stores/setupContext';
import { Theme } from '../../../theme';
import DetailsTable from '../../invoice/InvoiceDetails/DetailsTable';
import { css, Global } from '@emotion/core';
import { Typography } from '../../../ui-lib';
import { DetailsWrap, Link } from '../../common/SectionDetails';
import { cond } from 'lodash/fp';

export interface StandAloneProps {
  children?: React.ReactNode;
}

export interface DetailsProps {
  checkoutToken: CheckoutStore['checkoutToken'];
  buyerName: CheckoutStore['buyerName'];
  buyerBusinessName: CheckoutStore['buyerBusinessName'];
  buyerEmail: CheckoutStore['buyerEmail'];
  createdDate: CheckoutStore['createdDate'];
}

export const Container = styled('div')<{ theme: Theme }>(({ theme }) => ({
  padding: theme.gutters.base * 5,
  flexDirection: 'row',
  height: 'auto',
  minHeight: '100%',
  backgroundColor: theme.palette.main.purpleGray,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'baseline',
  '@media (max-width: 700px)': {
    flexDirection: 'column',
    height: 'auto',
    width: 'fit-content',
    paddingLeft: theme.gutters.base * 2,
    paddingRight: theme.gutters.base * 2,
  },
}));

export const DetailsContainer = styled('div')<{ theme: Theme }>(({ theme }) => ({
  width: '80%',
  marginLeft: '10%',
  height: '90%',
  '@media (max-width: 700px)': {
    marginLeft: '0%',
    width: '100%',
  },
}));

export const Payments = styled('div')<{ theme: Theme }>(({ theme }) => ({
  width: '60%',
  marginRight: '10%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '@media (max-width: 700px)': {
    width: '100%',
    alignItems: 'flex-start',
    marginRight: 0,
  },
}));

export const ContactUs = styled('div')<{ theme: Theme }>(({ theme }) => ({
  padding: '1rem',
  fontSize: '16px',
  color: '#9fa2b4',
  width: '70%',
  margin: '0 auto',
  '@media (max-width: 700px)': {
    width: '100%',
  },
}));

export const DetailsInfoBox = styled('div')<{ theme: Theme }>(({ theme }) => ({
  margin: '2em 0',
  padding: '1.5rem',
  backgroundColor: 'white',
  border: '2px solid #e3e4ed',
  width: '80%',
  borderRadius: '8px',
  '@media (max-width: 700px)': {
    width: '90%',
    padding: '1.5rem',
    backgroundColor: 'white',
    border: '2px solid #e3e4ed',
  },
}));

export const Items = styled('div')<{ theme: Theme }>(({ theme }) => ({
  width: '70%',
}));

export const StandAlone: React.FunctionComponent<StandAloneProps> = ({ children }) => {
  const { checkout } = useStore();
  const {
    checkoutToken,
    buyerName,
    buyerBusinessName,
    buyerEmail,
    createdDate,
    checkoutMode,
  }: CheckoutStore = checkout;

  const style =
    checkoutMode === 'standalone' &&
    css`
      iframe[id^='plaid-link-iframe'] {
        width: 50%;
        right: 0 !important;
        left: auto !important;
        background-color: transparent !important;
      }
    `;

  return (
    <Container>
      <Global styles={style} />
      <DetailsContainer>
        <Details
          checkoutToken={checkoutToken}
          buyerName={buyerName}
          buyerBusinessName={buyerBusinessName}
          buyerEmail={buyerEmail}
          createdDate={createdDate}
        />
      </DetailsContainer>
      <Payments>
        {children}
        <ContactUs>
          <Typography {...{ center: true }}>
            If you have any questions, contact Balance at &nbsp;
            <Link {...{ href: 'mailto:support@getbalance.com' }}>support@getbalance.com</Link> or call&nbsp;
            <Link {...{ href: 'tel:(906) 914-4635' }}>(906) 914-4635</Link>
          </Typography>
        </ContactUs>
      </Payments>
    </Container>
  );
};

const Observed = observer(StandAlone);

const WithStoreConnection = () => {
  return <Observed />;
};

export default observer(WithStoreConnection);

const Details = ({ checkoutToken, buyerName, buyerBusinessName, buyerEmail, createdDate }: DetailsProps) => {
  const forUser = cond([
    [() => !!(buyerBusinessName && buyerName), () => buyerName + ', ' + buyerBusinessName],
    [() => !!buyerBusinessName, () => buyerBusinessName],
    [() => !!buyerName, () => buyerName],
    [() => true, () => buyerEmail],
  ])(undefined);

  return (
    <>
      <DetailsWrap>
        <Typography {...{ variant: 'body1Bold' }}>Transaction #{checkoutToken}</Typography>
        <Typography {...{ variant: 'body1' }}>For: {forUser}</Typography>
        <Typography {...{ variant: 'body1' }}>Created on: {createdDate}</Typography>
      </DetailsWrap>
      <DetailsInfoBox>
        <b>Qualify for terms</b>
        <br />
        <span>
          To pay this transaction on net terms, we’ll need to learn more about you.
          <br />
          Follow the dialog to link your bank for an instant approval.
        </span>
      </DetailsInfoBox>
      <Items>
        <b>ITEMS</b>
        <DetailsTable />
      </Items>
    </>
  );
};

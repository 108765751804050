import * as React from 'react';
import styled from '@emotion/styled';
import { Theme } from '../../theme';
import { flexBetweenCenter } from '../../styles/common';

export interface OptionProps {}

export const Option = styled('li')<{ theme: Theme; isSelected: boolean; isDisabled?: boolean; isColumn?: boolean }>(
  ({ theme, isSelected, isDisabled, isColumn }) => ({
    transition: 'box-shadow 300ms ease-in',
    borderRadius: theme.radius.box,
    padding: `${theme.gutters.base * 3}px ${theme.gutters.base * 3}px`,
    boxShadow: isSelected
      ? `inset 0px 0px 0px 2px ${theme.palette.main.primary}`
      : `inset 0px 0px 0px 1px ${theme.palette.blacks.eta}`,
    ...(!isSelected && {
      '&: hover': {
        boxShadow: `inset 0px 0px 0px 1px ${theme.palette.blacks.alpha}`,
      },
    }),
    cursor: 'pointer',
    ...(!isColumn && { ...flexBetweenCenter }),
    ...(isDisabled && {
      backgroundColor: `#F5F5F5`,
      border: `1px solid ${theme.palette.blacks.eta}`,
      cursor: 'not-allowed',
      '& p': { color: theme.palette.blacks.zeta },
      '&: hover': {
        boxShadow: `inset 0px 0px 0px 1px ${theme.palette.blacks.eta}`,
      },
    }),
  })
);

export default Option;

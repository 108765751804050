import { SetupIntent } from '@stripe/stripe-js';
import { action, computed, makeObservable, observable } from 'mobx';
import CheckoutStore from '../CheckoutStore';

class CreditCardModel {
  public showCardForm: boolean = false;
  public setupIntent: any;
  private store: CheckoutStore;
  private form: any = {
    postalCode: '',
    isComplete: false,
  };
  constructor(store: CheckoutStore) {
    makeObservable(this, {
      // @ts-ignore
      form: observable,
      showCardForm: observable,
      canSubmit: computed,
      setFormComplete: action,
      setShowCardForm: action,
    });

    this.store = store;
  }

  setFormComplete = (flag?: boolean) => {
    if (typeof flag === 'undefined') {
      this.form.isComplete = true;
    } else {
      this.form.isComplete = flag;
    }
  };

  setShowCardForm = (flag?: boolean) => {
    if (typeof flag === 'undefined') {
      this.showCardForm = !this.showCardForm;
    } else {
      this.showCardForm = flag;
    }
  };

  get canSubmit(): boolean {
    return this.form.isComplete;
  }

  /**
   * For the case where there are no cards in the system.
   */
  onFreshlyCreatedCardSuccess = async (setupIntent: SetupIntent) => {
    await this.store.setSetupIntent(setupIntent);
    const cards = this.store.creditCardList;
    this.store.setSelectedCreditCard(cards[0]);
    this.store.overrideNextButton(null);
    this.store.setSelectedFundingInstrument('cardPayment');
    !this.store.isPayingWithTerms && this.store.setSelectedPaymentTerm('payNow');
    this.store.setPhase('summary');
    this.setShowCardForm(false);
    this.store.clearClientSecret();
    this.store.generateClientSecret();
  };

  onAddingACardToExistingCards = async (setupIntent: SetupIntent) => {
    await this.store.setSetupIntent(setupIntent);
    this.store.overrideNextButton(null);
    this.setShowCardForm(false);
    this.store.clearClientSecret();
    this.store.generateClientSecret();
  };
}

export default CreditCardModel;

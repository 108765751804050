import * as Yup from 'yup';
import { yupEINFieldSchemaNotRequired } from './ein.validation.util';

const invalidPhoneNumberError = 'Please enter a valid phone number';

export const createSmbValidationSchema = () =>
  Yup.object().shape({
    businessName: Yup.string().required('Required'),
    businessType: Yup.string().required('Required'),
    monthlySales: Yup.number().required('Required'),
    website: Yup.string().required('Required'),
    ein: yupEINFieldSchemaNotRequired,
    resaleNumber: Yup.string().nullable(),
    dontHaveEin: Yup.boolean().nullable(),
    dontHaveResaleNumber: Yup.boolean().nullable(),
    businessAddress: Yup.string().required('Required'),
    address2: Yup.string().nullable(),
    city: Yup.string().required('Required'),
    state: Yup.string().required('Required'),
    zipCode: Yup.string().required('Required'),
    googleMapsLink: Yup.string().nullable(),
    mobilePhoneNumber: Yup.string().required('Required').min(17, invalidPhoneNumberError),
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    ownerAddress: Yup.string().required('Required'),
    ownerAddress2: Yup.string().nullable(),
    ownerCity: Yup.string().required('Required'),
    ownerState: Yup.string().required('Required'),
    ownerZipCode: Yup.string().required('Required'),
  });

import * as React from 'react';
import styled from '@emotion/styled';
import { Theme } from '../../theme';
import { InnerScroll, LoaderBlown } from './../../ui-lib';

export interface CheckoutLayoutProps {
  header?: React.ReactNode;
  content: React.ReactNode;
  buttonGroup?: React.ReactNode;
  isLoading?: boolean;
}

const Layout = styled('div')<{ theme: Theme }>(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.site,
  display: 'grid',
  borderRadius: theme.radius.card,
  gridTemplateColumns: '1fr',
  gridTemplateRows: 'min-content 1fr auto',
  height: 780,
  maxHeight: '90%',
  [`@media (max-width: 480px)`]: {
    height: '100%',
    maxHeight: '100%',
    borderRadius: 0,
  },
  [`@media only screen and (min-device-width: 480px) 
                   and (max-device-width: 640px) 
                   and (orientation: landscape)`]: {
    height: '100%',
    maxHeight: '100%',
    borderRadius: 0,
    maxWidth: 'unset',
    paddingTop: '15px',
    paddingBottom: '5px',
  },
  maxWidth: theme.breakpoints.sm,
  width: '100%',
  overflow: 'hidden',
  padding: `${theme.gutters.base * 5}px 0px`,
}));

export const HeaderContainer = styled('div')<{ theme: Theme }>(({ theme }) => ({
  padding: `0px ${theme.gutters.base * 5}px`,
}));

export const ContentContainer = styled('div')<{ theme: Theme }>(({ theme }) => ({
  width: '100%',
  padding: `0px ${theme.gutters.base * 5}px`,
}));

export const ButtonGroup = styled('div')<{ theme: Theme }>(({ theme }) => ({
  padding: `0px ${theme.gutters.base * 5}px`,
  display: 'grid',
  gap: theme.gutters.base * 2,
  margin: `16px 0`,
}));

export const CheckoutLayout: React.FunctionComponent<CheckoutLayoutProps> = ({
  header,
  content,
  buttonGroup,
  isLoading,
}) => {
  return (
    <>
      <Layout>
        {isLoading && <LoaderBlown />}
        <HeaderContainer>{header}</HeaderContainer>
        <InnerScroll>
          <ContentContainer>{content}</ContentContainer>
        </InnerScroll>
        <ButtonGroup {...{ style: {} }}>{buttonGroup}</ButtonGroup>
      </Layout>
    </>
  );
};

export default CheckoutLayout;

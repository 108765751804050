import * as React from 'react';
import InvoiceStore, { InvoiceItem } from '../../../stores/InvoiceStore';
import { Typography } from '../../../ui-lib';
import CheckoutStore, { formatPrice } from '../../../stores/CheckoutStore';
import { useStore } from '../../../stores/setupContext';
import { observer } from 'mobx-react';
import { Divider, Row, Table } from '../../../ui-lib/Table/Table';

export interface DetailsTableProps {
  items: InvoiceItem[];
  estimatedTax: NonNullable<CheckoutStore['prices']>['estimatedTax'];
  subtotal: NonNullable<CheckoutStore['prices']>['subtotal'];
  shipping: NonNullable<CheckoutStore['prices']>['shipping'];
  total: NonNullable<CheckoutStore['prices']>['total'];
  discount: NonNullable<CheckoutStore['prices']>['discount'];
  currentCharge: InvoiceStore['currentCharge'];
  chargeDate: InvoiceStore['chargeDate'];
}

export const DetailsTable: React.FunctionComponent<DetailsTableProps> = ({
  items = [],
  subtotal,
  shipping,
  estimatedTax,
  total,
  discount,
  currentCharge,
  chargeDate,
}) => {
  const amount = formatPrice(currentCharge?.currency)(currentCharge?.amount || 0);
  const isPartialPayment = amount !== total;
  return (
    <Table>
      <div>
        <Row>
          <Typography {...{ variant: 'body1Bold' }}>Description</Typography>
          <Typography {...{ variant: 'body1Bold' }}>Qty</Typography>
          <Typography {...{ variant: 'body1Bold' }}>Price</Typography>
          <Typography {...{ variant: 'body1Bold' }}>Total</Typography>
        </Row>
        <Divider />
      </div>
      <div>
        {items.map(({ description, quantity, price, amount }, ind) => {
          return (
            <div {...{ key: ind }}>
              <Row>
                <Typography {...{ variant: 'body1' }}>{description}</Typography>
                <Typography {...{ variant: 'body1' }}>{quantity}</Typography>
                <Typography {...{ variant: 'body1' }}>{price}</Typography>
                <Typography {...{ variant: 'body1' }}>{amount}</Typography>
              </Row>
              <Divider />
            </div>
          );
        })}
      </div>
      <div>
        <Row>
          <Typography {...{ variant: 'body1' }}>Subtotal</Typography>
          <Typography {...{ variant: 'body1' }}>&nbsp;</Typography>
          <Typography {...{ variant: 'body1' }}>&nbsp;</Typography>
          <Typography {...{ variant: 'body1' }}>{subtotal}</Typography>
        </Row>
        <Divider />
        {shipping && (
          <>
            <Row>
              <Typography {...{ variant: 'body1' }}>Shipping</Typography>
              <Typography {...{ variant: 'body1' }}>&nbsp;</Typography>
              <Typography {...{ variant: 'body1' }}>&nbsp;</Typography>
              <Typography {...{ variant: 'body1' }}>{shipping}</Typography>
            </Row>
            <Divider />
          </>
        )}
        {discount && (
          <>
            <Row>
              <Typography {...{ variant: 'body1' }}>Discount</Typography>
              <Typography {...{ variant: 'body1' }}>&nbsp;</Typography>
              <Typography {...{ variant: 'body1' }}>&nbsp;</Typography>
              <Typography {...{ variant: 'body1' }}>{discount}</Typography>
            </Row>
            <Divider />
          </>
        )}
        {estimatedTax && (
          <>
            <Row>
              <Typography {...{ variant: 'body1' }}>Tax</Typography>
              <Typography {...{ variant: 'body1' }}>&nbsp;</Typography>
              <Typography {...{ variant: 'body1' }}>&nbsp;</Typography>
              <Typography {...{ variant: 'body1' }}>{estimatedTax}</Typography>
            </Row>
            <Divider />
          </>
        )}
        <Row>
          <Typography {...{ variant: 'body1Bold' }}>Total</Typography>
          <Typography {...{ variant: 'body1' }}>&nbsp;</Typography>
          <Typography {...{ variant: 'body1' }}>&nbsp;</Typography>
          <Typography {...{ variant: 'body1Bold' }}>{total}</Typography>
        </Row>
        {isPartialPayment && currentCharge && (
          <>
            <Divider />
            <Row>
              <Typography {...{ variant: 'body1Bold' }}>Payment due ({chargeDate})</Typography>
              <Typography {...{ variant: 'body1' }}>&nbsp;</Typography>
              <Typography {...{ variant: 'body1' }}>&nbsp;</Typography>
              <Typography {...{ variant: 'body1Bold' }}>{amount}</Typography>
            </Row>
          </>
        )}
      </div>
    </Table>
  );
};

const Observed = observer(DetailsTable);

const WithStoreConnection = () => {
  const { invoice, checkout } = useStore();

  const { prices } = checkout;
  const { items, currentCharge, chargeDate } = invoice;
  const itemsWithCheckout = items.length ? items : invoice.setItems();

  if (!prices) {
    return null;
  }

  return <Observed {...{ items: itemsWithCheckout, ...prices, currentCharge, chargeDate }} />;
};

export default observer(WithStoreConnection);

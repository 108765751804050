import {
  Image,
  QualificationHeading,
  QualificationMessageContainer,
  QualificationMessageContent,
  QualificationStepContainer,
  QualificationStepDescription,
} from '../../sections/qualification/common';
import { QualificationButton } from '../../sections/qualification/QualificationButton';
import React, { FC } from 'react';
import LottiePlayer from 'react-lottie-player';
import successAnimationLottieFile from '../../animations/success.confetti.lottie.json';
import { withHttps } from '../../utils/string.utils';
import { replaceColor } from 'lottie-colorify';
import { useMobile } from '../../utils/mobile.hook';
import { CompanyName } from '../InfoPage/InfoPage';
import { SeccessGreenIcon } from '../../icons/Success/Success';
import { PoweredByBalance } from '../../sections/qualification/PoweredByBalance';

export interface SuccessPageProps {
  siteDomain: string;
  redirectUrl?: string;
  logoText?: string;
  logoSrc?: string;
}

export const generateSuccessOnboardingDescription = (vendorName?: string): string =>
  `Thanks, you completed the on-boarding process with ${vendorName}`;

export const SuccessPage: FC<SuccessPageProps> = ({ siteDomain, redirectUrl, logoText, logoSrc, children }) => {
  if (redirectUrl) {
    location.href = withHttps(decodeURIComponent(redirectUrl));
    return null;
  }
  const isMobile = useMobile();

  const onClick = siteDomain
    ? () => {
        location.href = withHttps(siteDomain);
      }
    : null;

  return (
    <QualificationStepContainer>
      <QualificationMessageContainer>
        {isMobile ? (
          <>
            <CompanyName>{logoSrc ? <Image src={logoSrc} /> : logoText}</CompanyName>
            <SeccessGreenIcon />
          </>
        ) : (
          <LottiePlayer
            loop={false}
            animationData={replaceColor('#475dfc', '#5AB643', successAnimationLottieFile)}
            play
            style={{ width: 300, height: 300 }}
          />
        )}
        <QualificationHeading>Success</QualificationHeading>
        <QualificationStepDescription>{children}</QualificationStepDescription>
        <QualificationMessageContent>
          {onClick && (
            <QualificationButton onClick={onClick} data-analytics-id="qualification-success-visit-vendor-site">
              {isMobile ? `VISIT ${siteDomain.toUpperCase()}` : `Visit ${siteDomain}`}
            </QualificationButton>
          )}
        </QualificationMessageContent>
      </QualificationMessageContainer>
      {isMobile && <PoweredByBalance />}
    </QualificationStepContainer>
  );
};

import * as React from 'react';
import { Container, makeStyles } from '@material-ui/core';
import { theme } from '../../theme';
import { FC } from 'react';
import { device } from '../../utils/devices/devices.utils';

export const FormStep: FC = ({ children }) => {
  const styles = makeStyles({
    step: {
      background: theme.palette.background.site,
      marginTop: '24px',
      marginBottom: '24px',
      padding: '48px',
      borderRadius: '24px',
      boxShadow: '0px 2px 6px rgba(204, 218, 255, 0.4), 0px 5px 12px 8px #F0F2FF',
      [`@media ${device.mobileL}`]: {
        borderRadius: 0,
        boxShadow: 'none',
      },
    },
  })();

  return <Container className={styles.step}>{children as NonNullable<React.ReactNode>}</Container>;
};

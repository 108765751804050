import { CountryCode, CountryFields, transformBankAccountDetails } from './countries.interfaces';
import { COUNTRIES_FIELDS_MAP } from './countries';

export const trimBankAccountTransform: transformBankAccountDetails = ({ routingNumber, accountNumber }) => ({
  routingNumber: routingNumber?.trim(),
  accountNumber: accountNumber?.trim(),
});

export const getCountryConfig = (country: CountryCode): CountryFields => {
  return COUNTRIES_FIELDS_MAP[country];
};

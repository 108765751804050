import MaskedInput, { maskArray, MaskedInputProps } from 'react-text-mask';
import React from 'react';

const maskDefaultProps = {
  placeholderChar: '\u2000',
  guide: false,
};

export const InputTextMask = (props: MaskedInputProps) => {
  return (
    <MaskedInput {...maskDefaultProps} {...props} mask={[/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]} />
  );
};

export const InputResaleNumberMask = (props: MaskedInputProps) => {
  return (
    <MaskedInput
      {...maskDefaultProps}
      {...props}
      mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
    />
  );
};

const hundredsMask = ['$', /\d/, /\d/, /\d/];
const thousandsMask = ['$', /\d/, ',', /\d/, /\d/, /\d/];
const tensOfThousandsMask = ['$', /\d/, /\d/, ',', /\d/, /\d/, /\d/];
const hundredsThousandsMask = ['$', /\d/, /\d/, /\d/, ',', /\d/, /\d/, /\d/];
const millionsMask = ['$', /\d/, ',', /\d/, /\d/, /\d/, ',', /\d/, /\d/, /\d/];

export const InputMonthlySalesMask = (props: MaskedInputProps) => {
  return (
    <MaskedInput
      {...maskDefaultProps}
      {...props}
      mask={(value: string): maskArray => {
        if (value === '') return [];
        const input = parseInt(value.replace(/\D/g, ''));
        if (input < 1000) {
          return hundredsMask;
        } else if (input >= 1000 && input < 10000) {
          return thousandsMask;
        } else if (input >= 10000 && input < 100000) {
          return tensOfThousandsMask;
        } else if (input >= 100000 && input < 1000000) {
          return hundredsThousandsMask;
        } else return millionsMask;
      }}
    />
  );
};

export const DNBTextMask = (props: MaskedInputProps) => {
  return (
    <MaskedInput
      {...maskDefaultProps}
      {...props}
      mask={[/\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
    />
  );
};

export const PhoneNumberMask = (props: MaskedInputProps) => {
  return (
    <MaskedInput
      {...maskDefaultProps}
      {...props}
      mask={['+', '1', ' ', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
    />
  );
};

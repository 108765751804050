import styled from '@emotion/styled';
import { ArrowIcon } from '../../icons';
import React, { PropsWithChildren } from 'react';
import { Button } from '../../ui-lib';
import { Theme } from '../../theme';
import { ButtonProps } from '../../ui-lib/Button/Button';
import { device } from '../../utils/devices/devices.utils';
import { useMobile } from '../../utils/mobile.hook';

const CustomButton = styled(Button)<{ theme: Theme; isTransparent?: boolean }>(({ theme, isTransparent }) => ({
  width: 'fit-content',
  backgroundColor: theme.palette.main.link,
  '&:hover': {
    opacity: 0.8,
  },

  [`@media ${device.mobileL}`]: {
    width: '100%',
    backgroundColor: !isTransparent ? theme.palette.main.btnPrimary : 'transparent',
    fullWidth: true,
    color: isTransparent ? 'black' : '',
  },
}));

export interface QualificationButtonProps extends ButtonProps {
  onClick?: VoidFunction;
  isTransparent?: boolean;
}

export const QualificationButton = ({
  children,
  onClick,
  isTransparent,
  ...props
}: PropsWithChildren<QualificationButtonProps>) => {
  return (
    <CustomButton
      {...{
        isTransparent: isTransparent,
        fullWidth: false,
        disabled: false,
        rightIcon: !useMobile() ? <ArrowIcon rotate={180} color={'secondary'} /> : <></>,
      }}
      onClick={onClick}
      {...props}
    >
      <b>{children}</b>
    </CustomButton>
  );
};

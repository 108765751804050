import * as React from 'react';

export const ErrorIcon: React.FunctionComponent = () => (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="40" cy="40" r="40" fill="#EB5757" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.0837 27.16L54.5402 50.1733C54.7134 50.4889 54.7134 50.871 54.5402 51.1866L54.3535 51.4933C54.1807 51.8096 53.8472 52.0046 53.4867 52H26.5203C26.1598 52.0046 25.8262 51.8096 25.6534 51.4933L25.4667 51.1866C25.2935 50.871 25.2935 50.4889 25.4667 50.1733L38.9499 27.16C39.1323 26.8549 39.4613 26.6677 39.8167 26.6666H40.2168C40.5723 26.6677 40.9013 26.8549 41.0837 27.16ZM40.0035 30.6667L29.0542 49.3333H50.9394L40.0035 30.6667ZM40.6703 44H39.3366C38.9683 44 38.6698 44.2985 38.6698 44.6667V46C38.6698 46.3682 38.9683 46.6667 39.3366 46.6667H40.6703C41.0386 46.6667 41.3371 46.3682 41.3371 46V44.6667C41.3371 44.2985 41.0386 44 40.6703 44ZM38.9632 41.04L38.4298 36.7467C38.4067 36.5565 38.4667 36.3656 38.5943 36.2227C38.722 36.0797 38.9049 35.9986 39.0966 36H40.9103C41.102 35.9986 41.2849 36.0797 41.4126 36.2227C41.5402 36.3656 41.6002 36.5565 41.5772 36.7467L41.0437 41.04C41.0234 41.2083 40.8799 41.3346 40.7103 41.3334H39.2966C39.127 41.3346 38.9836 41.2083 38.9632 41.04Z"
      fill="white"
    />
  </svg>
);

export const MobileErrorIcon: React.FunctionComponent = () => (
  <svg width="40" height="40" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="24" cy="24" r="24" fill="#EB5757" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24.8128 14.37L34.9051 31.63C35.035 31.8667 35.035 32.1533 34.9051 32.39L34.7651 32.62C34.6355 32.8573 34.3853 33.0035 34.115 33H13.8902C13.6198 33.0035 13.3696 32.8573 13.24 32.62L13.1 32.39C12.9701 32.1533 12.9701 31.8667 13.1 31.63L23.2124 14.37C23.3492 14.1412 23.5959 14.0008 23.8625 14H24.1626C24.4292 14.0008 24.6759 14.1412 24.8128 14.37ZM24.0026 17L15.7906 31H32.2045L24.0026 17ZM23.5024 27C23.2262 27 23.0023 27.2239 23.0023 27.5V28.5C23.0023 28.7761 23.2262 29 23.5024 29H24.5027C24.7789 29 25.0028 28.7761 25.0028 28.5V27.5C25.0028 27.2239 24.7789 27 24.5027 27H23.5024ZM23.2224 24.78L22.8223 21.56C22.805 21.4173 22.85 21.2742 22.9457 21.167C23.0415 21.0598 23.1787 20.999 23.3224 21H24.6827C24.8264 20.999 24.9637 21.0598 25.0594 21.167C25.1551 21.2742 25.2001 21.4173 25.1828 21.56L24.7827 24.78C24.7675 24.9062 24.6599 25.0009 24.5327 25H23.4724C23.3453 25.0009 23.2376 24.9062 23.2224 24.78Z"
      fill="white"
    />
  </svg>
);

import * as React from 'react';
import SVGIcon, { SVGIconProps } from './../../ui-lib/SVGIcon/SVGIcon';

export interface CreditCardIconProps {
  color?: SVGIconProps['color'];
}

export const CreditCardIcon: React.FunctionComponent<CreditCardIconProps> = ({ color }) => {
  return (
    <SVGIcon {...{ width: 20, height: 16, viewBox: '0 0 20 16', color }}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 0H18C19.1046 0 20 0.89543 20 2V14C20 15.1046 19.1046 16 18 16H2C0.89543 16 0 15.1046 0 14V2C0 0.89543 0.89543 0 2 0ZM18 5V2H2V5H18ZM2 14V8H18V14H2ZM4.5 10H7.5C7.77614 10 8 10.2239 8 10.5V11.5C8 11.7761 7.77614 12 7.5 12H4.5C4.22386 12 4 11.7761 4 11.5V10.5C4 10.2239 4.22386 10 4.5 10Z"
      />
    </SVGIcon>
  );
};

import * as React from 'react';
import { Typography } from './../../ui-lib';
//import styled from '@emotion/styled';
import { motion } from 'framer-motion';

export interface AnimatedDimensionsProps {}

export const AnimatedDimensions: React.FunctionComponent<AnimatedDimensionsProps> = ({ children }) => {
  const [addItem, setItem] = React.useState(false);
  return (
    <motion.div {...{ layout: true, transition: { duration: 0.1 }, animate: { height: 'auto' } }}>
      {children}
    </motion.div>
  );
};

export default AnimatedDimensions;

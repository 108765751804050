import * as React from 'react';
import { Dot, Typography } from './../../ui-lib';
import styled from '@emotion/styled';
import { Theme } from './../../theme';
import { CogIcon } from './../../icons';
import { flexBetweenCenter, flexCenterCenter } from './../../styles/common';

export interface CurrentBankProps {
  name: string;
  bankName: string;
  mask: string;
  /**
   * If a callback is supplied will show a clickable icon
   * @default undefined
   */
  onEdit?: () => void;
}

export const Container = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
}));

export const Edit = styled('div')<{ theme: Theme }>(({ theme }) => ({
  marginLeft: theme.gutters.base,
  cursor: 'pointer',
  height: theme.gutters.base * 2,
  width: theme.gutters.base * 2,
  ...flexCenterCenter,
  '&:hover': {
    backgroundColor: theme.palette.blacks.eta,
    borderRadius: '50%',
  },
}));

export const CurrentBank: React.FunctionComponent<CurrentBankProps> = ({ name, bankName, mask, onEdit }) => {
  return (
    <Container>
      <Typography {...{ variant: 'caption', color: 'gamma' }}>{bankName}</Typography>
      &nbsp;
      <Dot />
      &nbsp;
      <Typography {...{ variant: 'caption', color: 'gamma' }}>{mask}</Typography>
      {onEdit && (
        <Edit {...{ onClick: onEdit, 'data-cy': 'cog' }}>
          <CogIcon {...{}} />
        </Edit>
      )}
    </Container>
  );
};

export default CurrentBank;

import { StepCard } from '../../../../../Components/StepCard';
import React, { VFC } from 'react';
import BankSvg from '../../../../../assets/bank.svg';
import { Grid } from '@material-ui/core';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import * as Yup from 'yup';
import { StyledNumberInput } from '../../../../../Components/StyledNumber';
import { COUNTRIES_CODES_MAP, COUNTRIES_FIELDS_MAP, COUNTRIES_MAP } from '../../../countries/countries';
import { getYupTestValidationForFromCountryConfig } from '../../../forms/validation.utils';
import { CountryCode } from '../../../countries/countries.interfaces';
import {
  BankAccountDetailsProps,
  VendorOnboardingProps,
} from '../../../hooks/useVendorOnboarding/vendor-onboarding.types';

export const yupBankAccountDetailsValidationSchema = Yup.object().shape({
  routingNumber: Yup.string().test(getYupTestValidationForFromCountryConfig('bankAccountDetails.routingNumber')),
  accountNumber: Yup.string()
    .required('Required')
    .test(getYupTestValidationForFromCountryConfig('bankAccountDetails.accountNumber')),
  confirmAccountNumber: Yup.string()
    .required('Required')
    .test('validate-account-number', "Account numbers don't match", function (value: any) {
      return this.parent.accountNumber?.toLowerCase() === value?.toLowerCase();
    }),
});

export const makeBankAccountDetailsDefaultValues = (countryCode: CountryCode) => ({
  // Only add default values for fields that are relevant for all countries
  accountNumber: '',
  confirmAccountNumber: '',
  country: countryCode,
  currency: COUNTRIES_MAP[countryCode].currency,
});

export const BANK_ACCOUNT_DETAILS_DEFAULT_VALUES: BankAccountDetailsProps = makeBankAccountDetailsDefaultValues(
  COUNTRIES_CODES_MAP[0].value
);

interface BankFieldProps {
  fields: any;
  fieldName: keyof BankAccountDetailsProps;
}

const BankDetailsField: VFC<BankFieldProps> = ({ fields, fieldName }) => {
  const { control } = useFormContext<VendorOnboardingProps>();
  const fieldProps = fields[fieldName];
  const Component = fieldProps.Component ?? StyledNumberInput;

  return (
    <Grid item xs={12} md={fieldProps.fullWidth ? undefined : 6}>
      <Controller
        control={control}
        name={`bankAccountDetails.${fieldName}` as keyof VendorOnboardingProps}
        render={({ field: { ref, ...field }, fieldState: { error } }) => (
          <Component
            inputRef={ref}
            margin="normal"
            fullWidth
            variant="standard"
            error={Boolean(error)}
            helperText={error?.message}
            {...field}
            {...fieldProps}
          />
        )}
      />
    </Grid>
  );
};

interface BankAccountDetailsStepProps {
  backToPreview?: VoidFunction;
}

export const BankAccountDetails: VFC<BankAccountDetailsStepProps> = ({ backToPreview }) => {
  const { control } = useFormContext<VendorOnboardingProps>();

  const country = useWatch({
    control,
    name: 'country',
  });

  const { transform, ...fields } = COUNTRIES_FIELDS_MAP[country]?.bankAccountDetails;

  return (
    <StepCard iconSrc={BankSvg} headerText={['Provide your', 'banking information']} onBackAction={backToPreview}>
      <Grid container spacing={6}>
        {Object.keys(fields).map((fieldName, index) => (
          <BankDetailsField key={index} fieldName={fieldName as keyof BankAccountDetailsProps} fields={fields} />
        ))}
      </Grid>
    </StepCard>
  );
};

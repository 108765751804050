import * as React from 'react';
import styled from '@emotion/styled';
import { Typography } from './../../../ui-lib';
import { flexCenterCenter } from './../../../styles/common';
import { Theme } from './../../../theme';
import pigletIcon from './../../../icons/piglet.png';

export interface LinkBankProps {}

export const Container = styled('div')<{ theme: Theme }>(({ theme }) => ({
  ...flexCenterCenter,
  flexDirection: 'column',
  maxWidth: theme.breakpoints.sm,
  margin: '0 auto',
}));

export const Image = styled('img')<{ theme: Theme }>(({ theme }) => ({
  display: 'block',
  height: 254,
  width: 254,
}));

export const LinkBank: React.FunctionComponent<LinkBankProps> = ({}) => {
  return (
    <Container>
      <Image {...{ src: pigletIcon }} />
      <Typography {...{ variant: 'h1', center: true, marginBottom: 16 }}>Connect your bank account</Typography>
      <Typography {...{ variant: 'body1', center: true }}>
        We need information from your business checking account to get a better picture of how your business is doing.
        By continuing you agree to our{' '}
        <Typography
          {...{
            variant: 'linkBlue',
            component: 'a',
            href: 'https://www.getbalance.com/legal/end-user-terms',
            target: '_blank',
          }}
        >
          terms of service
        </Typography>{' '}
        and{' '}
        <Typography
          {...{
            variant: 'linkBlue',
            component: 'a',
            href: 'https://www.getbalance.com/legal/privacy-policy',
            target: '_blank',
          }}
        >
          privacy policy
        </Typography>
        .
      </Typography>
    </Container>
  );
};

export default LinkBank;

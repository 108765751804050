import { AxiosResponse } from 'axios';
import { api } from '../../../api/api';
import {
  FetchVendorResponse,
  UpdateVendorOnboardingProps,
  VendorOnboardingProps,
} from './hooks/useVendorOnboarding/vendor-onboarding.types';

export const setOnboardingToken = (token: string) => {
  api.defaults.headers.common['X-Vendor-Onboarding-Key'] = token;
};

export const createVendor = async (data: VendorOnboardingProps): Promise<AxiosResponse> => {
  return api.post<VendorOnboardingProps, AxiosResponse>('/vendors/onboarding', data);
};

export const updateVendor = async (
  vendorPublicId: string,
  data: UpdateVendorOnboardingProps
): Promise<AxiosResponse> => {
  return api.put<VendorOnboardingProps, AxiosResponse>(`/vendors/onboarding/${vendorPublicId}`, data);
};

interface BusinessSettings {
  businessDomain: string;
  primaryColor?: string;
  secondaryColor?: string;
}

export interface MerchantSettings {
  name: string;
  logo: string;
  coverPhoto: string;
  settings: BusinessSettings;
}

export const getMerchantSettings = async (): Promise<AxiosResponse<MerchantSettings>> => {
  return api.get<MerchantSettings>(`/settings/merchant`);
};

export const getVendorDetails = async (vendorPublicId: string): Promise<AxiosResponse<FetchVendorResponse>> => {
  return api.get<FetchVendorResponse>(`/vendors/onboarding/${vendorPublicId}`);
};

import * as React from 'react';
import SVGIcon, { SVGIconProps } from '../../ui-lib/SVGIcon/SVGIcon';
//import styled from '@emotion/styled';

export interface BlockIconProps {
  color?: SVGIconProps['color'];
}

export const BlockIcon: React.FunctionComponent<BlockIconProps> = ({ color }) => {
  return (
    <SVGIcon {...{ width: 18, height: 18, viewBox: '0 0 18 18', color }}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1 4.4L13.6 0.9C13 0.3 12.3 0 11.5 0H6.5C5.7 0 4.9 0.3 4.4 0.9L0.9 4.4C0.3 5 0 5.7 0 6.5V11.5C0 12.3 0.3 13.1 0.9 13.6L4.4 17.1C5 17.7 5.7 18 6.5 18H11.5C12.3 18 13.1 17.7 13.6 17.1L17.1 13.6C17.7 13 18 12.3 18 11.5V6.5C18 5.7 17.7 4.9 17.1 4.4ZM2 11.5V6.5C2 6.2 2.1 6 2.3 5.8L5.8 2.3C6 2.1 6.2 2 6.5 2H11.5C11.8 2 12 2.1 12.2 2.3L13.3 3.4L3.4 13.3L2.2 12.2C2.1 12 2 11.8 2 11.5ZM15.7 12.2C15.9 12 16 11.8 16 11.5V6.5C16 6.2 15.9 6 15.7 5.8L14.6 4.7L4.7 14.6L5.8 15.7C6 15.9 6.2 16 6.5 16H11.5C11.8 16 12 15.9 12.2 15.7L15.7 12.2Z"
      />
    </SVGIcon>
  );
};

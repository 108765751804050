import * as React from 'react';
import CheckoutStore from './../../stores/CheckoutStore';
import styled from '@emotion/styled';
import { Theme } from '../../theme';
import { flexBetweenCenter, flexCenterCenter } from '../../styles/common';
import { CogIcon } from '../../icons';
import { Dot, Typography } from './../../ui-lib';
import { cardIconMapper } from '../../styles/cardIconMapper';

import {
  CardDetailsContainer,
  CardExpiry,
  CardInfoContainer,
  CardNameAndMask,
} from '../CreditCardManagement/CreditCardList';

export interface SelectedCreditCardProps {
  cardDetails: NonNullable<CheckoutStore['selectedCreditCard']>;
  /**
   * If a callback is supplied will show a clickable icon
   * @default undefined
   */
  onEdit?: () => void;
  showExpirationDate?: boolean;
}

export const Container = styled('div')<{ theme: Theme }>(({ theme }) => ({
  ...flexBetweenCenter,
  '& > div': {
    marginLeft: theme.gutters.base,
  },
}));

export const Edit = styled('div')<{ theme: Theme }>(({ theme }) => ({
  cursor: 'pointer',
  height: theme.gutters.base * 2,
  width: theme.gutters.base * 2,
  ...flexCenterCenter,
  '&:hover': {
    backgroundColor: theme.palette.blacks.eta,
    borderRadius: '50%',
  },
}));

export const IconWrap = styled('div')<{ theme: Theme }>(({ theme }) => ({
  marginRight: theme.gutters.base,
}));

export const Icon = styled('img')<{ theme: Theme }>(({ theme }) => ({
  display: 'block',
  width: '100%',
  maxWidth: theme.gutters.base * 3,
  maxHeight: theme.gutters.base * 2,
}));

export const SelectedCreditCard: React.FunctionComponent<SelectedCreditCardProps> = ({
  cardDetails,
  onEdit,
  showExpirationDate,
}) => {
  const { name, mask, exp } = cardDetails;
  return (
    <Container>
      <CardDetailsContainer>
        <IconWrap {...{ style: {} }}>
          <Icon {...{ src: cardIconMapper[name] ?? 'icon' }} />
        </IconWrap>
        <CardInfoContainer>
          <CardNameAndMask
            {...{
              style: {},
            }}
          >
            <Typography {...{ variant: 'caption' }}>{name.charAt(0).toUpperCase() + name.slice(1)} </Typography>
            &nbsp;
            <Dot />
            &nbsp;
            <Typography {...{ variant: 'caption' }}>{mask}</Typography>
          </CardNameAndMask>
          {showExpirationDate && <CardExpiry>{exp}</CardExpiry>}
        </CardInfoContainer>
      </CardDetailsContainer>

      {onEdit && (
        <Edit {...{ onClick: onEdit, 'data-cy': 'cog' }}>
          <CogIcon {...{}} />
        </Edit>
      )}
    </Container>
  );
};

export default SelectedCreditCard;

import { observer } from 'mobx-react';
import { checkIfStateModificationsAreAllowed } from 'mobx/dist/internal';
import * as React from 'react';
import { useStore } from './../../../stores/setupContext';
//import styled from '@emotion/styled';

export interface PayLaterProps {}

export const PayLater: React.FunctionComponent<PayLaterProps> = ({}) => {
  return <div />;
};

const Observed = observer(PayLater);

const WithStoreConnection = () => {
  const { checkout } = useStore();

  const { submitPayLater } = checkout;

  React.useEffect(() => {
    submitPayLater();
  }, []);

  return <Observed {...{}} />;
};

export default observer(WithStoreConnection);

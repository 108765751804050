import * as React from 'react';
import { Typography } from '../../../ui-lib';
import styled from '@emotion/styled';
import { Theme } from '../../../theme';
import { CalendarIcon, CreditCardIcon, BankIcon } from '../../../icons';
import CheckoutStore, { HowToPay } from '../../../stores/CheckoutStore';
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/setupContext';
import SelectedCreditCard from './../../../components/SelectedCreditCard/SelectedCreditCard';
import Option from './../../../components/Option/Option';
import Options from './../../../components/Options/Options';
import { absoluteBlow, flexCenterCenter, flexStartCenter } from '../../../styles/common';
import CurrentBank from './../../../components/CurrentBank/CurrentBank';
import { WrappedNotification, NotificationProps } from '../../../components/Notification/Notification';
import AnimatedDimensions from './../../../components/AnimatedDimensions/AnimatedDimensions';
import { GetPaymentMethodsResponse } from '../../../api/api.schema';
import AddCardForm from '../../../components/CreditCardSplitForm/CreditCardSplitForm';
import AddCardFormNew from '../../../components/CreditCardSplitForm/CreditCardSplitForm-new';
import CreditCardModel from '../../../stores/models/CreditCard';

export interface HowToPaySelectionProps {
  selectedHowToPay: CheckoutStore['selectedHowToPay'];
  setSelectedHowToPay: CheckoutStore['setSelectedHowToPay'];
  selectedCreditCard: CheckoutStore['selectedCreditCard'];
  selectedBankAccount: CheckoutStore['selectedBankAccount'];
  isPayingWithTerms: CheckoutStore['isPayingWithTerms'];
  openCardManagement: CheckoutStore['openCardManagement'];
  openBankManagement: CheckoutStore['openBankManagement'];
  payWithInvoice: CheckoutStore['payWithInvoice'];
  payWithTermsStatus: CheckoutStore['payWithTermsStatus'];
  shouldShowPayWithTerms: CheckoutStore['shouldShowPayWithTerms'];
  shouldPayWithTermsBeDisabled: CheckoutStore['shouldPayWithTermsBeDisabled'];
  howWouldYouLikeToPayTitle: CheckoutStore['howWouldYouLikeToPayTitle'];
  howToPayOptions: CheckoutStore['howToPayOptions'];
  shouldShowInvoice: CheckoutStore['shouldShowInvoice'];
  notification: NotificationProps | null;
  showCardForm: CreditCardModel['showCardForm'];
  setShowCardForm: CreditCardModel['setShowCardForm'];
}

export const paymentOptions: Array<{
  key: HowToPay;
  paymentMethodKey: GetPaymentMethodsResponse['allowedPaymentMethods'][0]['name'];
  label: string;
  subComponent: string | React.ReactNode;
  iconSrc: React.ReactNode;
}> = [
  {
    key: 'withTerms',
    paymentMethodKey: 'payWithTerms',
    label: 'Pay With Terms',
    subComponent: 'Pay now for the fastest way to checkout',
    iconSrc: <CalendarIcon />,
  },
  {
    key: 'withCard',
    paymentMethodKey: 'creditCard',
    label: 'Pay With Card',
    subComponent: 'Let us check for a real-time 0% APR financing decision',
    iconSrc: <CreditCardIcon />,
  },
  {
    key: 'withBankTransfer',
    paymentMethodKey: 'bank',
    label: 'Pay With Bank Transfer',
    subComponent: 'Let us check for a real-time 0% APR financing decision',
    iconSrc: <BankIcon />,
  },
];

export const ExtraOptions = styled('div')<{ theme: Theme }>(({ theme }) => ({
  marginTop: theme.gutters.base * 4,
  marginBottom: theme.gutters.base * 2,
  ...flexCenterCenter,
}));

export const IconAndLabel = styled('div')<{ theme: Theme; isColumn?: boolean; isSelected: boolean }>(
  ({ theme, isColumn, isSelected }) => ({
    ...(!isColumn && { ...flexCenterCenter }),
    ...(isColumn && { ...flexStartCenter }),
    '& svg': {
      marginRight: theme.gutters.base * 2,
      fill: isSelected ? theme.palette.main.primary : 'initial',
    },
  })
);

export const AddACard = styled(Typography)<{ theme: Theme }>(({ theme }) => ({
  position: 'relative',
  zIndex: 1,
  '&:after': {
    content: '""',
    ...absoluteBlow,
    top: -8,
    left: -8,
    right: -8,
    bottom: -8,
    padding: 8,
    zIndex: -1,
  },
  '&:hover:after': {
    backgroundColor: '#e3eefd',
  },
}));

export const HowToPaySelection: React.FunctionComponent<HowToPaySelectionProps> = ({
  selectedHowToPay,
  setSelectedHowToPay,
  selectedCreditCard,
  selectedBankAccount,
  openCardManagement,
  openBankManagement,
  payWithInvoice,
  notification,
  shouldShowPayWithTerms,
  shouldPayWithTermsBeDisabled,
  howWouldYouLikeToPayTitle,
  howToPayOptions,
  shouldShowInvoice,
  showCardForm,
  setShowCardForm,
}) => {
  const cardRef: React.MutableRefObject<HTMLInputElement | undefined> = React.useRef();
  return (
    <div>
      <Typography {...{ variant: 'h1', style: { marginBottom: 28 } }}>{howWouldYouLikeToPayTitle}</Typography>
      <WrappedNotification {...{ notification }} />
      <Options>
        {howToPayOptions.map((option) => {
          const isSelected = selectedHowToPay === option.key;

          if (option.key === 'withTerms' && !shouldShowPayWithTerms) {
            return null;
          }

          const isColumn = option.key === 'withCard' && showCardForm;
          return (
            <Option
              {...{
                innerRef: cardRef,
                key: option.key,
                onClick: () =>
                  option.key === 'withTerms' && shouldPayWithTermsBeDisabled ? null : setSelectedHowToPay(option.key),
                isSelected,
                isDisabled: option.key === 'withTerms' && shouldPayWithTermsBeDisabled,
                isColumn,
              }}
            >
              <IconAndLabel {...{ isColumn, isSelected }}>
                {/* @ts-ignore */}
                {React.cloneElement(option.iconSrc, { color: isSelected ? 'primary' : 'gamma' })}

                <Typography {...{ variant: 'body1' }}>{option.label}</Typography>
              </IconAndLabel>
              {option.key === 'withCard' && !selectedCreditCard && isSelected && !showCardForm && (
                <AddACard
                  {...{
                    onClick: () => {
                      cardRef.current?.scrollIntoView(true);
                      setShowCardForm(true);
                    },
                    variant: 'captionBold',
                    color: 'primary',
                  }}
                >
                  ADD A CARD
                </AddACard>
              )}
              {option.key === 'withCard' && !selectedCreditCard && isSelected && (
                <>
                  <AddCardForm {...{ mode: 'checkout' }} />
                  <AddCardFormNew {...{ mode: 'checkout' }} />
                </>
              )}
              {option.key === 'withCard' && selectedCreditCard && (
                <AnimatedDimensions>
                  <SelectedCreditCard
                    {...{
                      cardDetails: selectedCreditCard,
                    }}
                    {...(isSelected && { onEdit: openCardManagement })}
                  />
                </AnimatedDimensions>
              )}
              {option.key === 'withBankTransfer' && selectedBankAccount && (
                <AnimatedDimensions>
                  {' '}
                  <CurrentBank {...selectedBankAccount} {...(isSelected && { onEdit: openBankManagement })} />
                </AnimatedDimensions>
              )}
            </Option>
          );
        })}
      </Options>
      <ExtraOptions>
        {shouldShowInvoice && (
          <Typography {...{ variant: 'link', color: 'primary', style: { cursor: 'pointer' }, onClick: payWithInvoice }}>
            Need to get an invoice?
          </Typography>
        )}
      </ExtraOptions>
    </div>
  );
};

const Observed = observer(HowToPaySelection);

const WithStoreConnection = () => {
  const { checkout } = useStore();

  const {
    selectedHowToPay,
    setSelectedHowToPay,
    selectedCreditCard,
    openCardManagement,
    openBankManagement,
    selectedBankAccount,
    isPayingWithTerms,
    payWithInvoice,
    payWithTermsNotification,
    notification,
    payWithTermsStatus,
    shouldShowPayWithTerms,
    shouldPayWithTermsBeDisabled,
    howWouldYouLikeToPayTitle,
    howToPayOptions,
    shouldShowInvoice,
    creditCard,
  } = checkout;

  const { showCardForm, setShowCardForm } = creditCard;

  React.useEffect(() => {
    setSelectedHowToPay(null);
  }, []);

  return (
    <Observed
      {...{
        selectedHowToPay,
        setSelectedHowToPay,
        selectedCreditCard,
        openCardManagement,
        openBankManagement,
        selectedBankAccount,
        isPayingWithTerms,
        payWithInvoice,
        notification: payWithTermsNotification || notification,
        payWithTermsStatus,
        shouldPayWithTermsBeDisabled,
        shouldShowPayWithTerms,
        howWouldYouLikeToPayTitle,
        howToPayOptions,
        shouldShowInvoice,
        showCardForm,
        setShowCardForm,
      }}
    />
  );
};

export default observer(WithStoreConnection);

import axios, { AxiosResponse } from 'axios';
import { apiBaseURL } from '../config/config';
import {
  BuyerQualification,
  BuyerSettingsResponse,
  Charge,
  ConfirmRequest,
  ConfirmResponse,
  DeleteBankAccountResponse,
  DeleteCreditCardResponse,
  GetBankAccountsResponse,
  GetListOfStripeCardsResponse,
  GetPaymentMethodsResponse,
  GetTransactionDataResponse,
  Instructions,
  LoginPayload,
  LoginResponse,
  PersistPlaidRequest,
  PersistPlaidResponse,
  SetWireTransferResponse,
  SubmitElectronicQuoteRequest,
  SubmitElectronicQuoteResponse,
  SubmitPayLaterRequest,
  SubmitPayLaterResponse,
  SubmitPaymentRequest,
  UpdateQualificationRequest,
} from './api.schema';

export const api = axios.create({
  baseURL: `${apiBaseURL}/api/v1`,
  timeout: 120 * 1000,
  headers: {
    'Content-Type': 'application/json',
    source: 'checkout',
    common: {
      Authorization:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOm51bGwsImJpZCI6MTEsImVtYWlsIjoiYW1vdW50XzIyMjBAZXhhbXBsZS5jb20iLCJyb2xlIjoyNDgsImlhdCI6MTYwMDg1Njg1MiwiZXhwIjoxNjAwOTQzMjUyfQ.lF9yv218QKlBpbLDfFmM60Pq3A216nIAKDT4Qbk0BP4',
    },
  },
});

export const setToken = (token: string) => {
  api.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const persistQualification = async (payload: UpdateQualificationRequest) => {
  return api.put<BuyerQualification>('/qualification', payload);
};
export const buyerQualification = async (payload: UpdateQualificationRequest) => {
  return api.post<BuyerQualification>('/qualification', payload);
};

export const persistPlaid = async (payload: PersistPlaidRequest) => {
  return api.post<PersistPlaidResponse>('/payment/bank-transfer/plaid/persist', payload);
};

export const getBankAccounts = async () => {
  return api.get<GetBankAccountsResponse>('/payment/bank-transfer/plaid/accounts');
};

export const deleteBankAccount = async (accountId: string) => {
  return api.delete<DeleteBankAccountResponse>(`/payment/bank-transfer/plaid/accounts/${accountId}`);
};

export const setWireTransfer = async () => {
  return api.post<SetWireTransferResponse>('/payment/bank-transfer/stipe-credit-transfer');
};

export const generateClientSecret = async () => {
  return api.post<{ data: { clientSecret: string } }>(`/payment/cc/stripe/setup-intent`);
};

export const editPayment = async (chargeId: string | undefined, payload: SubmitPaymentRequest) => {
  if (chargeId) {
    return api.post(`/charges/${chargeId}/payment`, payload);
  }
};

export const editTransactionPayment = async (transactionId: string | undefined, payload: SubmitPaymentRequest) => {
  if (transactionId) {
    return api.post(`/transactions/${transactionId}/payment`, payload);
  }
};

export const submitPayLater = async (chekcoutToken: string, payload: SubmitPayLaterRequest) => {
  return api.post<SubmitPayLaterResponse>(`/transactions/${chekcoutToken}/customer/finance`, payload);
};

export const generateLinkTokenForPlaid = async (mode?: string) => {
  return api.get<{ linkToken: string }>(`/payment/bank-transfer/plaid/link-token${mode ? `?mode=${mode}` : ''}`);
};

export const submitElectroniceQuote = async (payload: SubmitElectronicQuoteRequest) => {
  return api.post<SubmitElectronicQuoteResponse>(`/quote`, payload);
};

export const getListOfStripeCards = () => {
  return api.get<GetListOfStripeCardsResponse>(`/payment/cc/stripe/cards`);
};

export const deleteCreditCard = async (id: string) => {
  return api.delete<DeleteCreditCardResponse>(`/payment/cc/stripe/cards/${id}`);
};

export const login = async (payload: LoginPayload) => {
  return api.post<LoginResponse>(`/customers/auth/login`, payload);
};

export const getCheckoutData = async (checkoutToken: string) => {
  return api.get<GetTransactionDataResponse>(`/transactions/${checkoutToken}/customer`);
};

export const getCheckoutChargesData = async (checkoutToken: string): Promise<AxiosResponse<Charge[]>> => {
  return api.get<Charge[]>(`/checkouts/${checkoutToken}/charges`);
};

export const getTransactionInfo = async (transactionToken: string) => {
  return api.get<Instructions>(`/transactions/${transactionToken}/customer/info`);
};

export const getPaymentMethods = async (checkoutToken: string): Promise<AxiosResponse<GetPaymentMethodsResponse>> => {
  return api.get<GetPaymentMethodsResponse>(`/checkout/${checkoutToken}/payment-methods`);
};

export const getBuyerQualification = async (): Promise<AxiosResponse<BuyerQualification>> => {
  return api.get<BuyerQualification>('/qualification');
};

export const confirm: (checkoutToken: string, payload: ConfirmRequest) => Promise<AxiosResponse<ConfirmResponse>> =
  async (checkoutToken: string, payload: ConfirmRequest) => {
    return new Promise((resolve, reject) => {
      return api.post<ConfirmResponse>(`/transactions/${checkoutToken}/customer/confirm`, payload, {
        transformResponse: (data: any, headers: any) => {
          const res = JSON.parse(data);
          const { redirectURL } = res || {};
          // @ts-ignore
          resolve({ data: res, status: res.status || res.statusCode, redirectURL, headers });
          return data;
        },
      });
    }).then((res: any) => {
      if (res.status >= 400) throw new Error(res.data.message);
      return res;
    });
  };

export const uploadQualificationFile = async (file: File, qualificationToken: string): Promise<AxiosResponse<void>> => {
  const formData = new FormData();
  formData.append('file', file);
  return api.post<void>(`/qualification/${qualificationToken}/file`, formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
};

export const deleteQualificationFile = async (file: File, qualificationToken: string): Promise<AxiosResponse<void>> => {
  return api.delete<void>(`/qualification/${qualificationToken}/${file.name}`);
};

export const getSettings = async (): Promise<AxiosResponse<BuyerSettingsResponse>> => {
  return api.get<BuyerSettingsResponse>(`/settings/customer`);
};

import * as React from 'react';
import styled from '@emotion/styled';
import { Theme } from './../../theme';

export interface ModalProps {}

const Drop = styled('div')<{ theme: Theme }>(({ theme }) => ({
  background: `${theme.palette.blacks.gamma}CC`,
  zIndex: theme.zIndex.modal,
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const Content = styled('div')<{ theme: Theme }>(({ theme }) => ({
  padding: `${theme.gutters.base * 5}px ${theme.gutters.base * 4}px`,
  boxShadow: '0 4px 8px 0 rgba(46, 64, 86, 0.21)',
  borderRadius: theme.radius.card,
  width: 500,

  maxWidth: '90%',
  maxHeight: '90%',
  height: '100%',
  backgroundColor: theme.palette.background.site,
  margin: '0 20px',
}));

export const Modal: React.FunctionComponent<ModalProps> = ({ children }) => {
  return (
    <Drop>
      <Content>{children}</Content>
    </Drop>
  );
};

export default Modal;

<svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg"></svg>;

import * as React from 'react';
import SVGIcon, { SVGIconProps } from './../../ui-lib/SVGIcon/SVGIcon';
//import styled from '@emotion/styled';

export interface HollowLockIconProps {
  color?: SVGIconProps['color'];
}

export const HollowLockIcon: React.FunctionComponent<HollowLockIconProps> = ({ color }) => {
  return (
    <SVGIcon {...{ width: 18, height: 20, viewBox: '0 0 18 20', color }}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0C4.68629 0 2 2.68629 2 6V8C0.89543 8 0 8.89543 0 10V18C0 19.1046 0.89543 20 2 20H14C15.1046 20 16 19.1046 16 18V10C16 8.89543 15.1046 8 14 8V6C14 2.68629 11.3137 0 8 0ZM14 10V18H2V10H14ZM4 6V8H12V6C12 3.79086 10.2091 2 8 2C5.79086 2 4 3.79086 4 6Z"
      />
    </SVGIcon>
  );
};

import * as React from 'react';
import { keyframes } from '@emotion/core';
import styled from '@emotion/styled';
import { Theme } from './../../theme';
import { absoluteBlow, flexCenterCenter } from './../../styles/common';

export interface LoaderProps {}

const animation = keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
`;

const Wrapper = styled('div')({
  display: 'inline-block',
  position: 'relative',
  width: '50px',
  height: '50px',
});

const AbsoluteWrap = styled('div')({
  ...absoluteBlow,
  ...flexCenterCenter,
  zIndex: 10,
  backgroundColor: 'rgba(0,0,0,0.7)',
});

const Ring = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'block',
  position: 'absolute',
  width: '42px',
  height: '42px',
  border: `1px solid ${theme.palette.main.brand}`,
  borderRadius: '50%',
  animation: `${animation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite`,
  borderColor: `${theme.palette.main.brand} transparent transparent transparent`,
  ':nth-of-type(1)': {
    animationDelay: '-0.45s',
  },
  ':nth-of-type(2)': {
    animationDelay: '-0.3s',
  },
  ':nth-of-type(3)': {
    animationDelay: '-0.15s',
  },
}));

export const LoaderBlown: React.FunctionComponent = () => {
  return (
    <AbsoluteWrap>
      <Loader />
    </AbsoluteWrap>
  );
};

export const Loader: React.FunctionComponent<LoaderProps> = ({}) => {
  return (
    <Wrapper>
      <Ring />
      <Ring />
      <Ring />
      <Ring />
    </Wrapper>
  );
};

export default Loader;

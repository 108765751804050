import React, { VFC } from 'react';
import { styled, Box, FormControl, Input, MenuItem, Select, SelectProps, makeStyles } from '@material-ui/core';
import { useFormContext, Controller } from 'react-hook-form';
import clsx from 'clsx';

export const useStyles = () =>
  makeStyles({
    selectMenu: {
      '& .MuiSelect-selectMenu:focus': {
        background: 'none !important',
      },
      '& .MuiSelect-selectMenu': {
        paddingTop: '0.5rem',
        paddingBottom: '1rem',
      },
    },
  });

export interface ValueName<T = string | number> {
  name: string | number;
  value: T;
}

const Label = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  color: 'rgba(0, 0, 0, 0.54)',
  fontSize: '12px',
});

const StyledFormControl = styled(FormControl)({
  marginTop: '16px',
  marginBottom: '8px',
});

interface StyledSelectProps extends SelectProps {
  name: string;
  label: string;
  defaultValue?: any;
  className?: string;
  items: ValueName[];
  error?: boolean;
  onChange?: (value: any) => void;
}

export const StyledSelect: VFC<StyledSelectProps> = ({
  name: fieldName,
  label,
  defaultValue,
  className,
  variant,
  items,
  onChange,
  error,
  ...props
}) => {
  const classes = useStyles()();
  const { control } = useFormContext();
  const labelId = `${fieldName}-label`;

  return (
    <StyledFormControl id={fieldName}>
      {label && <Label>{label}</Label>}
      <Controller
        name={fieldName || ''}
        control={control}
        defaultValue={defaultValue}
        render={({ field, formState }) => (
          <Select
            className={clsx(classes.selectMenu, className)}
            variant={variant ? variant : 'filled'}
            labelId={labelId}
            input={<Input />}
            MenuProps={{
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
            }}
            {...props}
            error={formState.errors[fieldName]}
            value={field.value}
            onChange={(event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
              field.onChange(event);
              onChange && onChange(event.target.value);
            }}
            inputRef={field.ref}
          >
            {items?.map(({ name, value }) => {
              return (
                <MenuItem key={value} value={value}>
                  {name}
                </MenuItem>
              );
            })}
          </Select>
        )}
      />
    </StyledFormControl>
  );
};

import { BackButtonContainer, HeaderItem } from './QualificationHeader';
import history from '../../routing/history';
import { ArrowBack } from '@material-ui/icons';
import { CompanyName } from '../../components/InfoPage/InfoPage';
import { Image, QualificationStepContainer } from './common';
import { Typography } from '../../ui-lib';
import { BillingAddress } from './BusinessDetailsForm';
import React from 'react';
import { BackContatiner, ManualFormContainer } from './QualificationStepper';
import { useMobile } from '../../utils/mobile.hook';
import { BusinessInfoForm } from './BusinessInfoForm';
import { PoweredByBalance } from './PoweredByBalance';

export interface SmbFormProps {
  logo: string | undefined;
  onBusinessDetailsFormSubmitted: any;
  buyerName: string | undefined;
  billingAddress: BillingAddress | undefined;
}

export const SmbForm = ({ logo, onBusinessDetailsFormSubmitted, buyerName, billingAddress }: SmbFormProps) => {
  const isMobile = useMobile();
  const title = 'Tell us more about your business';
  const subtitle = "We'll use this information to qualify your business for net terms";
  const mobileTitle = 'Tell us more about your business';

  return (
    <QualificationStepContainer>
      <ManualFormContainer>
        {isMobile && (
          <BackContatiner>
            {' '}
            <HeaderItem>
              <BackButtonContainer onClick={() => history.goBack()} data-analytics-id="qualification-back">
                <ArrowBack />
              </BackButtonContainer>
            </HeaderItem>
            <CompanyName>{logo ? <Image {...{ src: logo }} /> : buyerName}</CompanyName>
          </BackContatiner>
        )}

        <Typography variant={!isMobile ? 'h148Bold' : 'h1Bold'}>{isMobile ? mobileTitle : title}</Typography>
        <Typography variant="body2" center={!isMobile} color={'strongGray'}>
          {subtitle}
        </Typography>
        <BusinessInfoForm
          onSubmit={(data) => onBusinessDetailsFormSubmitted(data)}
          buyerAddress={billingAddress}
          buyerName={buyerName}
        />
        {isMobile && <PoweredByBalance />}
      </ManualFormContainer>
    </QualificationStepContainer>
  );
};

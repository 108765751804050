import { observer } from 'mobx-react';
import * as React from 'react';
import { useStore } from './../../../stores/setupContext';
import { Button } from './../../../ui-lib';
//import styled from '@emotion/styled';

export interface BankAccountsProps {}

import BankAccountsList from './../../../components/BankAccountsList/BankAccountsList';

const Observed = observer(BankAccountsList);

const WithStoreConnection = () => {
  const { checkout } = useStore();

  const {
    setPhase,
    bankAccountsList,
    selectedPaymentTerm,
    setSelectedBankAccount,
    selectedBankAccount,
    deleteBankAccount,
    hasInstallments,
    onAddBankClick,
  } = checkout;

  return (
    <Observed
      {...{
        selectedId: selectedBankAccount?.accountId ?? null,
        list: bankAccountsList,
        onSelect: (ind) => setSelectedBankAccount(bankAccountsList[ind]),
        deleteBankAccount,
        onAddBankClick,
      }}
    />
  );
};

export default observer(WithStoreConnection);

import { Control, UseFormSetValue } from 'react-hook-form/dist/types/form';
import React from 'react';
import ownerInfo from '../../apps/assets/ownerInfo.svg';
import { Typography } from '../../ui-lib';
import { Divider, Grid } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { StyledTextField } from '../../ui-lib/TextField/StyledTextField';
import { PlacesAddressAutocomplete } from '../../ui-lib/Address';
import { FormStep } from '../../ui-lib/Card/FormStep';
import { SmallBusinessFormFields } from '../../api/api.schema';
import { BusinessAddress } from './BusinessDetailsForm';
import { useMobile } from '../../utils/mobile.hook';
import { DividerWrapper } from './common';

export interface OwnerInfoStepProps {
  control: Control<SmallBusinessFormFields>;
  setValue: UseFormSetValue<SmallBusinessFormFields>;
  setSelectedAddressSuggestionValues: (address: BusinessAddress) => void;
}

export const OwnerInfoStep = ({ control, setValue, setSelectedAddressSuggestionValues }: OwnerInfoStepProps) => {
  const isMobile = useMobile();
  const ownerSectionTitle = '2 / Owner Info';
  const ownerSectionSubTitle = "We'll use this information to verify your identity";
  const ownerNameSubSectionTitle = 'Owner Name';
  const ownerAddressSubSectionTitle = 'Owner Address';

  return (
    <FormStep>
      {!isMobile && <img src={ownerInfo} alt={'owner info icon'} height={80} width={80} />}
      <Typography variant={'h1Bold'} fontWeight={700} marginTop={'24px'} marginBottom={'24px'}>
        {ownerSectionTitle}
      </Typography>
      <Typography color={'strongGray'} fontWeight={400} marginTop={'24px'} marginBottom={'24px'}>
        {ownerSectionSubTitle}
      </Typography>
      <Typography variant={'body1Bold'} fontWeight={700} marginTop={'40px'} marginBottom={'24px'}>
        {ownerNameSubSectionTitle}
      </Typography>
      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="firstName"
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                required
                {...field}
                margin="normal"
                fullWidth
                variant="standard"
                placeholder="First Name"
                error={Boolean(error)}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="lastName"
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                required
                {...field}
                margin="normal"
                fullWidth
                variant="standard"
                placeholder="Last Name"
                error={Boolean(error)}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
      </Grid>
      <DividerWrapper>
        <Divider variant="fullWidth" />
      </DividerWrapper>
      <Typography variant={'body1Bold'} fontWeight={700} marginTop={'24px'} marginBottom={'24px'}>
        {ownerAddressSubSectionTitle}
      </Typography>
      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="ownerAddress"
            render={({ field, fieldState: { error } }) => (
              <PlacesAddressAutocomplete
                placeholder={'Owner Address'}
                required
                restrictCountries={['us']}
                {...field}
                defaultValue={field.value}
                onAddressSelected={setSelectedAddressSuggestionValues}
                error={Boolean(error)}
                helperText={error?.message}
                onValueChanged={(newValue: string) => setValue('ownerAddress', newValue)}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="ownerAddress2"
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                {...field}
                margin="normal"
                fullWidth
                variant="standard"
                placeholder="Address line 2"
                error={Boolean(error)}
                helperText={error?.message}
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="ownerCity"
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                required
                {...field}
                margin="normal"
                fullWidth
                variant="standard"
                placeholder="City"
                error={Boolean(error)}
                helperText={error?.message}
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="ownerState"
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                required
                {...field}
                margin="normal"
                fullWidth
                variant="standard"
                placeholder="State"
                error={Boolean(error)}
                helperText={error?.message}
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            control={control}
            name="ownerZipCode"
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                required
                {...field}
                margin="normal"
                fullWidth
                variant="standard"
                placeholder="Zip Code"
                error={Boolean(error)}
                helperText={error?.message}
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </Grid>
      </Grid>
    </FormStep>
  );
};

import { ValueName } from '../../../Components/StyledSelect/StyledSelect';
import { CountryCode, Country, CountryFields } from './countries.interfaces';
import { EIN_US_REGEX } from '../forms/ein.validation.util';
import { StyledTextField } from '../../../../ui-lib/TextField/StyledTextField';

export const COUNTRIES_MAP: { [index in CountryCode]: Country } = {
  US: {
    name: 'United States',
    countryCode: 'US',
    currency: 'USD',
  },
  GB: {
    name: 'United Kingdom',
    countryCode: 'GB',
    currency: 'GBP',
  },
  MX: {
    name: 'Mexico',
    countryCode: 'MX',
    currency: 'MXN',
  },
  CA: {
    name: 'Canada',
    countryCode: 'CA',
    currency: 'CAD',
  },
  DE: {
    name: 'Germany',
    countryCode: 'DE',
    currency: 'EUR',
  },
  ID: {
    name: 'Indonesia',
    countryCode: 'ID',
    currency: 'IDR',
  },
  FR: {
    name: 'France',
    countryCode: 'FR',
    currency: 'EUR',
  },
  ES: {
    name: 'Spain',
    countryCode: 'ES',
    currency: 'EUR',
  },
  SA: {
    name: 'Saudi Arabia',
    countryCode: 'SA',
    currency: 'SAR',
  },
  KR: {
    name: 'South Korea',
    countryCode: 'KR',
    currency: 'KRW',
  },
  AE: {
    name: 'United Arab Emirates',
    countryCode: 'AE',
    currency: 'AED',
  },
  IE: {
    name: 'Ireland',
    countryCode: 'IE',
    currency: 'EUR',
  },
  FI: {
    name: 'Finland',
    countryCode: 'FI',
    currency: 'EUR',
  },
  AU: {
    name: 'Australia',
    countryCode: 'AU',
    currency: 'AUD',
  },
  DK: {
    name: 'Denmark',
    countryCode: 'DK',
    currency: 'DKK',
  },
  SE: {
    name: 'Sweden',
    countryCode: 'SE',
    currency: 'SEK',
  },
};

export const COUNTRIES_CODES_MAP: ValueName<CountryCode>[] = (Object.keys(COUNTRIES_MAP) as CountryCode[]).map(
  (code) => ({
    value: code,
    name: COUNTRIES_MAP[code].name,
  })
);

export const COUNTRIES_FIELDS_MAP: { [index in CountryCode]: CountryFields } = {
  US: {
    ein: {
      placeholder: 'Employer Identification Number (EIN)',
      label: '12-3456789',
      format: '##-#######',
      validation: {
        value: EIN_US_REGEX,
        message: 'Please enter a valid Employer Identification Number',
      },
    },
    bankAccountDetails: {
      routingNumber: {
        label: '110000000',
        placeholder: 'Routing number',
        format: '#########',
        fullWidth: true,
      },
      accountNumber: {
        label: '000123456789',
        placeholder: 'Account number',
        format: '#################',
      },
      confirmAccountNumber: {
        label: '000123456789',
        placeholder: 'Confirm account number',
        format: '#################',
      },
    },
    address: {
      addressLine1: {},
      addressLine2: {},
      city: {},
      state: {},
      zipCode: {
        placeholder: 'Zip Code',
        label: 'Zip Code',
      },
    },
  },
  GB: {
    ein: {
      placeholder: 'Company Registration Number (CRN)',
      label: '12345678',
      format: '########',
      Component: StyledTextField.upperCase,
      validation: {
        value: /^[A-Za-z0-9]{1,8}$/,
        message: 'CRN should be up to 8 characters long',
      },
    },
    bankAccountDetails: {
      routingNumber: {
        label: '108800',
        placeholder: 'Sort code',
        format: '######',
        fullWidth: true,
      },
      accountNumber: {
        label: '00012345',
        placeholder: 'Account number',
        format: '########',
      },
      confirmAccountNumber: {
        label: '00012345',
        placeholder: 'Confirm account number',
        format: '########',
      },
    },
    address: {
      addressLine1: {},
      addressLine2: {},
      city: {},
      zipCode: {
        Component: StyledTextField.upperCase,
      },
    },
  },
  MX: {
    ein: {
      placeholder: 'Company RFC',
      label: 'CLA210518EY5',
      format: '#############',
      Component: StyledTextField.upperCase,
      validation: {
        value: /^[A-Za-z0-9]{12,13}$/,
        message: 'RFC should be 12 or 13 characters long',
      },
    },
    bankAccountDetails: {
      accountNumber: {
        label: '000000001234567897',
        placeholder: 'CLABE',
        format: '##################',
      },
      confirmAccountNumber: {
        label: '000000001234567897',
        placeholder: 'Confirm CLABE',
        format: '##################',
      },
    },
    address: {
      addressLine1: {},
      addressLine2: {},
      city: {},
      state: {},
    },
  },
  CA: {
    ein: {
      placeholder: 'Business Number (BN)',
      label: '123456789',
      format: '#########',
    },
    bankAccountDetails: {
      transform: ({ transitNumber, institutionNumber, accountNumber }) => ({
        routingNumber: `${transitNumber?.trim()}-${institutionNumber?.trim()}`,
        accountNumber: accountNumber.trim(),
      }),
      transitNumber: {
        label: '12345',
        placeholder: 'Transit number',
        format: '#####',
      },
      institutionNumber: {
        label: '000',
        placeholder: 'Institution number',
        format: '###',
      },
      accountNumber: {
        label: '000123456789',
        placeholder: 'Account number',
        format: '############',
      },
      confirmAccountNumber: {
        label: '000123456789',
        placeholder: 'Confirm account number',
        format: '############',
      },
    },
    address: {
      addressLine1: {},
      addressLine2: {},
      city: {},
      state: {
        placeholder: 'Province',
      },
      zipCode: {
        Component: StyledTextField.upperCase,
      },
    },
  },
  DE: {
    ein: {
      placeholder: 'Commercial Registry Number',
      label: 'HRB 1234',
      format: '#########',
      Component: StyledTextField.upperCase,
    },
    bankAccountDetails: {
      accountNumber: {
        label: 'DE55370400440532014000',
        placeholder: 'IBAN',
        format: 'DE####################',
      },
      confirmAccountNumber: {
        label: 'DE55370400440532014000',
        placeholder: 'Confirm IBAN',
        format: 'DE####################',
      },
    },
    address: {
      addressLine1: {},
      addressLine2: {},
      city: {},
    },
  },
  ID: {
    ein: {
      placeholder: 'Tax identification number (NPWP)',
      label: '12.345.678.9-012.345',
      format: '##.###.###.#-###.###',
    },
    bankAccountDetails: {
      routingNumber: {
        label: '000',
        placeholder: 'Bank code',
        validation: {
          value: /^[A-Za-z0-9]{3,4}$/,
          message: 'Bank code should be 3 or 4 characters long',
        },
      },
      accountNumber: {
        label: '000123456789',
        placeholder: 'Account number',
      },
      confirmAccountNumber: {
        label: '000123456789',
        placeholder: 'Confirm account number',
      },
    },
    address: {
      addressLine1: {},
      addressLine2: {},
      city: {},
      zipCode: {
        format: '###',
      },
    },
  },
  FR: {
    ein: {
      placeholder: 'SIREN number',
      label: '123456789',
      format: '#########',
    },
    bankAccountDetails: {
      accountNumber: {
        label: 'FR1420041010050500013M02606',
        placeholder: 'IBAN',
        Component: StyledTextField.upperCase,
        validation: {
          value: /^[A-Za-z0-9]{27}$/,
          message: 'IBAN should be 27 characters long',
        },
      },
      confirmAccountNumber: {
        label: 'FR1420041010050500013M02606',
        placeholder: 'Confirm IBAN',
        format: '###########################',
        Component: StyledTextField.upperCase,
      },
    },
    address: {
      addressLine1: {},
      addressLine2: {},
      city: {},
      zipCode: {
        format: '###',
      },
    },
  },
  ES: {
    ein: {
      placeholder: 'Tax ID number (NIF)',
      label: 'A12345678',
      format: 'A########',
    },
    bankAccountDetails: {
      accountNumber: {
        label: 'ES0700120345030000067890',
        placeholder: 'IBAN',
        Component: StyledTextField.upperCase,
        validation: {
          value: /^[A-Za-z0-9]{24}$/,
          message: 'IBAN should be 24 characters long',
        },
      },
      confirmAccountNumber: {
        label: 'ES0700120345030000067890',
        placeholder: 'Confirm IBAN',
        Component: StyledTextField.upperCase,
      },
    },
    address: {
      addressLine1: {},
      addressLine2: {},
      city: {},
      zipCode: {
        format: '###',
      },
    },
  },
  SA: {
    ein: {
      placeholder: 'Tax Identification Number (TIN)',
      label: '0123456789',
      format: '##########',
    },
    bankAccountDetails: {
      routingNumber: {
        label: 'RIBLSARIXXX',
        placeholder: 'BIC',
        Component: StyledTextField.upperCase,
        fullWidth: true,
        // validation: {
        //   value: /^[A-Za-z0-9]{7,11}$/,
        //   message: 'Routing number should be between 8 to 11 characters long',
        // },
      },
      accountNumber: {
        label: 'SA89370400440532013000',
        placeholder: 'IBAN',
        Component: StyledTextField.upperCase,
        // validation: {
        //   value: /^[A-Za-z0-9]{22}$/,
        //   message: 'IBAN should be 22 characters long',
        // },
      },
      confirmAccountNumber: {
        label: 'SA4420000001234567891234',
        placeholder: 'IBAN Confirm',
        Component: StyledTextField.upperCase,
      },
    },
    address: {
      addressLine1: {},
      addressLine2: {},
      city: {},
      zipCode: {
        format: '#####',
      },
    },
  },
  KR: {
    phone: {
      isRequired: true,
    },
    ein: {
      placeholder: 'Business Registration Number',
      label: '123-45-67890',
      format: '###-##-#####',
    },
    bankAccountDetails: {
      routingNumber: {
        label: 'ABOCKRSE',
        placeholder: 'Bank code',
        Component: StyledTextField.upperCase,
        fullWidth: true,
        validation: {
          value: /^[A-Za-z0-9]{8,11}$/,
          message: 'Routing number should be between 8 to 11 characters long',
        },
      },
      accountNumber: {
        label: '000123456789',
        placeholder: 'Account Number',
        Component: StyledTextField.upperCase,
        validation: {
          value: /^[A-Za-z0-9]{11,15}$/,
          message: 'Routing number should be between 11 to 15 characters long',
        },
      },
      confirmAccountNumber: {
        label: '000123456789',
        placeholder: 'Account Number Confirm',
        Component: StyledTextField.upperCase,
      },
    },
    address: {
      addressLine1: {},
      addressLine2: {},
      city: {},
    },
  },
  AE: {
    ein: {
      placeholder: 'Company registration number',
      label: '1234567890',
      format: '##########',
    },
    bankAccountDetails: {
      accountNumber: {
        label: 'AE070331234567890123456',
        placeholder: 'IBAN',
        Component: StyledTextField.upperCase,
        validation: {
          value: /^[A-Za-z0-9]{23}$/,
          message: 'IBAN should be 23 characters long',
        },
      },
      confirmAccountNumber: {
        label: 'AE070331234567890123456',
        placeholder: 'IBAN Confirm',
        Component: StyledTextField.upperCase,
      },
    },
    address: {
      addressLine1: {},
      addressLine2: {},
      city: {},
    },
  },
  IE: {
    ein: {
      placeholder: 'Company registration number',
      label: '123456',
      format: '##########',
    },
    bankAccountDetails: {
      accountNumber: {
        label: 'AE070331234567890123456',
        placeholder: 'IBAN',
        Component: StyledTextField.upperCase,
        validation: {
          value: /^[A-Za-z0-9]{22}$/,
          message: 'IBAN should be 22 characters long',
        },
      },
      confirmAccountNumber: {
        label: 'AE070331234567890123456',
        placeholder: 'IBAN Confirm',
        Component: StyledTextField.upperCase,
      },
    },
  },
  AU: {
    ein: {
      placeholder: 'Australian business number (ABN)',
      label: '00111222333',
      format: '###########',
    },
    bankAccountDetails: {
      routingNumber: {
        label: '110000',
        placeholder: 'BSB',
        validation: {
          value: /^[A-Za-z0-9]{6}$/,
          message: 'Account number should be 6 characters long',
        },
      },
      accountNumber: {
        label: '000123456',
        placeholder: 'Account number',
        validation: {
          value: /^[A-Za-z0-9]{5,9}$/,
          message: 'Account number should be between 5 to 9 characters long',
        },
      },
      confirmAccountNumber: {
        placeholder: 'Confirm account number',
        label: '000123456',
      },
    },
    address: {
      addressLine1: { isRequired: true },
    },
  },
  FI: {
    ein: {
      placeholder: 'Business ID',
      label: '1234567-8',
      format: '#######-#',
    },
    bankAccountDetails: {
      accountNumber: {
        label: 'FI2112345600000785',
        placeholder: 'IBAN',
        Component: StyledTextField.upperCase,
        validation: {
          value: /^[A-Za-z0-9]{16,20}$/,
          message: 'IBAN should be 18 characters long',
        },
      },
      confirmAccountNumber: {
        label: 'FI2112345600000785',
        placeholder: 'IBAN Confirm',
        Component: StyledTextField.upperCase,
      },
    },
  },
  DK: {
    ein: {
      placeholder: 'VAT registration (CVR)',
      label: 'DK12345678',
      Component: StyledTextField.upperCase,
    },
    bankAccountDetails: {
      accountNumber: {
        label: 'DK5000400440116243',
        placeholder: 'IBAN',
        Component: StyledTextField.upperCase,
        validation: {
          value: /^[A-Za-z0-9]{16,20}$/,
          message: 'IBAN should be 18 characters long',
        },
      },
      confirmAccountNumber: {
        label: 'DK5000400440116243',
        placeholder: 'IBAN Confirm',
        Component: StyledTextField.upperCase,
      },
    },
  },
  SE: {
    ein: {
      placeholder: 'Organization number',
      label: '123456-7890',
      format: '######-####',
    },
    bankAccountDetails: {
      accountNumber: {
        label: 'SE3550000000054910000003',
        placeholder: 'IBAN',
        Component: StyledTextField.upperCase,
        validation: {
          value: /^[A-Za-z0-9]{24}$/,
          message: 'IBAN should be 24 characters long',
        },
      },
      confirmAccountNumber: {
        label: 'SE3550000000054910000003',
        placeholder: 'IBAN Confirm',
        Component: StyledTextField.upperCase,
      },
    },
  },
};

import React, { CSSProperties, useEffect, useState, VFC } from 'react';
import styled from '@emotion/styled';
import { theme, Theme } from '../../theme';
import { observer } from 'mobx-react';
import { UploadingFile } from './QualificationStepper';
import { useNProgress } from '@tanem/react-nprogress';
import { NProgressBarLoader } from '../../ui-lib/Loader/NProgressBarLoader';
import 'react-popper-tooltip/dist/styles.css';
import { cond } from 'lodash/fp';
import { usePopperTooltip } from 'react-popper-tooltip';
import { RemoveItemButton } from './RemoveItemButton';
import { Typography } from '../../ui-lib';

export const FileStatusChipCard = styled('div')<{ theme: Theme }>(({ theme }) => ({
  backgroundColor: 'white',
  minWidth: 120,
  maxWidth: 500,
  overflow: 'hidden',
  height: 35,
  borderRadius: theme.radius.card / 2,
  border: `1px solid ${theme.palette.main.lightGrey}`,
  filter: 'drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.1))',
  padding: theme.gutters.base,
  opacity: 1,
  '&:hover': {
    opacity: '1 !important',
    animation: 'fadeIn ease 1s',
  },
}));

export const ChipContent = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

export interface FileStatusChipProps extends UploadingFile {
  onDeleteClicked: (fileName: string) => Promise<void>;
}

export const FileStatusChip: VFC<FileStatusChipProps> = observer(({ name, status, message, onDeleteClicked }) => {
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip({
    offset: [0, 15],
  });

  const isFailed = status === 'failed';
  const isLoading = status === 'uploading';
  const isUploaded = status === 'uploaded';

  const { animationDuration, isFinished, progress } = useNProgress({
    isAnimating: isLoading,
  });

  const [shouldShowProgress, setsShouldShowProgress] = useState(true);

  const getStatusStyle = (): CSSProperties => {
    return cond<void, CSSProperties>([
      [() => isFailed, () => ({ backgroundColor: theme.palette.indicators.errorLight, opacity: 0.4 })],
      [() => isUploaded, () => ({})],
      [() => true, () => ({ backgroundColor: 'white' })],
    ])();
  };

  useEffect(() => {
    if (isUploaded || isFailed) {
      setTimeout(() => {
        setsShouldShowProgress(false);
      }, 500);
    }
  }, [isFailed, isUploaded, isFinished]);

  return (
    <>
      {visible && !isLoading && (
        <div ref={setTooltipRef} {...getTooltipProps({ className: 'tooltip-container' })}>
          {message}
          <div {...getArrowProps({ className: 'tooltip-arrow', style: { fontSize: 14 } })} />
        </div>
      )}
      <FileStatusChipCard style={getStatusStyle()} ref={setTriggerRef} className={'fade-in-quick'}>
        <ChipContent>
          <div />
          <Typography variant="caption" center={true} style={{ wordBreak: 'break-all' }}>
            {name}
          </Typography>
          {!isLoading && isUploaded ? <RemoveItemButton onClick={() => onDeleteClicked(name)} /> : <div />}
        </ChipContent>
        {shouldShowProgress && <NProgressBarLoader animationDuration={animationDuration} progress={progress} />}
      </FileStatusChipCard>
    </>
  );
});

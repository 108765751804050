import styled from '@emotion/styled';
import { PRIMARY_BLUE, Theme } from '../../../../../../theme';
import { Box, Button } from '@material-ui/core';
import {
  BankPaymentMethodSchema,
  UpdateVendorOnboardingProps,
} from '../../../hooks/useVendorOnboarding/vendor-onboarding.types';
import React, { useState, VFC } from 'react';
import { StepCard } from '../../../../../Components/StepCard';
import BankSvg from '../../../../../assets/bank.svg';
import { BankAccountDetails } from './BankAccountDetails';
import { useFormContext } from 'react-hook-form';

const BankAccountContainer = styled('div')<{ theme: Theme }>(({ theme }) => ({
  border: `1px solid ${theme.palette.main.lightGrey}`,
  width: '100%',
  height: '80px',
  padding: '0 1.5rem',
  boxSizing: 'border-box',
  lineHeight: '80px',
  verticalAlign: 'middle',
  userSelect: 'none',
}));

const ButtonContainer = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'end',
  marginTop: '1.5rem',
});

const UpdateButton = styled(Button)({
  color: `${PRIMARY_BLUE} !important`,
  textTransform: 'uppercase',
});

interface BankAccountPreviewProps {
  bankAccount: BankPaymentMethodSchema;
  onUpdateClick: VoidFunction;
}

const BankAccountPreview: VFC<BankAccountPreviewProps> = ({ bankAccount, onUpdateClick }) => (
  <StepCard iconSrc={BankSvg} headerText={['Your banking', 'information']}>
    <Box display="flex" flexDirection="column">
      <BankAccountContainer>{`Bank account: ${bankAccount.institutionName} ${bankAccount.accountNumberMask}`}</BankAccountContainer>
      <ButtonContainer>
        <UpdateButton variant="text" onClick={onUpdateClick}>
          <b>update your banking information</b>
        </UpdateButton>
      </ButtonContainer>
    </Box>
  </StepCard>
);

interface BankAccountStepProps {
  bankAccount?: BankPaymentMethodSchema;
}

export const BankAccountUpdate: VFC<BankAccountStepProps> = ({ bankAccount }) => {
  const { setValue } = useFormContext<UpdateVendorOnboardingProps>();
  const [isUpdatingBankDetails, enableUpdatingBankDetails] = useState(false);

  const backToPreview = () => {
    setValue('bankAccountDetails', undefined);
    enableUpdatingBankDetails(false);
  };

  if (isUpdatingBankDetails || !bankAccount) {
    return <BankAccountDetails backToPreview={backToPreview} />;
  }
  return <BankAccountPreview onUpdateClick={() => enableUpdatingBankDetails(true)} bankAccount={bankAccount} />;
};

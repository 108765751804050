import React from 'react';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

export interface RemoveItemButtonProps {
  onClick: () => void;
}

export const RemoveItemButton = ({ onClick }: RemoveItemButtonProps) => {
  return (
    <IconButton aria-label="close" size="small" onClick={onClick} data-analytics-id="qualification-deleteFile">
      <CloseIcon style={{ fontSize: 14 }} />
    </IconButton>
  );
};

import styled from '@emotion/styled';
import { Theme } from '../../theme';
import React from 'react';

const StyledFooter = styled('footer')<{ theme: Theme }>(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-end',
  height: 0,
  width: '100%',
}));

export const QualificationFooter = () => <StyledFooter />;

import * as React from 'react';
import styled from '@emotion/styled';

import { Typography, Modal, Button } from './../index';
import { Theme } from './../../theme';

export interface DialogProps {
  title: string;
  content: string | React.ReactNode;
  confirmationButton?: {
    text: string;
    action: () => void;
  };
  cancelButton?: {
    text: string;
    action: () => void;
  };
}

export const Container = styled('div')<{ theme: Theme }>(({ theme }) => ({
  label: 'dialog',
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: 'min-content 1fr min-content',
  maxHeight: '100%',
  gap: theme.gutters.base * 2,
}));

export const ContentWrap = styled('div')<{ theme: Theme }>(({ theme }) => ({
  marginBottom: theme.gutters.base * 2,
  maxHeight: '100%',
  overflow: 'auto',
}));

export const ButtonWrap = styled('div')<{ theme: Theme; hasOneButton: boolean }>(({ theme, hasOneButton }) => ({
  display: 'flex',
  justifyContent: hasOneButton ? 'center' : 'space-between',
}));

export const Dialog: React.FunctionComponent<DialogProps> = ({ title, content, confirmationButton, cancelButton }) => {
  return (
    <Modal>
      <Container>
        <Typography {...{ variant: 'h1', bolder: true, center: true }}>{title}</Typography>
        <ContentWrap>
          {typeof content === 'string' ? (
            <Typography {...{ variant: 'body1', center: true, bold: true }}>{content}</Typography>
          ) : (
            content
          )}
        </ContentWrap>
        <ButtonWrap {...{ hasOneButton: !cancelButton }}>
          {cancelButton && <Button {...{ variant: 'text', onClick: cancelButton.action }}>{cancelButton.text}</Button>}
          {confirmationButton && (
            <Button {...{ size: 'medium', onClick: confirmationButton.action }}>{confirmationButton.text}</Button>
          )}
        </ButtonWrap>
      </Container>
    </Modal>
  );
};

export default Dialog;

import * as React from 'react';
import { Line, Section, StyledBox } from './Review';

export interface SummaryProps {
  paymentDetails: {
    subtotal: string;
    shipping: string;
    estimatedTax: string;
    discount: string;
    total: string;
  };
  withLine: boolean;
}

export const Summary: React.FunctionComponent<SummaryProps> = ({ withLine, paymentDetails }) => {
  return (
    <Section {...{ title: 'Summary' }}>
      <StyledBox>
        <Line {...{ withLine, name: 'Subtotal', value: paymentDetails.subtotal }} />
        <Line {...{ withLine, name: 'Shipping', value: paymentDetails.shipping }} />
        <Line {...{ withLine, name: 'Est. Tax', value: paymentDetails.estimatedTax }} />
        {paymentDetails.discount && <Line {...{ withLine, name: 'Discount', value: paymentDetails.discount }} />}
        <Line {...{ name: 'Total', value: paymentDetails.total }} />
      </StyledBox>
    </Section>
  );
};

export default Summary;

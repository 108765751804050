export enum Events {
  HowToPaySelected = 'HowToPaySelected',
  FundingInstrumentSelected = 'FundingInstrumentSelected',
  LinkBank = 'LinkBank',
  ManageCards = 'ManageCards',
  ManageBankAccounts = 'ManageBankAccounts',
  PayWithTermsResponse = 'PayWithTermsResponse',
  CheckoutCompleted = 'CheckoutCompleted',
}

export enum Services {
  Checkout = 'Checkout',
  Invoice = 'Invoice',
}

declare var amplitude: any;

export const identify = (checkoutId: string, service: Services, environment: string, email: string, price: number) => {
  try {
    if (typeof amplitude === 'undefined') {
      return;
    }

    var identify = new amplitude.Identify()
      .set('service', service)
      .set('environment', environment)
      .set('checkoutId', checkoutId)
      .set('price', price);
    amplitude.getInstance()?.setUserId(email);
    amplitude.getInstance()?.identify(identify);
  } catch (err) {
    console.log('failed sending events');
  }
};

export const track = (eventName: Events, data: any = {}) => {
  try {
    amplitude?.getInstance()?.logEvent(eventName, data);
  } catch (err) {
    console.log('failed sending events');
  }
};

import React, { FunctionComponent, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Box } from '@material-ui/core';
import PhoneInput, { PhoneInputProps } from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { Theme } from '../../../theme';
import styled from '@emotion/styled';
import { Typography } from '../../../ui-lib';
import { stubTrue, cond } from 'lodash/fp';

interface ColorProps {
  focus: boolean;
  error: boolean;
  theme: Theme;
}

const colorCond = cond<ColorProps, string>([
  [({ error }) => error, ({ theme }) => theme.palette.indicators.danger],
  [({ focus }) => focus, ({ theme }) => theme.palette.main.primary],
  [stubTrue, ({ theme }) => theme.palette.main.gray],
]);

const Container = styled(Box)<ColorProps>(({ error, focus, theme }) => ({
  '& .react-tel-input .form-control': {
    width: '100%',
    border: 'none',
    borderBottom: `${error ? 2 : 1}px solid ${colorCond({ error, focus, theme })}`,
    borderRadius: 0,
    padding: '12px 26px 12px 44px',
    '&:hover': !error &&
      !focus && {
        borderBottom: `2px solid black !important`,
      },
    '&:focus': {
      borderWidth: '2px',
      boxShadow: 'none',
    },
  },
  '& .selected-flag': {
    paddingLeft: 0,
  },
}));

const ErrorMessage = styled(Typography)<{ theme: Theme }>(({ theme }) => ({
  marginTop: '3',
  color: theme.palette.indicators.danger,
}));

const Label = styled(Typography)<{ focus: boolean; error: boolean; theme: Theme }>((props) => ({
  color: colorCond(props),
}));

interface IPhoneInputField extends PhoneInputProps {
  defaultCountry?: string;
  name?: string;
  isRequired?: boolean;
}

export const PhoneInputField: FunctionComponent<IPhoneInputField> = ({
  defaultCountry = 'us',
  name = 'phone',
  isRequired,
  ...props
}) => {
  const [focus, setFocus] = useState<boolean>(false);
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  const colorTerms = { error: errors.phone, focus };

  return (
    <Box display="flex" flexDirection="column" mt={2}>
      <Label variant="label" {...colorTerms}>
        Phone number{isRequired ? '' : ' (Optional)'}
      </Label>
      <Container {...colorTerms}>
        <Controller
          name={name}
          control={control}
          defaultValue={''}
          render={({ field: { onChange, onBlur, ...field } }) => (
            <PhoneInput
              {...field}
              specialLabel={''}
              country={defaultCountry?.toLocaleLowerCase()}
              autocompleteSearch={true}
              enableSearch={true}
              onChange={(value, country, e, formattedValue) => {
                onChange(formattedValue);
                setValue && setValue('phone', formattedValue);
              }}
              onFocus={() => {
                setFocus(true);
              }}
              onBlur={() => {
                setFocus(false);
              }}
              copyNumbersOnly={false}
              isValid={!errors.phone}
              {...props}
            />
          )}
        />
        {errors.phone && <ErrorMessage variant={'label'}>{errors.phone.message || errors.phone.type}</ErrorMessage>}
      </Container>
    </Box>
  );
};

export const checkoutRoot: string = process.env.CHECKOUT_ROOT!;
export const apiBaseURL: string = process.env.API_BASE_URL!;
export const stripePublicKey: string = process.env.STRIPE_PK!;
export const environment: string = process.env.NODE_ENV!;
export const fullStoryOrgId: string | undefined = process.env.FULL_STORY_ORG_ID;
export const logRocketKey: string | undefined = process.env.LOG_ROCKET_KEY;

const PROD_ENV = 'production';
const SANDBOX_ENV = 'sandbox';

if (!checkoutRoot) throw new Error(`No CHECKOUT_ROOT, can't build`);
if (!apiBaseURL) throw new Error(`No API_BASE_URL, can't build`);
if (!stripePublicKey) throw new Error(`No STRIPE_PK, can't build`);

// TODO: make environment an enum
const lowerCasedEnv = environment.toLowerCase();
const isProduction = lowerCasedEnv === PROD_ENV;
const isSandbox = lowerCasedEnv === SANDBOX_ENV;
const isProdOrSandboxEnv = isProduction || isSandbox;

export const config = {
  checkoutRoot,
  apiBaseURL,
  stripePublicKey,
  environment,
  isProdOrSandboxEnv,
  fullStoryOrgId,
  isProduction,
  logRocketKey,
};

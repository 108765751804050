import * as Yup from 'yup';
export const EIN_US_REGEX = /^\d{2}-?\d{7}$/;

export const yupEINFieldSchema = Yup.string()
  .trim()
  .matches(EIN_US_REGEX, 'Please enter a valid Employer Identification Number')
  .required('Required');

export const yupEINFieldSchemaNotRequired = Yup.string()
  .trim()
  .matches(EIN_US_REGEX, 'Please enter a valid Employer Identification Number')
  .nullable();

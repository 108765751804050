import React from 'react';
import styled from '@emotion/styled';
import { Theme } from '../../theme';
import Typography from '../Typography/Typography';

export type AlertVariant = 'error' | 'success';

export interface StickyAppBarAlertProps {
  message: string;
  variant: AlertVariant;
}

export const variantColorMap: Record<AlertVariant, string> = {
  success: '#B3FAD1',
  error: '#ED0000',
};

export const AppBar = styled('div')<{ theme: Theme }>(({ theme }) => ({
  position: 'sticky',
  top: 0,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: 40,
  zIndex: 99999,
}));

export const StickyAppBarAlert = ({ message, variant }: StickyAppBarAlertProps) => {
  const color = variantColorMap[variant];
  return (
    <AppBar style={{ backgroundColor: color, padding: 10 }}>
      <Typography align="center" color={variant === 'success' ? 'main' : 'secondary'}>
        {message}
      </Typography>
    </AppBar>
  );
};

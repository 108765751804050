import * as React from 'react';
import styled from '@emotion/styled';
import { Dot, Typography, InnerScroll } from './../../ui-lib';
import { Theme } from './../../theme';
import { BankAccountMeta } from './../../stores/CheckoutStore';
import Options from '../Options/Options';
import Option from '../Option/Option';
import { BankIcon, DeleteIcon } from './../../icons';
import { flexStartCenter } from './../../styles/common';
import CurrentBank from '../CurrentBank/CurrentBank';
import { Header } from '../CreditCardManagement/CreditCardManagement';

export interface BankAccountsListProps {
  list: BankAccountMeta[];
  onSelect: (ind: number) => void;
  addNewButton?: React.ReactNode;
  selectedId: string | null;
  deleteBankAccount: (itemId: string) => void;
  onAddBankClick: () => void;
}

export const Wrap = styled('div')<{ theme: Theme }>(({ theme }) => ({
  padding: `${theme.gutters.base * 2}px`,
  height: '100%',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
}));
export const List = styled(Options)<{ theme: Theme }>(({ theme }) => ({
  minHeight: 0,
  marginBottom: theme.gutters.base * 2,
}));

export const CurrentBankWrap = styled('div')<{ theme: Theme }>(({ theme }) => ({
  minHeight: theme.gutters.base * 3,
  display: 'flex',
  alignItems: 'center',
}));

export const AddBankWrap = styled('div')<{ theme: Theme }>(({ theme }) => ({
  ...flexStartCenter,
}));

export const ListItem = styled(Option)<{ theme: Theme; isSelected: boolean }>(({ theme, isSelected }) => ({}));

export const BankAccountsList: React.FunctionComponent<BankAccountsListProps> = ({
  selectedId,
  list = [],
  onSelect,
  addNewButton,
  deleteBankAccount,
  onAddBankClick,
}) => {
  const [isEditMode, setEditMode] = React.useState<boolean>(false);

  return (
    <>
      <Header>
        <Typography {...{ component: 'h1', variant: 'h1' }}>Manage Bank Accounts</Typography>
        <Typography
          {...{ onClick: () => setEditMode(!isEditMode), variant: 'captionBold', style: { cursor: 'pointer' } }}
        >
          {isEditMode ? 'Done' : 'Edit'}
        </Typography>
      </Header>
      <Wrap>
        <InnerScroll>
          <List
            {...{
              onClick: (e) => {
                const target = e.target as HTMLElement;

                const ind: number = Number(target.dataset['ind'] || target.closest('li')?.dataset['ind']);

                if (typeof ind !== 'number') {
                  return;
                }

                onSelect(ind);
              },
            }}
          >
            {list.map(({ name, mask, accountId, bankName, itemId }, ind) => {
              return (
                <ListItem {...{ key: accountId, 'data-ind': ind, isSelected: selectedId === accountId }}>
                  <CurrentBankWrap>
                    <CurrentBank {...{ name, bankName, mask }} />
                  </CurrentBankWrap>

                  {isEditMode && (
                    <div
                      {...{
                        onClick: () => {
                          deleteBankAccount(itemId);
                        },
                      }}
                    >
                      <DeleteIcon />
                    </div>
                  )}
                </ListItem>
              );
            })}
          </List>

          <Option {...{ isSelected: false, onClick: onAddBankClick }}>
            <AddBankWrap>
              <BankIcon />
              <Typography {...{ style: { marginLeft: 16 } }}>Add Bank</Typography>
            </AddBankWrap>
          </Option>
        </InnerScroll>
      </Wrap>
      {addNewButton}
    </>
  );
};

export default BankAccountsList;

import React, { FC } from 'react';
import { Typography } from '../../../../../ui-lib/Typography/Typography';
import { Box } from '@material-ui/core';
import { SubTitle } from './Wrappers';

export const OnboardingForm: FC = ({ children }) => (
  <>
    <div>
      <Typography variant="h148Bold" center={true}>
        <div>Set up your payouts</div>
      </Typography>
      <Box mt={4} />
      <SubTitle variant="h1" center={true}>
        Fill out a few details so you can start getting paid
      </SubTitle>
    </div>
    {children}
  </>
);

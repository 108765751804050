import { BillingAddress, BusinessAddress } from '../sections/qualification/BusinessDetailsForm';
import { PlaidMetadata } from '../types/Plaid';
import { QualificationStatus } from '../stores/QualificationStore';

export interface BusinessInfo {
  businessName: string;
  businessType: string;
  ein: string;
  resaleNumber: number;
  dontHaveEin?: boolean;
  dontHaveResaleNumber?: boolean;
  monthlySales: number;
  website: string;
  mobilePhoneNumber: string;
  businessAddress: string;
  addressLine2?: string;
  city: string;
  state: string;
  zipCode: string;
  googleMapsLink?: string;
}

export interface OwnerInfo {
  firstName: string;
  lastName: string;
  homeAddress: string;
  city: string;
  state: string;
}

export interface PreApprovalInfo {
  businessName: string;
  ein?: string;
  dnb?: string;
  businessAddress?: BusinessAddress;
}

export interface qualificationFormData {
  businessInfo: BusinessInfo;
  ownerInfo: OwnerInfo;
}

export interface PlaidData {
  publicToken: string;
  accountId: string;
  meta: any;
}

export interface UpdateQualificationRequest {
  plaidData?: PlaidData;
  preApprovalInfo?: PreApprovalInfo;
  qualificationFormData?: qualificationFormData;
  status?: QualificationStatus;
  acceptedPrivacyPolicyAt?: Date;
  acceptedTermsAndConditionsAt?: Date;
  authorizedBankAccountDebitOnLatePaymentsAt?: Date;
}

export interface PersistPlaidRequest {
  publicToken: string;
  accountId: string;
  meta: any;
  isQualificationAccount?: boolean;
}

export type PersistPlaidResponse = Array<{
  itemId: string;
  institution: PlaidMetadata['institution'];
  account: PlaidMetadata['accounts'][0];
}>;

export interface DeleteBankAccountResponse {
  itemId: string;
}

export interface SetWireTransferResponse {
  id: string;
  accountNum: string;
  routingNum: string;
  bankName: string;
  swiftCode: string;
}

export interface SubmitPaymentRequest {
  /**
   * stripe-ach stands for bankTransfer (plaid)
   * stripe-cc stands for creditCard (stripe)
   * stripe-ach-credit-transfer stands for wireTransfer
   */
  gateway?: 'stripe-ach' | 'stripe-cc' | 'stripe-ach-credit-transfer' | undefined;
  paymentGatewayId?: string | undefined;
  paymentTermNumericVal?: number | undefined;
  depositPaymentId?: string | undefined;
  paymentTerm?: PaymentTermType | undefined;
  billedAutomatically?: boolean | undefined;
}

export interface SubmitPayLaterRequest {
  plaidItemId?: string;
}

export interface SubmitPayLaterResponse {
  decision: FinanceAssessmentDecision;
  reason?: FinanceAssessmentReason;
}

export enum FinanceAssessmentDecision {
  Approved = 'approved',
  Declined = 'declined',
}

export enum FinanceAssessmentReason {
  FinanceAssessmentFailed = 'finance_assessment_failed',
}

export interface SubmitElectronicQuoteRequest {
  message?: string;
  checkoutToken: string;
}

export interface SubmitElectronicQuoteResponse {}

export interface GetListOfStripeCardsResponse {
  data: {
    existingMethods: Array<{
      id: string;
      object: string;

      billing_details: {
        email: string | null;
        name: string | null;
        phone: string | null;
        address: {
          city: string | null;
          country: string | null;
          line1: string | null;
          line2: string | null;
          postal_code: string | null;
          state: string | null;
        };
      };
      created: number;
      customer: string;
      livemode: boolean;
      metadata: object;
      type: string;
      card: {
        brand: string;
        checks: {
          address_line1_check: string | null;
          address_postal_code_check: string | null;
          cvc_check: string | null;
        };
        /** Two digit */
        country: string | null;
        exp_month: number;
        exp_year: number;
        fingerprint: string;
        funding: string;
        generated_from: string | null;
        last4: string;
        networks: { available: string[]; preferred: null };
        three_d_secure_usage: { supported: true };
        wallet: null;
      };
    }>;
  };
  stripepk: string;
}

export interface DeleteCreditCardResponse {
  cardId: string;
}

export interface LoginPayload {
  checkoutKey?: string;
  qualificationKey?: string;
  emailAddress: string | null;
  retryCode: boolean;
  smsCode: boolean;
}

export interface LoginResponse {
  accessToken: string;
}

export interface BuyerCheckoutSchema {
  id: string | number;
  email: string;
  firstName?: string;
  lastName?: string;
  businessName: string;
  phone?: string;
  qualificationStatus?: BuyerQualificationStatus;
}

export enum BuyerQualificationStatus {
  PENDING = 'pending',
  APPROVED = 'completed',
  IN_REVIEW = 'inReview',
  DECLINED = 'declined',
}

export interface AddressSchema {
  firstName?: string;
  lastName?: string;
  addressLine1?: string;
  addressLine2?: string | null;
  countryCode?: string;
  state?: string | null;
  zipCode?: string;
  city?: string;
}

export interface LineItemsSchema {
  id: number;
  title: string;
  quantity: number;
  productId: string;
  productSku: string;
  variationId: string;
  itemType: string;
  price: string;
}

export type PaymentTermType = 'IMMEDIATE' | 'NET';

export interface MilestoneRequest {
  name?: string;
  chargeDate?: Date;
  amount: number;
  type: MilestoneType;
  note?: string;
}

export type ChargeStatus = 'pending' | 'charged' | 'failed' | 'waitingForPayment' | 'refunded' | 'canceled';

export interface Charge {
  id?: string;
  note?: string;
  name?: string;
  createdAt?: string;
  chargeDate?: string;
  amount: number;
  currency: string;
  buyerId: number;
  type: string;
  status: ChargeStatus;
  paymentMethodType?: string;
  processingFee?: number;
  invoicePdfLink: string;
}

export type RecurringTimeUnit = 'monthly' | 'yearly';

export interface RecurringPayment {
  timeUnit: RecurringTimeUnit;
  numberOfTotalRepeats?: number;
  startDate?: string;
}

export type MilestoneType = 'time' | 'event';

export type CheckoutStatus = 'open' | 'closed' | 'pending' | 'auth' | 'auth_required' | 'expired' | 'canceled';
export type PlanType = 'milestones' | 'installments' | 'invoice' | 'recurring';

export interface LineItemRequest {
  title: string;
  quantity?: number;
  productId?: string;
  productSku?: string;
  variationId?: string;
  itemType?: string;
  price: number;
  tax?: number;
}

export interface LineRequest {
  lineItems?: LineItemRequest[];
  tax?: number;
  shippingPrice?: number;
  vendorId: string;
}

export interface CommunicationConfig {
  emailsTo?: string[];
  emailsCc?: string[];
}

export interface AddressRequest {
  addressLine1: string;
  addressLine2: string;
  countryCode: string;
  state: string;
  zipCode: string;
  city: string;
}

export interface FinancingConfig {
  financingNetAmount?: number;
  financingNetDays: number;
}

export interface TransactionPlan {
  planType: PlanType;
  chargeDate?: Date;
  installments?: number;
  milestones?: MilestoneRequest[];
  recurringPayment?: RecurringPayment;
}

export interface GetTransactionDataResponse {
  id: string;
  currency: string;
  externalReferenceId: string;
  buyer: BuyerCheckoutSchema;
  lines: LineRequest[];
  totalShipping: number;
  totalLineItems: number;
  totalTax: number;
  totalPrice: number;
  totalDiscount: number;
  vendorName: string;
  vendorLogo: string;
  communicationConfig: CommunicationConfig;
  billingAddress: AddressRequest;
  shippingAddress: AddressRequest;
  financingConfig: FinancingConfig;
  allowedPaymentMethods: PaymentMethodName[];
  autoPayouts: boolean;
  notes: string;
  plan: TransactionPlan;
  marketplaceFixedTake?: number;
  marketplacePercentageTake?: number;
  status: CheckoutStatus;
  createdAt: Date;
  selectedPaymentMethod: PaymentMethodName;
  autoBilled: boolean;
}

export interface BankTransfer {
  accountNum: string;
  routingNum: string;
  bankName: string;
  swiftCode: string;
}

export interface InvoiceData {
  invoice: {
    bankTransfer: BankTransfer;
    isPaid: boolean;
    prettyToken: string;
    pdfLink: string;
    chargeDate?: string;
    instructions: { instructions: string; mailAddress: string };
  };
}

export type PaymentMethodName = 'creditCard' | 'bank' | 'check' | 'payWithTerms' | 'invoice';

export interface AllowedPaymentMethod {
  name: PaymentMethodName;
  days: number[];
}

export interface PaymentMethod {
  allowedPaymentMethods: Array<AllowedPaymentMethod>;
  allowedTermsPaymentMethods?: Array<AllowedPaymentMethod>;
}

export type SellerStatus = 'pending' | 'active' | 'inactive' | 'deleted' | 'suspended';

export interface ColorOverrides {
  primary?: string;
  secondary?: string;
}

export interface Merchant {
  name: string;
  logo: string;
  businessDomain: string;
  coverPhoto: string;
  merchantDomain: string;
  businessURL: string;
  colorOverrides?: ColorOverrides;
  qualificationWithPlaid: boolean;
}
export enum QualificationFlow {
  BASIC = 'basic',
  FULL = 'full',
}

export interface BuyerQualification {
  status: QualificationStatus;
  buyerId: number | string;
  buyerName?: string;
  billingAddress?: BillingAddress;
  merchant: Merchant;
  requestedAmountInCents: number;
  flow: QualificationFlow;
  qualificationType: string;
}

export type GetPaymentMethodsResponse = PaymentMethod;

export interface ConfirmRequest {
  paymentMethodId?: string;
  paymentMethodType: PaymentMethodName;
  isFinanced: boolean;
  isAuth: boolean;
}

export interface ConfirmResponse {
  id: string;
  status: CheckoutStatus;
  redirectURL?: string;
}

export interface BuyerSettingsResponse {
  settings: BuyerSettings;
}

export type GetBankAccountsResponse = Array<{
  itemId: string;
  account: {
    id: string;
    name: string;
    type: string;
    subtype: string;
    mask: string;
  };
  institution: { name: string; institution_id: string };
}>;

export type Instructions = {
  bank: {
    bankTransferDetails: { accountNum: string; routingNum: string; swiftCode: string; bankName: string; id: string };
  };
  check: { instructions: string; mailAddress: string };
};

export interface BuyerSettings {
  billingAddress?: boolean;
  manualBillingAddress?: boolean;
  primaryColor?: string;
  secondaryColor?: string;
}

export interface SmallBusinessFormFields {
  businessName: string;
  businessType: string;
  monthlySales: number;
  website: string;
  mobilePhoneNumber: string;
  ein: string;
  resaleNumber: string;
  dontHaveEin: boolean;
  dontHaveResaleNumber: boolean;
  businessAddress: string;
  address2: string;
  city: string;
  state: string;
  zipCode: string;
  googleMapsLink?: string;
  firstName: string;
  lastName: string;
  ownerAddress: string;
  ownerAddress2: string;
  ownerCity: string;
  ownerState: string;
  ownerZipCode: string;
}

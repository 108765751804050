import styled from '@emotion/styled';
import { Theme } from '../../theme';
import React from 'react';
import { flexStartCenter } from '../../styles/common';
import { ArrowBack } from '@material-ui/icons';
import { Typography } from './../../ui-lib';
import history from './../../routing/history';
import { device } from '../../utils/devices/devices.utils';
import { PoweredByBalance } from './PoweredByBalance';
import { Image } from './common';

export interface QualificationHeaderProps {
  backEnabled: boolean;
  logoEnabled?: boolean;
  logo?: string;
}
export const HeaderContainer = styled('header')<{ theme: Theme }>(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0 2rem',
  gap: 20,
}));

export const HeaderItem = styled('div')<{ theme: Theme }>(() => ({
  ...flexStartCenter,
  zIndex: 1000,
  height: 80,
  [`@media ${device.mobileL}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: '1px solid lightgray',
    height: '100%',
  },
}));

export const BackButtonContainer = styled('div')<{ theme: Theme }>(({ theme }) => ({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  color: theme.palette.main.gray,
  width: 72,
  '&:hover': {
    color: theme.palette.main.link,
  },
}));

export const QualificationHeader = ({ backEnabled, logoEnabled, logo }: QualificationHeaderProps) => {
  return (
    <HeaderContainer>
      <HeaderItem>
        {backEnabled && (
          <BackButtonContainer onClick={() => history.goBack()} data-analytics-id="qualification-back">
            <ArrowBack />
            <Typography variant="caption" style={{ fontFamily: 'Aeonik-Bold' }}>
              BACK
            </Typography>
          </BackButtonContainer>
        )}
        {logoEnabled && <Image src={logo} />}
      </HeaderItem>
      <PoweredByBalance />
    </HeaderContainer>
  );
};

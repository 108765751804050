import * as Yup from 'yup';

export const DOMAIN_REGEX =
  '^(https?:\\/\\/)?(www.)?' + // protocol
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
  '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
  '(\\#[-a-z\\d_]*)?$';

export const yupDomainFieldSchema = Yup.string()
  .required('Required')
  .matches(RegExp(DOMAIN_REGEX, 'i'), 'Please enter a valid domain (e.g. www.getbalance.com)');

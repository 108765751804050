import * as React from 'react';
import { CreditCardMeta } from '../../stores/CheckoutStore';
import { CreditCardFormProps } from './CreditCardForm';
import CreditCardList, { CreditCardListProps } from './CreditCardList';
import { NotificationProps } from '../Notification/Notification';
import { Typography } from '../../ui-lib';
import { flexBetweenCenter } from '../../styles/common';
import styled from '@emotion/styled';
import { Theme } from '../../theme';
import CreditCard from '../../stores/models/CreditCard';

export interface CreditCardManagementProps {
  creditCardList: CreditCardListProps['list'];
  clientSecret: CreditCardFormProps['clientSecret'];
  onError: CreditCardFormProps['onError'];
  onSuccess: CreditCardFormProps['onSuccess'];
  whatToShow: WhatToShow;
  setCreditCardWhatToShow: (option: WhatToShow) => void;
  setSelectedCreditCard: (creditCardMeta: CreditCardMeta) => void;
  selectedCreditCard: CreditCardMeta | null;
  setLoading: CreditCardFormProps['setLoading'];
  notification: NotificationProps | null;
  deleteCreditCard?: (id: string) => void;
  generateClientSecret: () => Promise<void>;
  showCardForm: CreditCard['showCardForm'];
  setShowCardForm: CreditCard['setShowCardForm'];
}
type WhatToShow = 'form' | 'list';

export const Header = styled('div')<{ theme: Theme }>(({ theme }) => ({
  ...flexBetweenCenter,
}));

export const CreditCardManagement: React.FunctionComponent<CreditCardManagementProps> = ({
  creditCardList,
  clientSecret,
  onSuccess,
  onError,
  whatToShow,
  setSelectedCreditCard,
  selectedCreditCard,
  setLoading,
  notification,
  deleteCreditCard,
  generateClientSecret,
  showCardForm,
  setShowCardForm,
}) => {
  const [isEditMode, setEditMode] = React.useState<boolean>(false);

  return (
    <div {...{ style: { height: '100%' } }}>
      {whatToShow === 'list' && (
        <>
          <Header>
            <Typography {...{ component: 'h1', variant: 'h1' }}>Manage Cards</Typography>
            <Typography
              {...{ onClick: () => setEditMode(!isEditMode), variant: 'captionBold', style: { cursor: 'pointer' } }}
            >
              {isEditMode ? 'Done' : 'Edit'}
            </Typography>
          </Header>
          <div {...{ style: { height: '100%' } }}>
            <CreditCardList
              {...{
                notification,
                isEditMode,
                clientSecret,
                onSuccess,
                onError,
                setLoading,
                deleteCreditCard,
                list: creditCardList,
                onSelect: (ind) => setSelectedCreditCard(creditCardList[ind]),
                selectedId: selectedCreditCard?.id ?? null,
                generateClientSecret,
                showCardForm,
                setShowCardForm,
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default CreditCardManagement;

import * as React from 'react';

export const SecureIcon = () => {
  return (
    <svg width="20" height="23" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.217 2.02301L10.217 0.0230126C10.074 -0.0079964 9.92601 -0.0079964 9.783 0.0230126L0.783 2.02301C0.56077 2.07241 0.362047 2.19616 0.219677 2.37381C0.0773066 2.55145 -0.000188228 2.77236 3.43331e-07 3.00001V13C3.43331e-07 15.6522 1.05357 18.1957 2.92893 20.0711C4.8043 21.9464 7.34784 23 10 23C12.6522 23 15.1957 21.9464 17.0711 20.0711C18.9464 18.1957 20 15.6522 20 13V3.00001C20.0002 2.77236 19.9227 2.55145 19.7803 2.37381C19.638 2.19616 19.4392 2.07241 19.217 2.02301ZM15 16C15 16.2652 14.8946 16.5196 14.7071 16.7071C14.5196 16.8947 14.2652 17 14 17H6C5.73478 17 5.48043 16.8947 5.29289 16.7071C5.10536 16.5196 5 16.2652 5 16V11C5 10.7348 5.10536 10.4804 5.29289 10.2929C5.48043 10.1054 5.73478 10 6 10H7V8.00001C7 7.20436 7.31607 6.4413 7.87868 5.87869C8.44129 5.31608 9.20435 5.00001 10 5.00001C10.7956 5.00001 11.5587 5.31608 12.1213 5.87869C12.6839 6.4413 13 7.20436 13 8.00001V10H14C14.2652 10 14.5196 10.1054 14.7071 10.2929C14.8946 10.4804 15 10.7348 15 11V16Z"
        fill="#9FA2B4"
      />
      <path
        d="M10 7C9.73478 7 9.48043 7.10536 9.29289 7.29289C9.10536 7.48043 9 7.73478 9 8V10H11V8C11 7.73478 10.8946 7.48043 10.7071 7.29289C10.5196 7.10536 10.2652 7 10 7Z"
        fill="#9FA2B4"
      />
    </svg>
  );
};

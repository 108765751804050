import * as React from 'react';
import styled from '@emotion/styled';

import { theme, Theme, TypographyVariant } from './../../theme';
import { CSSProperties } from 'react';
import { device } from '../../utils/devices/devices.utils';

export interface TypographyProps {
  /**
   * How would you like it to render? Please note, that the default is `p` unless stated otherwise.
   * If you need h1, passing only the variant is not enough.
   * @default 'p'
   */
  component?: keyof HTMLElementTagNameMap;
  /**
   * @default body1
   */
  variant?: TypographyVariant;

  /**
   * sets font-weight 600
   * @default false
   */
  bold?: boolean;

  /**
   * sets font-weight 700
   * @default false
   */
  bolder?: boolean;

  /**
   * If true, no margins will be applied
   * @default false
   */
  noMargin?: boolean;

  /**
   * Align text to center
   */
  center?: boolean;

  /**
   * Imagine you'd like to add some opacity to the text.
   * @default undefined
   */
  textOpacity?: number;

  /**
   * In case you would like to override the color of the text.
   * @default main
   */
  color?:
    | keyof Theme['palette']['text']
    | keyof Theme['palette']['main']
    | keyof Theme['palette']['blacks']
    | keyof Theme['palette']['indicators']
    | 'inherit';

  children: React.ReactNode;

  fontWeight?: React.CSSProperties['fontWeight'];

  /**
   * Would you like it to be in uppercase?
   * @default false
   */
  uppercase?: boolean;

  margin?: React.CSSProperties['margin'];
  marginTop?: React.CSSProperties['marginTop'];
  marginBottom?: React.CSSProperties['marginBottom'];
  align?: 'center' | 'left' | 'right';
  style?: CSSProperties;
}

const Typo = styled('p')<
  { theme?: Theme } & Pick<
    TypographyProps,
    | 'center'
    | 'noMargin'
    | 'variant'
    | 'bold'
    | 'bolder'
    | 'textOpacity'
    | 'color'
    | 'fontWeight'
    | 'uppercase'
    | 'marginTop'
    | 'marginBottom'
    | 'margin'
    | 'align'
  >
>(
  ({
    theme,
    variant = 'body1',
    bold = false,
    bolder = false,
    noMargin = false,
    center = false,
    textOpacity,
    color,
    fontWeight,
    uppercase = false,
    marginTop,
    marginBottom,
    margin,
    align,
  }) => {
    return {
      fontFamily: theme.typography.fontFamily,
      ...(theme.typography?.meta[variant] ?? {}),
      margin: noMargin ? 0 : 'initial',
      textAlign: center ? 'center' : align ? align : 'initial',
      color: colorSelect((color || (theme.typography?.meta[variant]?.color as TypographyProps['color'])) ?? 'inherit'),
      ...(textOpacity && { opacity: textOpacity }),
      ...(bold && { fontWeight: 600 }),
      ...(bolder && { fontWeight: 700 }),
      ...(fontWeight && { fontWeight }),
      ...(uppercase && { textTransform: 'uppercase' }),
      ...(margin && { margin }),
      ...(marginBottom && { marginBottom }),
      ...(marginTop && { marginTop }),
    };
  }
);

export const Typography: React.FunctionComponent<TypographyProps> = ({
  component = 'p',
  variant = 'body1',
  bold,
  textOpacity,
  children,
  ...rest
}) => {
  return <Typo {...{ as: component, variant, bold, textOpacity, ...rest }}>{children}</Typo>;
};

export default Typography;

export const colorSelect = (color: TypographyProps['color']): string => {
  if (!color) {
    return theme.palette.text.main;
  }

  if (color === 'inherit') {
    return 'inherit';
  }

  if (color in theme.palette.text) {
    // @ts-ignore
    return theme.palette.text[color];
  } else if (color in theme.palette.main) {
    // @ts-ignore
    return theme.palette.main[color];
  } else if (color in theme.palette.blacks) {
    // @ts-ignore
    return theme.palette.blacks[color];
  } else if (color in theme.palette.indicators) {
    // @ts-ignore
    return theme.palette.indicators[color];
  } else {
    return theme.palette.text.main;
  }
};

import { PartialDeep } from 'type-fest';
import { Typography, typography } from './typography';
import { ColorOverrides } from '../api/api.schema';

type MainPalette =
  | 'primary'
  | 'secondary'
  | 'brand'
  | 'disabled'
  | 'cta'
  | 'border'
  | 'current'
  | 'lightGrey'
  | 'gray'
  | 'purpleGray'
  | 'link'
  | 'water30'
  | 'water40'
  | 'strongGray'
  | 'btnPrimary';
type IndicatorsPalette =
  | 'success'
  | 'warning'
  | 'error'
  | 'errorLight'
  | 'info'
  | 'danger'
  | 'successBold'
  | 'successLight';
type TextColor = 'main' | 'contrast' | 'grey' | 'subtle' | 'link' | 'success';
type BackgroundPalette = 'site' | 'alpha' | 'beta' | 'gamma' | 'delta';
type BlacksPalette = 'alpha' | 'beta' | 'gamma' | 'delta' | 'epsilon' | 'zeta' | 'eta' | 'theta';
type Breakpoints = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

interface Palette {
  main: Record<MainPalette, string>;
  indicators: Record<IndicatorsPalette, string>;
  text: Record<TextColor, string>;
  blacks: Record<BlacksPalette, string>;
  background: Record<BackgroundPalette, string>;
}

type ZIndexKeys = 'appBar' | 'drawer' | 'modal' | 'snackbar' | 'tooltip';

type Radius = 'button' | 'box' | 'card' | 'circle' | 'tooltip' | 'notifiaciton';

type Border = 'checkbox' | 'dashed';

type Opacity = 'hidden' | 'half' | 'visible';

/**
 * Basic theme we will use to build components from.
 */
export interface Theme {
  palette: Palette;
  zIndex: Record<ZIndexKeys, number>;
  gutters: Gutters;
  radius: Record<Radius, number>;
  border: Record<Border, string>;
  opacity: Record<Opacity, number>;
  typography: Typography;
  breakpoints: Record<Breakpoints, number>;
}

interface Gutters {
  base: number;
}

type CreateThemeOptions = { theme?: PartialDeep<Theme>; colorOverrides?: ColorOverrides };

export const DEFAULT_PRIMARY = '#1D1D1D';
export const PRIMARY_BLUE = '#475dfc';

export const createTheme: (createThemeOptions?: CreateThemeOptions) => Theme = (options?: CreateThemeOptions) => {
  const { theme, colorOverrides } = options || {};
  const { primary } = colorOverrides || {};
  return {
    palette: {
      main: {
        primary: primary ?? '#3B6EDF',
        link: primary ?? PRIMARY_BLUE,
        secondary: '#FBFFE6',
        brand: '#DCFF00',
        border: '#ccc',
        cta: 'green',
        disabled: '#333',
        current: 'currentColor',
        strongGray: '#60626E',
        ...theme?.palette?.main,
        lightGrey: '#E3E4ED',
        gray: '#9FA2B4',
        purpleGray: '#f4f4fa',
        water30: 'rgba(96, 98, 110, 1)',
        water40: 'rgb(96,98,110)',
        btnPrimary: '#51B879',
      },
      indicators: {
        successLight: '#f5ffee',
        success: '#D3E8C8',
        successBold: '#B3FAD1',
        warning: '#FAECC7',
        errorLight: '#ffefea',
        error: '#FAC7B7',
        muiError: '#f44336',
        info: '#BED6EE',
        danger: '#DB0C0C',
        ...theme?.palette?.indicators,
      },
      text: {
        main: DEFAULT_PRIMARY,
        contrast: '#fff',
        grey: '#989898',
        subtle: '#EAEBEC',
        link: '#475DFC',
        success: '#47F1D1',
        ...theme?.palette?.text,
      },
      blacks: {
        alpha: DEFAULT_PRIMARY,
        beta: '#242424',
        gamma: '#666666',
        delta: '#3A3A3A',
        epsilon: '#B4B4B4',
        zeta: '#CCCCCC',
        eta: '#E5E5E5',
        theta: '#FFFFFF',
      },
      background: {
        site: '#fff',
        alpha: '#F3F3F3',
        beta: '#EBF2FF',
        gamma: '#666',
        /** Hasn't been defined */
        delta: '#f0f0f0',
        ...theme?.palette?.background,
      },
    },
    zIndex: {
      appBar: 600,
      modal: 700,
      drawer: 800,
      snackbar: 900,
      tooltip: 1000,
      ...theme?.zIndex,
    },
    border: {
      checkbox: '1px solid #ccc',
      dashed: '1px dashed #ccc',
      ...theme?.border,
    },
    opacity: {
      hidden: 0,
      half: 0.5,
      visible: 1,
      ...theme?.opacity,
    },
    gutters: { base: 8, ...theme?.gutters },
    radius: {
      button: 20,
      card: 8,
      circle: 50,
      tooltip: 0,
      notifiaciton: 4,
      box: 6,
      ...theme?.radius,
    },
    typography: { ...typography() },
    breakpoints: { xs: 0, sm: 550, md: 960, lg: 1280, xl: 1920 },
  };
};

export const theme = createTheme();

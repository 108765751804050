import * as React from 'react';
import styled from '@emotion/styled';
import { Theme, theme } from './../../theme';
import { colorSelect } from '../Typography/Typography';

const SVGIconBase = styled('svg')({
  display: 'inline-flex',
});

export interface SVGIconProps {
  width?: number;
  height?: number;
  viewBox?: string;
  color?: keyof Theme['palette']['main'] | keyof Theme['palette']['blacks'] | keyof Theme['palette']['indicators'];
}

export const SVGIcon: React.FunctionComponent<SVGIconProps> = ({
  viewBox,
  height,
  width,
  children,
  color = 'gamma',
  ...rest
}) => {
  return (
    <SVGIconBase
      {...{
        height,
        width,
        fill: colorSelect(color),
        viewBox: viewBox || `0 0 24 24`,
        xmlns: 'http://www.w3.org/2000/svg',
        xmlnsXlink: 'http://www.w3.org/1999/xlink',
        ...rest,
      }}
    >
      {children}
    </SVGIconBase>
  );
};

export default SVGIcon;

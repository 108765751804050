import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/setupContext';
import { Typography } from '../../../ui-lib';
import { flexCenterCenter } from '../../../styles/common';
import success from './../../../images/success.png';
import { Theme } from '../../../theme';

export interface AfterInvoiceCreatedProps {}

export const Container = styled('div')<{ theme: Theme }>(({ theme }) => ({
  marginTop: theme.gutters.base * 5,
  ...flexCenterCenter,
  flexDirection: 'column',
}));

export const TextBlock = styled('div')<{ theme: Theme }>(({ theme }) => ({
  marginTop: theme.gutters.base * 3,
  display: 'grid',
  gap: theme.gutters.base * 2,
  textAlign: 'center',
}));

export const Image = styled('img')({
  display: 'block',
  width: 420,
  objectFit: 'contain',
  height: 240,
});

export const AfterInvoiceCreated: React.FunctionComponent<AfterInvoiceCreatedProps> = ({}) => {
  return (
    <Container>
      <Image {...{ src: success }} />
      <TextBlock>
        <Typography {...{ variant: 'h1Bold', center: true }}>Great!</Typography>
        <Typography {...{ variant: 'h1', center: true }}>Your invoice is on its way</Typography>
      </TextBlock>
    </Container>
  );
};

const Observed = observer(AfterInvoiceCreated);

const WithStoreConnection = () => {
  const { checkout } = useStore();

  const { checkoutStatus } = checkout;

  return <Observed {...{ checkoutStatus }} />;
};

export default observer(WithStoreConnection);

import { FileStatus, QualificationStatus, QualificationStep } from '../../stores/QualificationStore';
import React from 'react';
import {
  Image,
  QualificationBulletWrapper,
  QualificationColumn,
  QualificationHeading,
  QualificationStepBullet,
  QualificationStepContainer,
  QualificationStepContent,
  QualificationStepDescription,
} from './common';
import { PlaidHost } from '../../components/PlaidHost/PlaidHost';
import { observer } from 'mobx-react';
import { css, Global } from '@emotion/core';
import { useStore } from '../../stores/setupContext';
import { LockIcon } from '../../icons/LockIcon/LockIcon';
import { PrivacyIcon } from '../../icons/PrivacyIcon/PrivacyIcon';
import { generateSuccessOnboardingDescription, SuccessPage } from '../../components/SuccessPage/SuccessPage';
import { Typography } from './../../ui-lib';
import styled from '@emotion/styled';
import { Theme } from '../../theme';
import { Route, useRouteMatch } from 'react-router-dom';
import { Divider } from '../../ui-lib/Table/Table';
import { flexCenterCenter } from '../../styles/common';
import { BusinessDetailsForm } from './BusinessDetailsForm';
import { useMobile } from '../../utils/mobile.hook';
import { CompanyName } from '../../components/InfoPage/InfoPage';
import { QualificationButton } from './QualificationButton';
import { device } from '../../utils/devices/devices.utils';
import { BackButtonContainer, HeaderItem } from './QualificationHeader';
import history from '../../routing/history';
import { ArrowBack } from '@material-ui/icons';
import { SmbForm } from './SmbForm';
import { PoweredByBalance } from './PoweredByBalance';
import { OnSuccessCallback } from '../../types/Plaid';

export interface QualificationStepperProps {
  step: QualificationStep;
  status: QualificationStatus;
  vendorName: string;
  siteDomain: string;
}

const plaidIframeStyle = css`
  iframe[id^='plaid-link-iframe'] {
    width: 400px;
    left: calc(50vw - 400px) !important;
  }
`;

export const SkipPlaid = styled('div')<{ theme: Theme }>({
  width: '100%',
  cursor: 'pointer',
});

export const ManualFormContainer = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.gutters.base * 4,
  paddingTop: '3rem',
  [`@media ${device.mobileL}`]: {
    padding: 16,
  },
}));

export const FileStatusChipContainer = styled('div')<{ theme: Theme }>({
  ...flexCenterCenter,
  flexWrap: 'wrap',
  maxWidth: 730,
  gap: 24,
});

export const BackContatiner = styled('div')<{ theme: Theme }>({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export interface UploadingFile {
  name: string;
  status: FileStatus;
  message?: string;
}

export interface LinkYourBankProps {
  title: string;
  onNoBankAccessClicked: Function;
  buyerName: string | undefined;
  setPlaidPressed: any;
  logo: string | undefined;
  mustUsePlaid: boolean;
  isMobile: boolean;
}

const LinkYourBank = ({
  title,
  onNoBankAccessClicked,
  buyerName,
  setPlaidPressed,
  logo,
  mustUsePlaid,
  isMobile,
}: LinkYourBankProps) => {
  const desktopAndSkipPlaid = !isMobile && !mustUsePlaid;
  const stepDescription =
    'We use a secure flow to help you link your bank account. This gives us accurate information we can use to approve you for terms.';
  const smbStepDescription = (
    <>
      <p>Securely link your bank account so that we can learn more about your business.</p>
      <p>By linking your account, you authorize Balance to debit it for any late payments.</p>
    </>
  );
  return (
    <QualificationColumn className={'linkbank'}>
      {isMobile && (
        <BackContatiner>
          {' '}
          <HeaderItem>
            <BackButtonContainer onClick={() => history.goBack()} data-analytics-id="qualification-back">
              <ArrowBack />
            </BackButtonContainer>
          </HeaderItem>
          <CompanyName>{logo ? <Image src={logo} /> : buyerName}</CompanyName>
        </BackContatiner>
      )}

      <QualificationHeading>Link your bank account</QualificationHeading>
      <QualificationStepDescription>{mustUsePlaid ? smbStepDescription : stepDescription}</QualificationStepDescription>
      <QualificationBulletWrapper>
        <LockIcon />
        <QualificationStepBullet>Your personal data is encrypted and protected</QualificationStepBullet>
      </QualificationBulletWrapper>
      <QualificationBulletWrapper>
        <PrivacyIcon />
        <QualificationStepBullet>
          Your credentials stay safe - <br />
          they are never shared with anyone
        </QualificationStepBullet>
      </QualificationBulletWrapper>
      {desktopAndSkipPlaid && (
        <QualificationBulletWrapper>
          <SkipPlaid onClick={() => onNoBankAccessClicked()} data-analytics-id="qualification-noBank">
            <Divider />
            <br />
            <Typography variant="label">
              {title}
              {'  '}
              <Typography variant="linkBlue" style={{ fontSize: 'inherit', display: 'inline' }}>
                <u>Click here</u>
              </Typography>
            </Typography>
          </SkipPlaid>
        </QualificationBulletWrapper>
      )}
      {isMobile && (
        <>
          {' '}
          <QualificationButton onClick={setPlaidPressed} data-analytics-id="qualification-decision-mobile">
            LINK YOUR BANK
          </QualificationButton>
          {!mustUsePlaid && (
            <QualificationButton
              onClick={() => onNoBankAccessClicked()}
              isTransparent={true}
              data-analytics-id="qualification-decision-mobile"
            >
              OR PROVIDE BUSINESS INFO
            </QualificationButton>
          )}
        </>
      )}
    </QualificationColumn>
  );
};

export enum QualificationType {
  INTERNAL = 'internal',
  SMB = 'smb',
}

export const QualificationStepper = observer(({ vendorName, siteDomain }: QualificationStepperProps) => {
  const { qualification } = useStore();
  const isMobile = useMobile();
  const {
    onBankLinkSuccess,
    onBankLinkExit,
    onNoBankAccessClicked,
    uploadQualificationFile,
    deleteQualificationFile,
    qualificationFiles,
    onFileRejected,
    onBusinessDetailsFormSubmitted,
    onSmallBusinessFormSubmitted,
    buyerQualification,
    redirectUrl,
  } = qualification;
  const match = useRouteMatch();
  const [plaidPressed, setPlaidPressed] = React.useState(false);
  const logo = buyerQualification?.merchant.logo;
  const qualificationWithPlaid = buyerQualification?.merchant.qualificationWithPlaid;
  const isSmbFlow = buyerQualification?.qualificationType === QualificationType.SMB;
  const title = qualificationWithPlaid ? "Can't link your bank?" : 'Qualify your business for net terms';
  const subtitle = qualificationWithPlaid
    ? 'No problem, just fill out your information and we’ll take care of the rest'
    : 'Fill out the form to qualify your business for net terms';

  const mobileNonPlaidTitle = 'Provide some information about your business';
  const mobileSubTitle = 'Just fill out your information and we’ll take care of the rest';

  const mobileTitle = `Link your bank account`;

  const setPlaidPressedCallback = () => {
    setPlaidPressed(!plaidPressed);
  };

  return (
    <>
      <Route path={`${match.path}/link-bank`}>
        {plaidPressed ? <PlaidHost mode={'standalone'} onExit={onBankLinkExit} onSuccess={onBankLinkSuccess} /> : ''}
        {isMobile ? (
          <>
            <LinkYourBank
              title={mobileTitle}
              onNoBankAccessClicked={onNoBankAccessClicked}
              buyerName={buyerQualification?.buyerName}
              setPlaidPressed={setPlaidPressedCallback}
              logo={logo}
              mustUsePlaid={isSmbFlow}
              isMobile={isMobile}
            />
            <PoweredByBalance />
          </>
        ) : (
          <QualificationStepContainer>
            <Global styles={plaidIframeStyle} />
            <QualificationColumn>
              <PlaidHost mode={'standalone'} onExit={onBankLinkExit} onSuccess={onBankLinkSuccess} />
            </QualificationColumn>
            <QualificationStepContent>
              {LinkYourBank({
                title,
                onNoBankAccessClicked,
                buyerName: '',
                setPlaidPressed,
                logo,
                mustUsePlaid: isSmbFlow,
                isMobile,
              })}
            </QualificationStepContent>
          </QualificationStepContainer>
        )}
      </Route>
      <Route path={`${match.path}/no-bank`}>
        <QualificationStepContainer>
          <ManualFormContainer>
            {isMobile && (
              <BackContatiner>
                {' '}
                <HeaderItem>
                  <BackButtonContainer onClick={() => history.goBack()} data-analytics-id="qualification-back">
                    <ArrowBack />
                  </BackButtonContainer>
                </HeaderItem>
                <CompanyName>{logo ? <Image src={logo} /> : buyerQualification?.buyerName}</CompanyName>
              </BackContatiner>
            )}

            <Typography variant={!isMobile ? 'h148Bold' : 'h1Bold'}>
              {isMobile ? mobileNonPlaidTitle : title}
            </Typography>
            <Typography variant="body2" center={!isMobile}>
              {isMobile ? mobileSubTitle : subtitle}
            </Typography>
            <BusinessDetailsForm
              onSubmit={(data) => onBusinessDetailsFormSubmitted(data)}
              onFileAdded={uploadQualificationFile}
              onFileRejected={onFileRejected}
              onDeleteFile={deleteQualificationFile}
              qualificationFiles={qualificationFiles}
              buyerAddress={buyerQualification?.billingAddress}
              buyerName={buyerQualification?.buyerName}
            />
            {isMobile && <PoweredByBalance />}
          </ManualFormContainer>
        </QualificationStepContainer>
      </Route>
      <Route path={`${match.path}/retail-buyer`}>
        <SmbForm
          billingAddress={buyerQualification?.billingAddress}
          buyerName={buyerQualification?.buyerName}
          logo={logo}
          onBusinessDetailsFormSubmitted={onSmallBusinessFormSubmitted}
        />
      </Route>
      <Route path={`${match.path}/success`}>
        <SuccessPage
          siteDomain={siteDomain}
          redirectUrl={redirectUrl}
          logoText={buyerQualification?.buyerName}
          logoSrc={logo}
        >
          {generateSuccessOnboardingDescription(vendorName)}
        </SuccessPage>
      </Route>
    </>
  );
});

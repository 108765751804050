import * as React from 'react';
import SVGIcon, { SVGIconProps } from './../../ui-lib/SVGIcon/SVGIcon';
//import styled from '@emotion/styled';

export interface BankIconProps {
  color?: SVGIconProps['color'];
}

export const BankIcon: React.FunctionComponent<BankIconProps> = ({ color }) => {
  return (
    <SVGIcon {...{ width: 18, height: 20, viewBox: '0 0 18 20', color }}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 16.0001H1.5C1.22386 16.0001 1 15.7762 1 15.5001V13.5001C1 13.224 1.22386 13.0001 1.5 13.0001H3V6.0001H1.5C1.22386 6.0001 1 5.77624 1 5.5001V3.6701C1.00202 3.2664 1.24657 2.9035 1.62 2.7501L8.02 0.150098C8.26114 0.0510454 8.51931 8.97089e-05 8.78 9.82842e-05H9.22C9.48741 -0.0025528 9.75264 0.0484529 10 0.150098L16.38 2.7501C16.7534 2.9035 16.998 3.2664 17 3.6701V5.5001C17 5.77624 16.7761 6.0001 16.5 6.0001H15V13.0001H16.5C16.7761 13.0001 17 13.224 17 13.5001V15.5001C17 15.7762 16.7761 16.0001 16.5 16.0001ZM13 6.0001H10V13.0001H13V6.0001ZM5 6.0001H8V13.0001H5V6.0001ZM0.5 18.0001H17.5C17.7761 18.0001 18 18.224 18 18.5001V19.5001C18 19.7762 17.7761 20.0001 17.5 20.0001H0.5C0.223858 20.0001 0 19.7762 0 19.5001V18.5001C0 18.224 0.223858 18.0001 0.5 18.0001Z"
      />
    </SVGIcon>
  );
};

import * as React from 'react';
import styled from '@emotion/styled';
import { Theme } from '../../theme';
import { Dot, Typography, InnerScroll } from '../../ui-lib';
import Option from '../../components/Option/Option';
import Options from '../../components/Options/Options';
import { CreditCardIcon } from '../../icons';
import { CreditCardMeta } from '../../stores/CheckoutStore';
import { flexStartCenter, flexCenterStart } from '../../styles/common';
import { cardIconMapper } from '../../styles/cardIconMapper';
import { CreditCardFormProps } from './CreditCardForm';
import { NotificationProps, WrappedNotification } from '../Notification/Notification';
import { DeleteIcon } from '../../icons';
import { CreditCardManagementProps } from './CreditCardManagement';
import CreditCardSplitForm from '../CreditCardSplitForm/CreditCardSplitForm';
import CreditCardSplitFormNew from '../CreditCardSplitForm/CreditCardSplitForm-new';

export interface CreditCardListProps {
  list: CreditCardMeta[];
  onSelect: (ind: number) => void;
  addNewButton?: React.ReactNode;
  selectedId: string | null;

  onError: CreditCardFormProps['onError'];
  clientSecret: CreditCardFormProps['clientSecret'];
  onSuccess: CreditCardFormProps['onSuccess'];
  setLoading: CreditCardFormProps['setLoading'];
  notification: NotificationProps | null;
  deleteCreditCard: CreditCardManagementProps['deleteCreditCard'];
  isEditMode?: boolean;
  generateClientSecret: () => Promise<void>;
  showCardForm: any;
  setShowCardForm: any;
}

export const Wrap = styled('div')<{ theme: Theme }>(({ theme }) => ({
  padding: `${theme.gutters.base * 2}px`,
  height: '100%',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
}));

export const List = styled(Options)<{ theme: Theme }>(({ theme }) => ({
  minHeight: 0,
}));

export const CardDetailsContainer = styled('div')<{ theme: Theme }>(({ theme }) => ({
  ...flexStartCenter,
}));

export const IconWrap = styled('div')<{ theme: Theme }>(({ theme }) => ({
  marginRight: theme.gutters.base * 2,
}));

export const SmallIconWrap = styled('div')<{ theme: Theme }>(({ theme }) => ({
  marginRight: theme.gutters.base / 2,
}));

export const CardInfoContainer = styled('div')<{ theme: Theme }>(({ theme }) => ({
  ...flexCenterStart,
  flexDirection: 'column',
}));

export const CardNameAndMask = styled('div')<{ theme: Theme }>(({ theme }) => ({
  ...flexStartCenter,
  color: theme.palette.blacks.gamma,
}));

export const CardExpiry = styled(Typography)<{ theme: Theme }>(({ theme }) => ({
  color: theme.palette.blacks.zeta,
  ...theme.typography.meta.link,
}));

export const ListItem = styled(Option)<{ theme: Theme; isSelected: boolean }>(({ theme, isSelected }) => ({}));
export const Icon = styled('img')<{ theme: Theme }>(({ theme }) => ({
  display: 'block',
  width: '100%',
  maxWidth: theme.gutters.base * 5,
  maxHeight: theme.gutters.base * 3,
}));

export const AddCardWrap = styled('div')<{ theme: Theme }>(({ theme }) => ({
  ...flexStartCenter,
}));

export const CreditCardList: React.FunctionComponent<CreditCardListProps> = ({
  selectedId,
  list = [],
  onSelect,
  addNewButton,
  isEditMode,
  notification,
  deleteCreditCard,
  showCardForm,
  setShowCardForm,
}) => {
  const [showStripe, setShowStripe] = React.useState<boolean>(false);

  return (
    <Wrap>
      <WrappedNotification {...{ notification }} />

      <InnerScroll>
        <List
          {...{
            onClick: (e) => {
              if (showStripe) {
                setShowStripe(false);
              }
              const target = e.target as HTMLElement;

              const ind: number = Number(target.dataset['ind'] || target.closest('li')?.dataset['ind']);

              if (typeof ind !== 'number') {
                return;
              }

              onSelect(ind);
            },
          }}
        >
          {list.map(({ name, mask, id, exp }, ind) => {
            return (
              <ListItem
                {...{
                  key: id,
                  'data-ind': ind,
                  onClick: () => {
                    setShowCardForm(false);
                  },
                  isSelected: selectedId === id,
                }}
              >
                <CardDetailsContainer>
                  <IconWrap {...{ style: {} }}>
                    <Icon {...{ src: cardIconMapper[name] ?? 'icon' }} />
                  </IconWrap>
                  <CardInfoContainer>
                    <CardNameAndMask
                      {...{
                        style: {},
                      }}
                    >
                      <Typography {...{ variant: 'link' }}>{name.charAt(0).toUpperCase() + name.slice(1)}</Typography>
                      &nbsp;
                      <Dot />
                      &nbsp;
                      <Typography {...{ variant: 'link' }}>{mask}</Typography>
                    </CardNameAndMask>
                    <CardExpiry>{exp}</CardExpiry>
                  </CardInfoContainer>
                </CardDetailsContainer>
                {isEditMode && (
                  <div
                    {...{
                      onClick: () => {
                        deleteCreditCard?.(id);
                      },
                    }}
                  >
                    <DeleteIcon />
                  </div>
                )}
              </ListItem>
            );
          })}
          <Option
            {...{
              isColumn: showCardForm,
              isSelected: showCardForm,
              onClick: () => !showCardForm && setShowCardForm(true),
            }}
          >
            <AddCardWrap {...{ style: {} }}>
              <CreditCardIcon />
              <Typography {...{ style: { marginLeft: 16 } }}>Add card</Typography>
            </AddCardWrap>
            <CreditCardSplitForm {...{ mode: 'checkout' }} />
            <CreditCardSplitFormNew {...{ mode: 'checkout' }} />
          </Option>
        </List>
      </InnerScroll>
      {addNewButton}
    </Wrap>
  );
};

export default CreditCardList;

import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import CheckoutStore from './../../../stores/CheckoutStore';
import { useStore } from './../../../stores/setupContext';
import { Typography } from '../../../ui-lib';
import { flexCenterCenter } from './../../../styles/common';
import success from './../../../images/success.png';
import { Theme } from '../../../theme';
export interface AfterPayProps {
  checkoutStatus: CheckoutStore['checkoutStatus'];
}
export const Container = styled('div')<{ theme: Theme }>(({ theme }) => ({
  marginTop: theme.gutters.base * 5,
  ...flexCenterCenter,
  flexDirection: 'column',
}));

export const TextBlock = styled('div')<{ theme: Theme }>(({ theme }) => ({
  marginTop: theme.gutters.base * 3,
  display: 'grid',
  gap: theme.gutters.base * 2,
  textAlign: 'center',
}));

export const Image = styled('img')<{ theme: Theme }>(({ theme }) => ({
  display: 'block',
  width: 420,
  objectFit: 'contain',
  height: 240,
}));

export const AfterPay: React.FunctionComponent<AfterPayProps> = ({ checkoutStatus }) => {
  return (
    <Container>
      {checkoutStatus === 'closed' && <Image {...{ src: success }} />}
      {checkoutStatus === 'auth' && <Image {...{ src: success }} />}
      <TextBlock>
        <Typography {...{ variant: 'h1Bold', center: true }}>Great!</Typography>
        <Typography {...{ variant: 'h1', center: true }}>Your payment went through</Typography>
      </TextBlock>
    </Container>
  );
};

const Observed = observer(AfterPay);

const WithStoreConnection = () => {
  const { checkout } = useStore();

  const { checkoutStatus } = checkout;

  return <Observed {...{ checkoutStatus }} />;
};

export default observer(WithStoreConnection);

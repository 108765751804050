import * as React from 'react';
import SVGIcon from './../../ui-lib/SVGIcon/SVGIcon';
//import styled from '@emotion/styled';

export interface CoogProps {}

export const CogIcon: React.FunctionComponent<CoogProps> = ({}) => {
  return (
    <SVGIcon {...{ width: 16, height: 17, viewBox: '0 0 16 17' }}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8341 9.75077L14.4033 10.1971C14.6733 10.4053 14.7455 10.7685 14.574 11.056L13.7487 12.4086C13.5781 12.672 13.2411 12.7918 12.9305 12.6994L12.219 12.456C11.7992 12.3068 11.3299 12.3544 10.9526 12.5845C10.7854 12.6943 10.6093 12.7915 10.4261 12.8753C10.0258 13.0704 9.74612 13.4341 9.67196 13.8559L9.55101 14.5322C9.49464 14.863 9.192 15.1046 8.83954 15.1003H7.20314C6.85068 15.1046 6.54804 14.863 6.49167 14.5322L6.37072 13.8559C6.29656 13.4341 6.0169 13.0704 5.61655 12.8753C5.43335 12.7915 5.25731 12.6943 5.09006 12.5845C4.71277 12.3544 4.2435 12.3068 3.82363 12.456L3.11216 12.6994C2.78897 12.8136 2.4262 12.691 2.25127 12.4086L1.42596 11.056C1.25444 10.7685 1.32665 10.4053 1.59671 10.1971L2.16589 9.75077C2.511 9.47846 2.70083 9.06743 2.67816 8.64164V8.35083V8.06002C2.70083 7.63423 2.511 7.22319 2.16589 6.95088L1.59671 6.50453C1.32665 6.29635 1.25444 5.93312 1.42596 5.64562L2.25127 4.29302C2.4249 4.01711 2.77887 3.89553 3.09793 4.00221L3.8094 4.24568C4.22927 4.39484 4.69854 4.34723 5.07583 4.11718C5.24308 4.00735 5.41912 3.91012 5.60232 3.82637C5.99175 3.62483 6.26007 3.26224 6.32803 2.84574L6.44898 2.16944C6.50535 1.83869 6.80799 1.59703 7.16045 1.60134H8.79685C9.14931 1.59703 9.45195 1.83869 9.50832 2.16944L9.62927 2.84574C9.70343 3.26757 9.98309 3.63121 10.3834 3.82637C10.5666 3.91012 10.7427 4.00735 10.9099 4.11718C11.2872 4.34723 11.7565 4.39484 12.1764 4.24568L12.8878 4.00221C13.211 3.88807 13.5738 4.01062 13.7487 4.29302L14.574 5.64562C14.7455 5.93312 14.6733 6.29635 14.4033 6.50453L13.8341 6.95088C13.489 7.22319 13.2992 7.63423 13.3218 8.06002V8.35083V8.64164C13.2992 9.06743 13.489 9.47846 13.8341 9.75077ZM5.86558 8.35075C5.86558 9.47128 6.8212 10.3797 8.00001 10.3797C8.56609 10.3797 9.10899 10.1659 9.50928 9.7854C9.90956 9.40491 10.1344 8.88885 10.1344 8.35075C10.1344 7.23022 9.17882 6.32185 8.00001 6.32185C6.8212 6.32185 5.86558 7.23022 5.86558 8.35075Z"
        fill="#666666"
      />
    </SVGIcon>
  );
};

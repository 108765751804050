import * as React from 'react';
import styled from '@emotion/styled';
import { Theme } from '../../../theme';
import { Typography } from '../../../ui-lib';
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/setupContext';
import CheckoutStore from '../../../stores/CheckoutStore';
import { ArrowIcon } from '../../../icons';
import { flexCenterCenter } from '../../../styles/common';
import Stepper, { StepperProps } from '../../../components/Stepper/Stepper';

export interface HeaderProps {
  hostIcon?: string;
  hostName?: string;
  hostLogo?: string;
  amountToCharge: string;
  previousButton: CheckoutStore['previousButton'];
  isShowBackButton: CheckoutStore['isShowBackButton'];
  steps: StepperProps['steps'];
  currentStep: StepperProps['currentStep'];
}

export const HeaderContainer = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: 'auto auto',
  gap: theme.gutters.base * 2,
  marginBottom: theme.gutters.base * 4,
}));

export const CheckoutMeta = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const AmountToCharge = styled(Typography)<{ theme: Theme }>(({ theme }) => ({
  ...theme.typography.meta.body1Bold,
}));

export const BackButton = styled('div')<{ theme: Theme }>(({ theme }) => ({
  cursor: 'pointer',
  ...flexCenterCenter,
}));

export const CloseCheckout = styled(Typography)<{ theme: Theme }>(({ theme }) => ({
  ...theme.typography.meta.caption,
  color: theme.palette.main.primary,

  cursor: 'pointer',
}));

export const Logo = styled('img')<{ theme: Theme }>(({ theme }) => ({
  display: 'block',
  maxWidth: 120,
  maxHeight: 25,
}));

export const Header: React.FunctionComponent<HeaderProps> = ({
  hostName = '',
  hostLogo,
  previousButton,
  isShowBackButton,
  hostIcon,
  amountToCharge,
  currentStep,
  steps,
}) => {
  const handleClose = () => {
    window.xprops?.onClose?.();
  };

  return (
    <HeaderContainer>
      <CheckoutMeta>
        <BackButton
          {...{
            onClick: previousButton.callback ? previousButton.callback : () => {},
            style: { visibility: isShowBackButton && previousButton.callback ? 'visible' : 'hidden' },
          }}
        >
          <ArrowIcon {...{ color: 'alpha' }} />
          <Typography {...{ variant: 'labelBold' }}>Back</Typography>
        </BackButton>

        <Logo {...{ src: hostLogo, alt: hostName }} />
        <AmountToCharge>{amountToCharge}</AmountToCharge>
      </CheckoutMeta>
    </HeaderContainer>
  );
};

const Observed = observer(Header);

const WithStoreConnection = () => {
  const { checkout } = useStore();

  const { amountToCharge, merchantLogo, previousButton, isShowBackButton, currentPhase } = checkout;

  return (
    <Observed
      {...{
        amountToCharge,
        hostLogo: merchantLogo,
        previousButton,
        isShowBackButton,
        currentStep: currentPhase === 'summary' || currentPhase === 'afterPay' ? 'review' : 'payment',
        steps: [
          { key: 'payment', label: 'Payment' },
          { key: 'review', label: 'Review' },
        ],
      }}
    />
  );
};

export default observer(WithStoreConnection);

import React from 'react';

import { WithTheme } from './WithTheme';
import { WithMobx } from './WithMobx';
import { WithGlobal } from './WithGlobal';

const WithAllAppWrappers: React.FunctionComponent = ({ children }) => {
  return (
    <WithMobx>
      <>
        <WithGlobal />
        {children}
      </>
    </WithMobx>
  );
};

const WithAllStorybookWrappers: React.FunctionComponent = ({ children }) => {
  return (
    <>
      <WithTheme>
        <>
          <WithGlobal />
          {children}
        </>
      </WithTheme>
    </>
  );
};

export default WithAllAppWrappers;
export { WithTheme, WithMobx, WithAllStorybookWrappers };

import React, { useCallback } from 'react';
import { flexCenterCenter, flexCenterStretch } from '../../styles/common';
import { Typography } from '../../ui-lib';
import styled from '@emotion/styled';
import plusIcon from '../../icons/plusIcon.svg';
import { DropzoneOptions, FileRejection, useDropzone } from 'react-dropzone';
import { theme, Theme } from '../../theme';

export const FileUploadContainer = styled('div')<{ theme: Theme }>(({ theme }) => ({
  ...flexCenterStretch,
  flexDirection: 'column',
  gap: theme.gutters.base * 2,
}));

export const FileUploadDropZoneContainer = styled('div')<{ theme: Theme; isActive: boolean }>(
  ({ theme, isActive }) => ({
    ...flexCenterCenter,
    cursor: 'pointer',
    width: 550,
    height: 130,
    outline: 'none',
    border: isActive ? `2px dashed ${theme.palette.main.link}` : `2px dashed ${theme.palette.main.gray}`,
    '&:active, &:hover': {
      border: `2px dashed ${theme.palette.main.link}`,
    },
    padding: '2rem',
  })
);

export const FileUploadDropZoneCTA = styled('div')<{ theme: Theme }>(({ theme }) => ({
  ...flexCenterStretch,
  gap: theme.gutters.base * 3,
}));

export const AddFilesFab = styled('div')<{ theme: Theme }>(({ theme }) => ({
  ...flexCenterCenter,
  width: 32,
  height: 32,
  minWidth: 32,
  borderRadius: '50%',
  background: theme.palette.main.link,
}));

export const PlusIcon = styled('img')<{ theme: Theme }>(({ theme }) => ({
  height: 9,
  width: 9,
}));

export const CTATextContainer = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.gutters.base,
}));

export interface FileUploadDropzoneProps {
  onFileAdded: (file: File) => any;
  onFileRejected: (file: FileRejection) => any;
}

export const QUALIFICATION_UPLOAD_FILE_SIZE_LIMIT_IN_BYTES = 10 * 1024 * 1024; // 10MB

export const FileUploadDropzone = ({ onFileAdded, onFileRejected }: FileUploadDropzoneProps) => {
  const onDrop = useCallback((acceptedFiles: File[], fileRejections: FileRejection[]) => {
    acceptedFiles.forEach((file) => {
      onFileAdded(file);
      amplitude.getInstance().logEvent('qualification-uploadFile-success');
    });
    fileRejections.forEach((file) => {
      onFileRejected(file);
      amplitude.getInstance().logEvent('qualification-uploadFile-fail', file.errors);
    });
  }, []);
  const dropZoneOptions = { onDrop, maxFiles: 10, maxSize: QUALIFICATION_UPLOAD_FILE_SIZE_LIMIT_IN_BYTES } as
    | DropzoneOptions
    | undefined;
  const { getRootProps, getInputProps, isDragActive } = useDropzone(dropZoneOptions);

  return (
    <FileUploadContainer>
      <Typography style={{ color: '#60626E', fontSize: 16 }}>
        <b>Optional:</b>
      </Typography>
      <FileUploadDropZoneContainer
        {...getRootProps()}
        isActive={isDragActive}
        data-analytics-id="qualification-uploadFile-clicked"
      >
        <input {...getInputProps()} />
        <FileUploadDropZoneCTA>
          {!isDragActive && (
            <AddFilesFab>
              <PlusIcon src={plusIcon} />
            </AddFilesFab>
          )}
          <CTATextContainer>
            {isDragActive ? (
              <>
                <Typography variant="h1Bold" style={{ color: theme.palette.main.water40 }}>
                  Drop files here
                </Typography>
                <Typography variant="caption" style={{ color: theme.palette.main.water30 }}>
                  And they will be uploaded
                </Typography>
              </>
            ) : (
              <>
                <Typography style={{ color: theme.palette.main.water30, fontSize: 16 }}>
                  <b>Add supporting documents such as financial statements or trade references</b>
                </Typography>
                <Typography variant="caption" style={{ color: theme.palette.main.water30 }}>
                  Click or drop files here
                </Typography>
              </>
            )}
          </CTATextContainer>
        </FileUploadDropZoneCTA>
      </FileUploadDropZoneContainer>
    </FileUploadContainer>
  );
};

import { Typography } from '../../ui-lib';
import { QualificationButton } from './QualificationButton';
import React from 'react';
import styled from '@emotion/styled';
import { DEFAULT_PRIMARY, Theme } from '../../theme';
import { device } from '../../utils/devices/devices.utils';

const FooterLink = styled('a')({
  textDecoration: 'underline',
  color: DEFAULT_PRIMARY,
});

export const FormFooterContainer = styled('div')<{ theme: Theme }>(({ theme }) => ({
  paddingTop: 50,
  marginBottom: '50px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  height: 100,
  width: '100%',
  [`@media ${device.mobileL}`]: {
    flexDirection: 'column',
  },
}));

const SubmitButtonContainer = styled('div')({
  [`@media ${device.mobileL}`]: {
    marginRight: '16px',
    marginLeft: '16px',
  },
});

const LegalContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  [`@media ${device.mobileL}`]: {
    marginBottom: '32px',
  },
});

export const SmbFooter = () => {
  const termsAndConditionsUrl = 'https://www.getbalance.com/legal/end-user-terms';
  const privacyPolicyUrl = 'https://www.getbalance.com/legal/privacy-policy';

  return (
    <FormFooterContainer>
      <LegalContainer>
        <Typography center variant={'body1'}>
          By clicking Submit, you agree to our&nbsp;
          <FooterLink
            target={'_blank'}
            rel={'noopener noreferrer'}
            {...{
              href: termsAndConditionsUrl,
            }}
          >
            Terms of Use
          </FooterLink>
          &nbsp;and&nbsp;
          <FooterLink
            target={'_blank'}
            rel={'noopener noreferrer'}
            {...{
              href: privacyPolicyUrl,
            }}
          >
            Privacy Policy
          </FooterLink>
        </Typography>
      </LegalContainer>
      <SubmitButtonContainer>
        <QualificationButton type="submit" data-analytics-id="qualification-finish">
          Submit
        </QualificationButton>
      </SubmitButtonContainer>
    </FormFooterContainer>
  );
};

export default SmbFooter;

<svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg"></svg>;

import * as React from 'react';
import SVGIcon, { SVGIconProps } from '../../ui-lib/SVGIcon/SVGIcon';
//import styled from '@emotion/styled';

export interface DeleteIconProps {
  color?: SVGIconProps['color'];
}

export const DeleteIcon: React.FunctionComponent<DeleteIconProps> = ({ color = 'danger' }) => {
  return (
    <SVGIcon {...{ width: 20, height: 20, viewBox: '0 0 20 20', color }}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 10C0 4.47715 4.47715 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34784 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10ZM6 10.5C6 10.7761 6.22386 11 6.5 11H13.5C13.7761 11 14 10.7761 14 10.5V9.5C14 9.22386 13.7761 9 13.5 9H6.5C6.22386 9 6 9.22386 6 9.5V10.5Z"
      />
    </SVGIcon>
  );
};

import * as React from 'react';
import styled from '@emotion/styled';
import { Theme } from '../../theme';

export interface OptionsProps {}

export const Options = styled('ul')<{ theme: Theme }>(({ theme }) => ({
  display: 'grid',
  gap: theme.gutters.base * 2,
}));

export default Options;

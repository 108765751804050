import { PoweredBy } from '../common/PoweredBy';
import React from 'react';
import styled from '@emotion/styled';
import { flexStartCenter } from '../../styles/common';
import { device } from '../../utils/devices/devices.utils';

const PoweredByItem = styled('div')({
  ...flexStartCenter,
  zIndex: 1000,
  height: 80,
  [`@media ${device.mobileL}`]: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const LINK_PARAMS = '?utm_source=Product&utm_medium=Buyer%20Qualifcation&utm_campaign=referral';

export const PoweredByBalance = () => {
  return (
    <PoweredByItem>
      <PoweredBy linkParams={LINK_PARAMS} />
    </PoweredByItem>
  );
};

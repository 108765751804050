import React from 'react';
import { Global } from '@emotion/core';

export const WithGlobal: React.FunctionComponent = () => {
  return (
    <Global
      {...{
        styles: {
          '*': {
            boxSizing: 'border-box',
          },
          'html, body': {
            height: '100%',
            minHeight: '100vh',
          },
          ul: {
            margin: 0,
            padding: 0,
            listStyle: 'none',
          },
          input: {
            margin: 0,
            padding: 0,
          },
        },
      }}
    />
  );
};

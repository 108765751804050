import * as React from 'react';
import SVGIcon, { SVGIconProps } from './../../ui-lib/SVGIcon/SVGIcon';

export const SuccessIcon: React.FunctionComponent<SVGIconProps> = ({ color, ...rest }) => (
  <SVGIcon {...{ width: 20, height: 20, viewBox: '0 0 20 20', color, ...rest }}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0ZM10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18ZM13.12 6.49C13.3144 6.29943 13.6256 6.29943 13.82 6.49L14.32 7.02C14.4147 7.11388 14.4679 7.24168 14.4679 7.375C14.4679 7.50832 14.4147 7.63612 14.32 7.73L8.7 13.35C8.60927 13.4467 8.48259 13.5015 8.35 13.5015C8.21741 13.5015 8.09073 13.4467 8 13.35L5.65 10.98C5.55534 10.8861 5.5021 10.7583 5.5021 10.625C5.5021 10.4917 5.55534 10.3639 5.65 10.27L6.18 9.74C6.27073 9.64332 6.39741 9.58848 6.53 9.58848C6.66259 9.58848 6.78927 9.64332 6.88 9.74L8.38 11.23L13.12 6.49Z"
    />
  </SVGIcon>
);

export const SeccessGreenIcon: React.FunctionComponent<SVGIconProps> = ({ color, ...rest }) => (
  <SVGIcon {...{ width: 48, height: 48, viewBox: '0 0 48 48', color, ...rest }}>
    <circle cx="24" cy="24" r="24" fill="#6FCF97" />
    <path
      d="M31.85 19.25L21.25 29.85C21.1561 29.9447 21.0283 29.9979 20.895 29.9979C20.7617 29.9979 20.6339 29.9447 20.54 29.85L15.15 24.46C15.0553 24.3661 15.0021 24.2383 15.0021 24.105C15.0021 23.9717 15.0553 23.8439 15.15 23.75L15.85 23.05C15.9439 22.9553 16.0717 22.9021 16.205 22.9021C16.3383 22.9021 16.4661 22.9553 16.56 23.05L20.89 27.38L30.44 17.83C30.6378 17.6382 30.9522 17.6382 31.15 17.83L31.85 18.54C31.9446 18.6339 31.9979 18.7617 31.9979 18.895C31.9979 19.0283 31.9446 19.1561 31.85 19.25Z"
      fill="white"
    />
  </SVGIcon>
);

import visa from './../icons/cards/visa.png';
import mastercard from './../icons/cards/mastercard.png';
import amex from './../icons/cards/amex.png';
import jcb from './../icons/cards/jcb.png';
import diners from './../icons/cards/diners.png';
import discover from './../icons/cards/discover.png';
import elo from './../icons/cards/elo.png';

export const visaIcon = visa;
export const mastercardIcon = mastercard;
export const amexIcon = amex;
export const eloIcon = elo;

export const cardIconMapper: Record<string, string> = {
  visa,
  mastercard,
  amex,
  jcb,
  diners,
  discover,
  elo,
  unionpay: '',
};

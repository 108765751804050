import * as React from 'react';
import styled from '@emotion/styled';
import { Theme } from './../../theme';

type Variant = 'small' | 'big' | 'xl';

export interface LabelProps {
  label: string;
  /**
   * In case other flavors are needed.
   * @default small
   */
  variant?: Variant;
}

const extraStyles = {
  small: {},
  big: {
    fontWeight: 18,
  },
  xl: {
    'font-size': 16,
    fontFamily: 'Aeonik-bold',
    color: '#60626E',
  },
};

const StyledLabel = styled('label')<{ theme?: Theme; variant: Variant }>(({ theme, variant }) => ({
  ...theme.typography.meta.label,
  fontFamily: theme.typography.fontFamily,
  fontWeight: 600,
  ...extraStyles[variant],
  cursor: 'inherit',
}));

export const Label: React.FunctionComponent<LabelProps> = ({ label, variant = 'small', ...rest }) => {
  return <StyledLabel {...{ ...rest, variant }}>{label}</StyledLabel>;
};

export default Label;

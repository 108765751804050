import React, { useMemo, VFC } from 'react';
import { HeaderContainer, HeaderItem } from '../../../../../sections/qualification/QualificationHeader';
import { PoweredBy } from '../../../../../sections/common/PoweredBy';
import { useVendorOnboarding } from '../../hooks/useVendorOnboarding';
import { Logo } from './Wrappers';
import { StickyAppBarAlert } from '../../../../../ui-lib/Alert/StickyAlert';
import { useStore } from '../../../../../stores/setupContext';
import { observer } from 'mobx-react';
import { ErrorPage } from '../../../../../components/ErrorPage/ErrorPage';
import { generateSuccessOnboardingDescription, SuccessPage } from '../../../../../components/SuccessPage/SuccessPage';
import { InfoPage } from '../../../../../components/InfoPage/InfoPage';
import { Redirect, Route, Switch } from 'react-router-dom';
import history from '../../../../../routing/history';
import {
  BackgroundCircleContainer,
  CenteredContainer,
  LayoutContainer,
} from '../../../../../sections/qualification/BasicQualificationFlow';
import { LoadingOverlay } from '../../../../../ui-lib/Loader/LoadingOverlay';
import { UpdateVendorForm } from './UpdateVendorForm';
import { CreateVendorForm } from './CreateVendorForm';

const VENDORS = '/vendors';
const FORM_PAGE = `${VENDORS}/pending`;
export const HOME_PAGE = `${VENDORS}/started`;
export const SUCCESS_PAGE = `${VENDORS}/success`;
export const ERROR_PAGE = `${VENDORS}/error`;
export const UNAUTHORIZED_ERROR_PAGE = `${ERROR_PAGE}/unauthorized`;

const Header: VFC<{ logo?: string }> = ({ logo }) => (
  <HeaderContainer>
    {logo && (
      <HeaderItem>
        <Logo src={logo} />
      </HeaderItem>
    )}
    <HeaderItem>
      <PoweredBy linkParams="?utm_source=Product&utm_medium=Vendor%20Onboarding&utm_campaign=referral" />
    </HeaderItem>
  </HeaderContainer>
);

export const VendorOnboardingLayout: VFC = observer(() => {
  const { vendorOnboarding } = useStore();
  const { createVendor, updateVendor, isLoading: isSubmitLoading, error } = useVendorOnboarding();
  const {
    merchant,
    isLoading: isAppLoading,
    redirectUrl,
    vendorDetails,
    isVendorUpdate,
    paymentData,
  } = vendorOnboarding;
  const { settings, coverPhoto = '', name = '', logo = '' } = merchant || {};

  const onStarted = () => {
    history.push({ pathname: FORM_PAGE, search: location.search });
  };

  const vendorName = merchant?.name ?? 'store';
  const successPageDescription = isVendorUpdate
    ? 'Thanks, your details have been updated'
    : generateSuccessOnboardingDescription(vendorName);

  const homePageDescription = useMemo(() => {
    let text = `${name} partners with Balance for fast, secure payouts`;
    if (isVendorUpdate) {
      return `${text}. Fill out a few details to get set up.`;
    }
    return `${text}. Fill out a few details so you can start getting paid.`;
  }, [name, isVendorUpdate]);

  return (
    <LayoutContainer>
      {!isAppLoading && (
        <Route key={'pending-background'} path={HOME_PAGE}>
          <BackgroundCircleContainer>&nbsp;</BackgroundCircleContainer>
        </Route>
      )}
      {error && <StickyAppBarAlert message={error} variant={'error'} />}
      <Header logo={logo} />
      <Route key={'unauthorized-error'} path={UNAUTHORIZED_ERROR_PAGE}>
        <ErrorPage
          tryAgain={onStarted}
          message={`Please contact ${vendorName} support for help`}
          title={'Your link has expired'}
        />
      </Route>
      <Route key={'general-error'} path={ERROR_PAGE} exact>
        <ErrorPage tryAgain={onStarted} />
      </Route>
      {isAppLoading ? (
        <CenteredContainer>
          <LoadingOverlay />
        </CenteredContainer>
      ) : (
        <Switch>
          <Route key="started" path={HOME_PAGE}>
            <InfoPage
              coverPhotoUrl={coverPhoto}
              title="Set up your payouts"
              description={homePageDescription}
              siteDomain={settings?.businessDomain}
              onStarted={onStarted}
            />
          </Route>
          <Route key="pending" path={FORM_PAGE}>
            {isVendorUpdate && vendorDetails ? (
              <UpdateVendorForm
                onSubmitDetails={updateVendor}
                isSubmitLoading={isSubmitLoading}
                vendorInfo={vendorDetails}
                bankAccount={paymentData?.banks?.[0]}
              />
            ) : (
              <CreateVendorForm onSubmitDetails={createVendor} isSubmitLoading={isSubmitLoading} />
            )}
          </Route>
          <Route key="success" path={SUCCESS_PAGE}>
            <SuccessPage logoSrc={merchant?.logo} siteDomain={settings?.businessDomain ?? ''} redirectUrl={redirectUrl}>
              {successPageDescription}
            </SuccessPage>
          </Route>
          <Redirect from="/" to={ERROR_PAGE} />
        </Switch>
      )}
    </LayoutContainer>
  );
});

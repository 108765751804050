import * as React from 'react';
import { Typography } from './../../ui-lib';
import styled from '@emotion/styled';
import { Theme } from '../../theme';
import { InfoIcon, BlockIcon, SuccessIcon } from '../../icons';

export interface NotificationProps {
  type: 'success' | 'warning' | 'error' | 'info';
  /**
   * What text would you like to show?
   * If you need something more complex, check `component` out
   */
  text?: string;
  /**
   * In case you'd like to add something that's more complex than a string.
   */
  component?: React.ReactNode;
}

export const Container = styled('div')<{ theme: Theme; type: NotificationProps['type'] }>(({ theme, type }) => ({
  borderRadius: theme.radius.notifiaciton,
  padding: theme.gutters.base * 2,
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: 'min-content 1fr',
  gap: theme.gutters.base * 2,
  backgroundColor: theme.palette.indicators[type],
}));

export const NotificationWrap = styled('div')<{ theme: Theme }>(({ theme }) => ({
  marginBottom: theme.gutters.base * 2,
}));

export const Notification: React.FunctionComponent<NotificationProps> = ({ type, text, component }) => {
  if (!text && !component) {
    console.error('no content to show');
    return null;
  }
  return (
    <Container {...{ type }}>
      {type === 'error' && <BlockIcon {...{ color: 'alpha' }} />}
      {type === 'success' && <SuccessIcon {...{ color: 'alpha' }} />}
      {type === 'warning' && <InfoIcon {...{ color: 'alpha' }} />}
      {type === 'info' && <InfoIcon {...{ color: 'alpha' }} />}
      {text && <Typography>{text}</Typography>}
      {component}
    </Container>
  );
};

export const WrappedNotification: React.FunctionComponent<{ notification: NotificationProps | null }> = ({
  notification,
}) => {
  if (!notification) {
    return null;
  }
  return (
    <NotificationWrap>
      <Notification {...notification} />
    </NotificationWrap>
  );
};

export default Notification;

import * as React from 'react';
import styled from '@emotion/styled';
import { Theme } from '../../../theme';
import { Dialog, Typography } from '../../../ui-lib';
import { useStore } from '../../../stores/setupContext';
import { observer } from 'mobx-react';
import Summary from './Summary';
import PaymentMethod from './PaymentMethod';
import { WrappedNotification } from '../../../components/Notification/Notification';
import CheckoutStore from './../../../stores/CheckoutStore';
import { Remarkable } from 'remarkable';
var md = new Remarkable();

export interface ReviewProps {
  shipping?: CheckoutStore['shippingDetails'];
  paymentDetails: {
    subtotal: string;
    shipping: string;
    estimatedTax: string;
    total: string;
    discount: string;
  };

  note?: string;
  paymentMethodSummary: PaymentMethodSummary;
  notification: CheckoutStore['notification'];
  legal?: string;
}

export interface PaymentMethodSummary {
  box1: { label: string; text: string };
  box2?: { label: string; text: string };
}

export const StyledBox = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  flex: 1,
  borderRadius: theme.radius.box,
  '& div:not(:last-of-type)': {
    marginBottom: theme.gutters.base * 2,
  },
}));

export const ShippingStyledBox = styled(StyledBox)<{ theme: Theme }>(({ theme }) => ({
  justifyContent: 'start',
  '& p:not(:last-of-type)': {
    marginBottom: theme.gutters.base * 2,
  },
}));

export const SectionContainer = styled('div')<{ theme: Theme }>(({ theme }) => ({
  border: '1px solid #e5e5e5',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: theme.gutters.base * 2,
}));

export const SectionTitle: React.FunctionComponent = ({ children }) => {
  return <Typography {...{ uppercase: true, color: 'main', style: { marginBottom: 16 } }}>{children}</Typography>;
};

export const Section: React.FunctionComponent<{ title: string }> = ({ title, children }) => {
  return (
    <SectionContainer>
      <SectionTitle>{title}</SectionTitle>
      <>{children}</>
    </SectionContainer>
  );
};

export const StyledLine = styled('div')<{ theme: Theme; withLine?: boolean }>(({ theme, withLine }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const HR = styled('div')<{ theme: Theme }>(({ theme }) => ({
  backgroundColor: theme.palette.blacks.eta,
  width: '100%',
  height: 1,
}));

export const Line: React.FunctionComponent<{ name: string; value: string; withLine?: boolean }> = ({
  name,
  value,
  withLine,
}) => {
  return (
    <>
      <StyledLine {...{ withLine }}>
        <Typography {...{ variant: 'body1', color: 'grey' }}>{name}</Typography>
        <Typography {...{ variant: 'body1' }}>{value}</Typography>
      </StyledLine>
      {withLine && <HR />}
    </>
  );
};

export const LegalText = styled('div')<{ theme: Theme }>(({ theme }) => ({
  ...theme.typography.meta.caption,
}));

export const ShippingAndSummary = styled('div')<{ theme: Theme; numOfColumns: number }>(({ theme, numOfColumns }) => ({
  display: 'grid',
  gridTemplateColumns: `repeat(${numOfColumns}, 1fr)`,
  gap: 16,
  alignItems: 'stretch',
  marginBottom: 32,
}));

export const Review: React.FunctionComponent<ReviewProps> = ({
  shipping,
  paymentDetails,
  paymentMethodSummary,
  note,
  notification,
  legal,
}) => {
  const [isLegalModalOpen, setLegalModalOpen] = React.useState<boolean>(false);
  const hasName = shipping?.firstName || shipping?.lastName;
  const hasStreetAddress1 = shipping?.addressLine1;
  const hasStreetAddress2 = shipping?.addressLine2;
  const hasCityZipCodeState = shipping?.city || shipping?.zipCode || shipping?.state;

  return (
    <div {...{ style: { marginBottom: 32 } }}>
      <WrappedNotification {...{ notification }} />
      <ShippingAndSummary {...{ numOfColumns: shipping ? 2 : 1 }}>
        {shipping && (
          <Section {...{ title: 'Shipping address' }}>
            <ShippingStyledBox>
              {hasName && (
                <Typography {...{ variant: 'body1' }}>
                  {shipping.firstName} {shipping.lastName}
                </Typography>
              )}
              {hasStreetAddress1 && <Typography {...{ variant: 'body1' }}>{shipping.addressLine1}</Typography>}
              {hasStreetAddress2 && <Typography {...{ variant: 'body1' }}>{shipping.addressLine2}</Typography>}
              {hasCityZipCodeState && (
                <Typography {...{ variant: 'body1' }}>
                  {shipping.city ? `${shipping.city}` : null}
                  {shipping.state ? (shipping.city ? `, ${shipping.state}` : `${shipping.state}`) : null}
                  {shipping.zipCode ? ` ${shipping.zipCode}` : null}
                </Typography>
              )}
            </ShippingStyledBox>
          </Section>
        )}
        <Summary {...{ paymentDetails, withLine: !shipping }} />
      </ShippingAndSummary>
      <PaymentMethod {...{ note, paymentMethodSummary }} />
      {isLegalModalOpen && (
        <Dialog
          {...{
            title: 'Terms and Contidions',
            content: <LegalText {...{ dangerouslySetInnerHTML: { __html: md.render(legal!) } }}></LegalText>,
            confirmationButton: {
              text: 'I Accept',
              action: () => setLegalModalOpen(false),
            },
            cancelButton: {
              text: 'Cancel',
              action: () => setLegalModalOpen(false),
            },
          }}
        />
      )}
      {legal && (
        <Typography {...{ variant: 'link', marginTop: 32 }}>
          I confirm that I have read and agree to the{' '}
          <Typography
            {...{
              variant: 'linkBlue',
              component: 'span',
              style: { cursor: 'pointer' },
              onClick: () => setLegalModalOpen(true),
            }}
          >
            terms and conditions
          </Typography>
        </Typography>
      )}
    </div>
  );
};

const Observed = observer(Review);

const WithStoreConnection = () => {
  const { checkout } = useStore();

  const {
    prices,
    paymentMethodSummary,
    paymentMethodNote,
    shippingDetails,
    payWithTermsNotification,
    notification,
    payWithTermsLegal,
  } = checkout;
  if (!prices || !paymentMethodSummary) {
    console.error('no prices / paymentMethodSummary provided');
    return null;
  }

  return (
    <Observed
      {...{
        shipping: shippingDetails,
        paymentDetails: prices,
        paymentMethodSummary,
        note: paymentMethodNote,
        notification: payWithTermsNotification || notification,
        legal: payWithTermsLegal,
      }}
    />
  );
};

export default observer(WithStoreConnection);

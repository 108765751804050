import LogRocket from 'logrocket';
import React from 'react';
import './App.css';
import WithAllAppWrappers from './wrappers';
import CheckoutManager from './sections/checkout/CheckoutManager';
import { config } from './config/config';

if (config.logRocketKey && config.isProduction) {
  LogRocket.init(config.logRocketKey, {
    release: 'checkoutV1',
    dom: {
      inputSanitizer: true,
    },
  });
}

function App() {
  return (
    <WithAllAppWrappers>
      <>
        <CheckoutManager />
      </>
    </WithAllAppWrappers>
  );
}

export default App;

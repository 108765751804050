import React, { FC } from 'react';
import {
  BILLING_ADDRESS_DEFAULT_VALUE,
  BillingAddress,
  yupBillingAddressValidationSchema,
} from './Steps/BillingAddress';
import { BUSINESS_INFO_DEFAULT_VALUES, BusinessInfo, yupBusinessInfoValidationSchema } from './Steps/BusinessInfo';
import _ from 'lodash';
import {
  BankAccountDetailsProps,
  BankPaymentMethodSchema,
  BillingAddressProps,
  BusinessInfoProps,
  UpdateVendor,
  UpdateVendorOnboardingProps,
} from '../../hooks/useVendorOnboarding/vendor-onboarding.types';
import { toJS } from 'mobx';
import { Body } from './Wrappers';
import { Box } from '@material-ui/core';
import { QualificationButton } from '../../../../../sections/qualification/QualificationButton';
import { FormProvider, useForm } from 'react-hook-form';
import { OnboardingForm } from './VendorOnboardingForm';
import { BankAccountUpdate } from './Steps/BankAccountPreview';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { yupBankAccountDetailsValidationSchema } from './Steps/BankAccountDetails';
import { transformVendorBank } from '../../forms/vendor.utils';
import { CountryFields } from '../../countries/countries.interfaces';

interface UpdateVendorFormProps {
  onSubmitDetails: UpdateVendor;
  isSubmitLoading: boolean;
  vendorInfo: UpdateVendorOnboardingProps;
  bankAccount?: BankPaymentMethodSchema;
}

const createValidationSchema = () =>
  Yup.object().shape({
    ...yupBusinessInfoValidationSchema,
    address: yupBillingAddressValidationSchema,
    bankAccountDetails: yupBankAccountDetailsValidationSchema
      .notRequired()
      // If color is undefined, leave it as undefined instead of casting it to {}
      .default(undefined),
  });

interface UpdateVendorOnboardingFormProps extends BusinessInfoProps {
  address: BillingAddressProps;
  bankAccountDetails?: BankAccountDetailsProps;
}

const DEFAULT_VALUES: UpdateVendorOnboardingFormProps = {
  ...BUSINESS_INFO_DEFAULT_VALUES,
  address: BILLING_ADDRESS_DEFAULT_VALUE,
};

const getDefaultValues = (vendorInfo: UpdateVendorOnboardingProps) => {
  const values = toJS(vendorInfo);
  return _.merge(DEFAULT_VALUES, {
    ...values,
    country: values?.bankAccountDetails?.country ?? values?.address?.countryCode,
    address: values?.address || BILLING_ADDRESS_DEFAULT_VALUE,
  });
};

const getFieldsRules = (vendorInfo: UpdateVendorOnboardingProps): Partial<CountryFields> => {
  const hasCountry = !!vendorInfo.country || !!vendorInfo.address?.countryCode;
  const hasEin = !!vendorInfo.ein;
  const hasName = !!vendorInfo.name;

  return {
    ein: { disabled: hasEin && hasCountry },
    country: { disabled: hasEin && hasCountry },
    name: { disabled: hasName },
  };
};

export const UpdateVendorForm: FC<UpdateVendorFormProps> = ({
  onSubmitDetails,
  isSubmitLoading,
  vendorInfo,
  bankAccount,
}) => {
  const methods = useForm<UpdateVendorOnboardingFormProps>({
    resolver: yupResolver(createValidationSchema()),
    defaultValues: getDefaultValues(vendorInfo),
    shouldFocusError: true,
  });

  const { handleSubmit } = methods;

  const onSubmit = handleSubmit(async (data) => {
    let transformedData = data;
    if (data.bankAccountDetails) {
      transformedData.bankAccountDetails = transformVendorBank(data.bankAccountDetails, data.country);
    }
    await onSubmitDetails(transformedData);
  });

  return (
    <OnboardingForm>
      <FormProvider {...methods}>
        <Body>
          <BusinessInfo fieldsRules={getFieldsRules(vendorInfo)} />
          <BillingAddress />
          <BankAccountUpdate bankAccount={bankAccount} />
          <Box display="flex" justifyContent={'flex-end'} mb={5}>
            <QualificationButton onClick={onSubmit} disabled={isSubmitLoading}>
              {isSubmitLoading ? 'Loading' : 'Update'}
            </QualificationButton>
          </Box>
        </Body>
      </FormProvider>
    </OnboardingForm>
  );
};

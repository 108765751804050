import * as React from 'react';
import { useEffect } from 'react';

import { usePlaidLink } from 'react-plaid-link';
import { Button } from '../../ui-lib';
import axios from 'axios';
import { useStore } from '../../stores/setupContext';
import { OnExitCallback, OnSuccessCallback, PlaidMetadata, Token } from '../../types/Plaid';

export interface PlaidHostProps {
  linkToken?: string;
  onSuccess?: OnSuccessCallback;
  onExit?: OnExitCallback;
  mode?: string;
  open?: VoidFunction;
}

export const PlaidWrap: React.FunctionComponent = () => {
  const [linkToken, setLinkToken] = React.useState<string>('');
  React.useEffect(() => {
    const asyncFunc = async () => {
      const { data } = await axios.post<{ link_token: string }>(
        'http://localhost:7777/api/v1/plaid/create_link_token',
        { clientUserId: '9999' },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      setLinkToken(data.link_token);
    };

    asyncFunc();
  }, []);

  if (!linkToken) {
    return null;
  }

  return (
    <PlaidHostStorybookVersion
      {...{
        linkToken,
        onSuccess: (token, metadata) => {},
        onExit: () => {
          console.log('exited');
        },
      }}
    />
  );
};

export const PlaidHostStorybookVersion: React.FunctionComponent<PlaidHostProps> = ({ linkToken, onSuccess }) => {
  const onPlaidSuccess = React.useCallback((token: Token, metadata: PlaidMetadata) => {
    onSuccess ? onSuccess(token, metadata) : undefined;
  }, []);

  const config = {
    token: linkToken,
  };

  return (
    <div>
      <Button
        {...{
          onClick: () => {
            open();
          },
          disabled: false,
        }}
      >
        OpenPlaid
      </Button>
    </div>
  );
};

export const PlaidHost: React.FunctionComponent<PlaidHostProps> = ({ mode, linkToken, onExit, onSuccess }) => {
  const { checkout } = useStore();
  const { linkToken: checkoutLinkToken, generateLinkTokenForPlaid, handlePlaidSuccess, handlePlaidExit } = checkout;

  useEffect(() => {
    if (linkToken) {
      return;
    }
    generateLinkTokenForPlaid(mode);
  }, [linkToken]);

  const onPlaidSuccess = React.useCallback((token: Token, metadata: PlaidMetadata) => {
    onSuccess ? onSuccess(token, metadata) : handlePlaidSuccess(token, metadata);
  }, []);

  const config = {
    token: linkToken ?? checkoutLinkToken,
    onSuccess: onPlaidSuccess,
    selectAccount: true,
    onExit: onExit ?? handlePlaidExit,
  };

  const { open, ready, error } = usePlaidLink(config);

  React.useEffect(() => {
    if (!ready) {
      return;
    }

    open();
  }, [open]);

  return <div />;
};

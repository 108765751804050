import { BankAccountDetailsProps, VendorOnboardingProps } from '../hooks/useVendorOnboarding/vendor-onboarding.types';
import { COUNTRIES_FIELDS_MAP, COUNTRIES_MAP } from '../countries/countries';
import { CountryCode } from '../countries/countries.interfaces';
import { trimBankAccountTransform } from '../countries/countries.utils';
import { clearPhoneRedundantCharacters } from './phone.utils';

export const transformVendorBank = (
  bankAccountDetails: BankAccountDetailsProps,
  country: CountryCode
): BankAccountDetailsProps => {
  delete bankAccountDetails.confirmAccountNumber;
  const { countryCode, currency } = COUNTRIES_MAP[country];
  const customBankDetails = COUNTRIES_FIELDS_MAP[country].bankAccountDetails;
  const transformedBankDetails = customBankDetails?.transform
    ? customBankDetails.transform(bankAccountDetails)
    : trimBankAccountTransform(bankAccountDetails);

  return {
    country: countryCode,
    currency,
    ...transformedBankDetails,
  };
};

export const transformVendor = ({
  bankAccountDetails: rawBankAccountDetails,
  address,
  phone,
  ...data
}: VendorOnboardingProps): VendorOnboardingProps => {
  const bankAccountDetails = transformVendorBank(rawBankAccountDetails, data.country);
  address.countryCode = bankAccountDetails.country as CountryCode;

  return {
    bankAccountDetails,
    address,
    phone: clearPhoneRedundantCharacters(phone),
    url: data.businessDomain,
    ...data,
  };
};

import React, { useEffect, useRef, useState } from 'react';
import usePlacesAutocomplete, { GeocodeResult, getGeocode } from 'use-places-autocomplete';
import { InputAdornment, Menu, MenuItem } from '@material-ui/core';
import { StyledTextField } from '../TextField/StyledTextField';
import { BusinessAddress } from '../../sections/qualification/BusinessDetailsForm';
import { TextFieldProps } from '@material-ui/core/TextField/TextField';
import SearchIcon from '@material-ui/icons/Search';
import { useMobile } from '../../utils/mobile.hook';

export interface PlacesAutocompleteProps {
  outerStyle?: {};
  inputStyle?: {};
  onAddressSelected: (address: BusinessAddress) => void;
  onValueChanged: (value: string) => void;
  keepAddress?: boolean;
  restrictCountries?: string[];
}

export const PlacesAddressAutocomplete = ({
  onAddressSelected,
  onValueChanged,
  placeholder,
  margin,
  outerStyle,
  inputStyle,
  keepAddress,
  restrictCountries,
  ...props
}: PlacesAutocompleteProps & TextFieldProps) => {
  const requestOptions = restrictCountries?.length ? { componentRestrictions: { country: restrictCountries } } : {};
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions,
    debounce: 300,
  });
  const [anchorEl, setAnchorEl] = useState();
  const autocompleteRef = useRef<HTMLInputElement>();

  useEffect(() => {
    //@ts-ignore
    setValue(undefined);
  }, []);

  const handleInput = (e: any) => {
    setAnchorEl(e.currentTarget);
    setValue(e.target.value);
    onValueChanged(e.target.value);
  };

  const onSuggestionsFocus = () => {
    autocompleteRef?.current?.focus();
  };

  function createObjectFromResults(results: GeocodeResult[]) {
    const address = Object.fromEntries(
      results[0]?.address_components?.map((addressComponent: any) => [
        addressComponent?.types[0],
        addressComponent?.types[0] === 'country' ? addressComponent?.short_name : addressComponent?.long_name,
      ])
    );
    const formattedAddress = results[0]?.formatted_address;
    return Object.assign(address, { formattedAddress });
  }

  function getAddressFromResults(results: GeocodeResult[]): BusinessAddress {
    const objectResults = createObjectFromResults(results);
    const {
      street_number,
      locality,
      route,
      administrative_area_level_2,
      administrative_area_level_1,
      country,
      postal_code,
      colloquial_area,
      political,
      formattedAddress,
      postal_town,
    } = objectResults;
    const adminLevel = administrative_area_level_1 || administrative_area_level_2;
    return {
      city: postal_town || locality || administrative_area_level_1,
      country: country,
      state: country === 'US' ? adminLevel : adminLevel || locality,
      address1:
        street_number && route
          ? `${street_number} ${route}`
          : street_number || route || colloquial_area || political || '',
      zipCode: postal_code,
      formattedAddress: formattedAddress,
    };
  }

  const handleSelect = (suggestion: any) => () => {
    setValue(suggestion.description, false);
    clearSuggestions();

    getGeocode({ address: suggestion.description })
      .then((results) => {
        const addressResults = getAddressFromResults(results);
        if (!keepAddress) {
          //@ts-ignore
          setValue(undefined);
        }
        setTimeout(() => {
          onAddressSelected(addressResults);
        }, 200);
      })
      .catch((error: any) => {
        console.log('Error: ', error);
      });
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <MenuItem key={id} onClick={handleSelect(suggestion)}>
          {main_text}&nbsp;<small>{secondary_text}</small>
        </MenuItem>
      );
    });

  const handleClose = () => {
    //@ts-ignore
    setValue(undefined);
    clearSuggestions();
    setAnchorEl(undefined);
  };

  let styles = !useMobile()
    ? {
        maxWidth: 550,
        width: '100%',
        minWidth: 500,
      }
    : {
        maxWidth: 400,
        width: '90%',
        minWidth: 300,
        right: '16px',
      };

  return (
    <div style={{ ...outerStyle }}>
      <StyledTextField
        {...props}
        value={value ?? props.value}
        onChange={handleInput}
        disabled={!ready}
        margin={margin || 'normal'}
        fullWidth
        variant="standard"
        placeholder={placeholder ?? 'Business Address'}
        style={{
          width: '100%',
        }}
        inputRef={autocompleteRef}
        InputLabelProps={{
          style: { fontFamily: 'Aeonik', fontSize: 16 },
        }}
        inputProps={{ style: inputStyle }}
        InputProps={{
          style: inputStyle,
          endAdornment: (
            <InputAdornment position="start">
              <SearchIcon style={{ color: '#989898' }} />
            </InputAdornment>
          ),
        }}
      />
      {status === 'OK' && (
        <Menu
          disableAutoFocus={true}
          disableRestoreFocus={true}
          disableEnforceFocus={true}
          onFocus={onSuggestionsFocus}
          anchorEl={anchorEl}
          keepMounted
          open={true}
          onClose={handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          PaperProps={{
            style: styles,
          }}
        >
          {renderSuggestions()}
        </Menu>
      )}
    </div>
  );
};

import React from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { StyledTextField } from '../../../ui-lib/TextField/StyledTextField';

export const StyledNumberInput = (props: NumberFormatProps) => {
  const { ref: inputRef, ...other } = props;

  return (
    <NumberFormat
      allowLeadingZeros
      ref={inputRef}
      allowNegative={false}
      allowEmptyFormatting={false}
      customInput={StyledTextField}
      {...other}
    />
  );
};

import { Typography } from '../../ui-lib';
import { Logo } from './SectionDetails';
import logo from '../../icons/logo.svg';
import React from 'react';
import { SecureIcon } from '../../icons/SecureIcon/SecureIcon';
import styled from '@emotion/styled';
import { Theme } from '../../theme';
import { flexStartCenter } from '../../styles/common';

export const HeaderLogoItem = styled('a')<{ theme: Theme }>(() => ({
  ...flexStartCenter,
  cursor: 'pointer',
  gap: 4,
  textDecoration: 'none',
}));

export interface PoweredByProps {
  linkParams: string;
}

export const PoweredBy = ({ linkParams }: PoweredByProps) => {
  return (
    <HeaderLogoItem
      target="_blank"
      href={`https://www.getbalance.com/${linkParams ?? ''}`}
      data-analytics-id="Balance-clicked"
    >
      <SecureIcon />
      <Typography
        {...{
          variant: 'label',
          component: 'span',
          style: {
            color: '#9fa2b4',
          },
        }}
      >
        Powered by&nbsp;
      </Typography>
      <Logo {...{ src: logo }} />
    </HeaderLogoItem>
  );
};

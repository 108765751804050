import * as React from 'react';
import SVGIcon, { SVGIconProps } from './../../ui-lib/SVGIcon/SVGIcon';
//import styled from '@emotion/styled';

export interface SlashIconProps {
  color?: SVGIconProps['color'];
}

export const SlashIcon: React.FunctionComponent<SlashIconProps> = ({ color }) => {
  return (
    <SVGIcon {...{ width: 9, height: 22, viewBox: '0 0 9 22', color }}>
      <path d="M2.77372 22L9 0H6.22627L0 22H2.77372Z" />
    </SVGIcon>
  );
};

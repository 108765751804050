import styled from '@emotion/styled';
import { Theme } from '../../theme';
import { flexCenterCenter, flexStartCenter } from '../../styles/common';
import React, { PropsWithChildren } from 'react';
import { Typography } from '../../ui-lib';
import { device } from './../../utils/devices/devices.utils';
import { useMobile } from '../../utils/mobile.hook';
import { TypographyVariant } from '@material-ui/core';

export const Image = styled('img')<{ theme: Theme }>(({ theme }) => ({
  display: 'block',
  height: 32,
}));

export const QualificationStepContainer = styled('div')<{ theme: Theme }>(({ theme }) => ({
  ...flexCenterCenter,
  flex: '0 0 auto',
  margin: 'auto',
  [`@media ${device.mobileL}`]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: 0,
    height: '100%',
  },
}));

export const QualificationStepContent = styled('div')<{ theme: Theme }>(({ theme }) => ({
  maxWidth: 420,
}));

export const QualificationMessageContainer = styled('div')<{ theme: Theme }>(({ theme }) => ({
  ...flexCenterCenter,
  gap: '2rem',
  flexDirection: 'column',
  [`@media ${device.mobileL}`]: {
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
}));

export const QualificationMessageContent = styled('div')<{ theme: Theme }>(({ theme }) => ({
  ...flexCenterCenter,
  gap: '2rem',
  [`@media ${device.mobileL}`]: {
    width: '100%',
  },
}));

export const QualificationBulletWrapper = styled('div')<{ theme: Theme }>(({ theme }) => ({
  ...flexStartCenter,
  gap: '1rem',
}));

export const QualificationHeading = ({ children }: PropsWithChildren<{}>) => {
  const variant = useMobile() ? 'h1Bold' : 'h148Bold';
  return (
    <Typography variant={variant} center={false}>
      {children}
    </Typography>
  );
};

export const QualificationMobileHeading = ({ children }: PropsWithChildren<{}>) => (
  <Typography
    {...{
      variant: 'h1Bold',
      center: false,
    }}
  >
    {children}
  </Typography>
);

export const QualificationStepDescription = ({ children }: PropsWithChildren<{}>) => (
  <Typography
    {...{
      variant: 'body1',
      center: false,
    }}
  >
    {children}
  </Typography>
);

export const QualificationNote = ({ children }: PropsWithChildren<{}>) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      color: '#6A6A6A',
    }}
  >
    <div
      style={{
        paddingRight: '16px',
      }}
    >
      Icon
    </div>
    <Typography
      {...{
        variant: 'label',
        center: false,
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '120%',
      }}
    >
      {children}
    </Typography>
  </div>
);

export const QualificationStepBullet = ({ children }: PropsWithChildren<{}>) => (
  <Typography
    {...{
      variant: 'label',
      center: false,
    }}
  >
    {children}
  </Typography>
);

export const QualificationColumn = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 30,
  padding: 40,
  paddingRight: 0,
  width: 420,

  [`@media ${device.mobileL}`]: {
    padding: 16,
    height: '100%',
    width: '100%',
  },
}));

export const DividerWrapper = styled('div')({
  marginTop: '48px',
  marginBottom: '32px',
});

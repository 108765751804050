import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Grid } from '@material-ui/core';
import { flexCenterCenter } from '../../styles/common';
import styled from '@emotion/styled';
import { BusinessInfoStep } from './BusinessInfoStep';
import { OwnerInfoStep } from './OwnerInfoStep';
import SmbFooter from './SmbFooter';
import { SmallBusinessFormFields } from '../../api/api.schema';
import { BillingAddress, BusinessAddress, OwnerAddress } from './BusinessDetailsForm';
import { createSmbValidationSchema } from '../../apps/VendorOnboarding/src/forms/smb.validation.util';

export interface BusinessInfoFormProps {
  onSubmit: (fields: SmallBusinessFormFields) => Promise<void>;
  buyerAddress?: BillingAddress;
  buyerName?: string;
}

export const LegalStatement = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const DividerWrapper = styled('div')({
  marginTop: '48px',
  marginBottom: '32px',
});

export const FormContainer = styled('div')(() => ({
  ...flexCenterCenter,
  width: '100%',
}));
export const FormCenteredContainer = styled('div')(() => ({
  maxWidth: 650,
}));

export const BusinessInfoForm = ({ onSubmit, buyerAddress, buyerName }: BusinessInfoFormProps) => {
  const { handleSubmit, control, setValue } = useForm<SmallBusinessFormFields>({
    resolver: yupResolver(createSmbValidationSchema()),
    shouldFocusError: true,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      businessName: buyerName || '',
      state: buyerAddress?.state || '',
      city: buyerAddress?.city || '',
      zipCode: buyerAddress?.zipCode || '',
      businessAddress: buyerAddress?.addressLine1 || '',
      address2: buyerAddress?.addressLine2,
      ein: undefined,
      resaleNumber: undefined,
    },
  });

  const setSelectedBusinessAddressSuggestionValues = (address: BusinessAddress) => {
    Object.entries(address).forEach((entry) => {
      const [key, value] = entry;
      if (!value) {
        setValue(key as keyof SmallBusinessFormFields, '');
      } else if (key === 'address1') {
        setValue('businessAddress', value as string, { shouldValidate: true });
      } else {
        setValue(key as keyof SmallBusinessFormFields, value as string, { shouldValidate: true });
      }
    });
  };

  const setSelectedOwnerAddressSuggestionValues = (address: BusinessAddress) => {
    const ownerAddress: OwnerAddress = {
      ownerAddress: address.address1,
      ownerAddress2: address.address2,
      ownerCity: address.city,
      ownerState: address.state,
      ownerZipCode: address.zipCode,
    };
    Object.entries(ownerAddress).forEach((entry) => {
      const [key, value] = entry;
      if (!value) {
        setValue(key as keyof SmallBusinessFormFields, '');
      } else {
        setValue(key as keyof SmallBusinessFormFields, value as string, { shouldValidate: true });
      }
    });
  };

  const onSubmitClicked = (data: SmallBusinessFormFields) => {
    data.ein = data.ein?.replace('-', '');
    onSubmit(data);
  };

  return (
    <FormContainer>
      <FormCenteredContainer>
        <form id={'qualification-business-info'} onSubmit={handleSubmit(onSubmitClicked)} noValidate={true}>
          <Grid container spacing={6} style={{ maxWidth: 800 }}>
            <BusinessInfoStep
              control={control}
              setValue={setValue}
              setSelectedAddressSuggestionValues={setSelectedBusinessAddressSuggestionValues}
            />
            <OwnerInfoStep
              control={control}
              setValue={setValue}
              setSelectedAddressSuggestionValues={setSelectedOwnerAddressSuggestionValues}
            />
            <SmbFooter />
          </Grid>
        </form>
      </FormCenteredContainer>
    </FormContainer>
  );
};

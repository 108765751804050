import * as React from 'react';
import styled from '@emotion/styled';

import { Theme } from '../../theme';

export const Table = styled('div')({
  display: 'grid',
});

export const StyledRow = styled('div')<{
  theme: Theme;
  dense?: boolean;
  withBackground?: boolean;
}>(({ theme, dense, withBackground }) => ({
  display: 'grid',
  minHeight: dense ? 20 : 50,
  height: 'auto',
  marginTop: theme.gutters.base,
  paddingLeft: theme.gutters.base * 2,
  paddingRight: theme.gutters.base * 2,
  background: withBackground ? theme.palette.background.alpha : 'none',
  borderRadius: 8,
}));

export const Col = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const Row = ({
  children,
  dense,
  background,
  style,
}: {
  children: React.ReactNode;
  dense?: boolean;
  background?: boolean;
  style?: React.CSSProperties;
}) => {
  const getGridTemplateColumns = () => {
    const childrenCount = React.Children.count(children);
    const items = new Array(childrenCount).fill('');

    return items.map((item, index) => (index === 0 ? '2fr' : '1fr')).join(' ');
  };

  return (
    <StyledRow
      {...{
        dense,
        withBackground: background,
        style: {
          gridTemplateColumns: getGridTemplateColumns(),
          ...style,
        },
      }}
    >
      {children}
    </StyledRow>
  );
};

export const getInitials = (name: string): string => {
  try {
    let initials;
    const nameSplit = name.split(' ');
    const nameLength = nameSplit.length;
    if (nameLength > 1) {
      initials = nameSplit[0].substring(0, 1) + nameSplit[nameLength - 1].substring(0, 1);
    } else if (nameLength === 1) {
      initials = nameSplit[0].substring(0, 1);
    } else {
      return name;
    }
    return initials.toUpperCase();
  } catch (e) {
    return name;
  }
};

export const withHttps = (url: string) => (!/^https?:\/\//i.test(url) ? `https://${url}` : url);

import styled from '@emotion/styled';
import { Theme } from '../../theme';
import { Typography } from '../../ui-lib';
import { flexCenterCenter } from '../../styles/common';

export const Container = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'grid',
  gap: theme.gutters.base * 2,
  gridTemplateRows: 'min-content min-content min-content 1fr',
  alignContent: 'start',
  marginTop: theme.gutters.base * 7,
  maxHeight: 1000,
}));

export const DetailsWrap = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'grid',
  gap: theme.gutters.base * 2,
  marginTop: theme.gutters.base,
}));

export const SellerLogo = styled('img')<{ theme: Theme }>(({ theme }) => ({
  maxWidth: '80%',
  maxHeight: '100px',
  marginBottom: theme.gutters.base,
}));

export const SellerName = styled('div')<{ theme: Theme }>(({ theme }) => ({
  maxWidth: '80%',
  fontWeight: 'bold',
  fontFamily: 'Aeonik-bold',
}));

export const Link = styled('a')<{ theme: Theme }>(({ theme }) => ({
  color: theme.palette.text.link,
}));

export const Footer = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignSelf: 'end',
  marginTop: theme.gutters.base * 3,
  marginBottom: theme.gutters.base * 2,
}));

export const Logo = styled('img')<{ theme: Theme }>(({ theme }) => ({
  ...flexCenterCenter,
  height: '100%',
  maxHeight: '1rem',
}));

import { useEffect, useState } from 'react';
import { createTheme, Theme, DEFAULT_PRIMARY } from '../../../theme';
import { ColorOverrides } from '../../../api/api.schema';
import * as Mui from '@material-ui/core';

type MerchantThemeHook = (colorOverrides?: ColorOverrides | null, defaultPrimary?: string) => [Theme, Mui.Theme];

export const useMerchantTheme: MerchantThemeHook = (colorOverrides, defaultPrimary = DEFAULT_PRIMARY) => {
  const [theme, setTheme] = useState<Theme>(createTheme({ colorOverrides: { primary: defaultPrimary } }));
  const [muiTheme, setMuiTheme] = useState<Mui.Theme>(Mui.createMuiTheme());

  useEffect(() => {
    if (!colorOverrides || !colorOverrides.primary) {
      return;
    }

    const modifiedTheme = createTheme({ colorOverrides });
    setTheme(modifiedTheme);

    const muiTheme = Mui.createMuiTheme({
      palette: {
        primary: {
          main: modifiedTheme.palette.main.primary,
        },
      },
      overrides: {
        MuiFormLabel: {
          asterisk: {
            color: modifiedTheme.palette.indicators.danger,
          },
        },
        MuiFormHelperText: {
          root: {
            height: 0,
          },
        },
      },
    });
    setMuiTheme(muiTheme);
  }, [colorOverrides?.primary]);

  return [theme, muiTheme];
};

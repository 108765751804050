import businessBuilding from '../../apps/assets/businessBuilding.svg';
import { Typography } from '../../ui-lib';
import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { StyledTextField } from '../../ui-lib/TextField/StyledTextField';
import {
  InputMonthlySalesMask,
  InputResaleNumberMask,
  InputTextMask,
  PhoneNumberMask,
} from '../../components/Fields/text.input.masks';
import { PlacesAddressAutocomplete } from '../../ui-lib/Address';
import { FormStep } from '../../ui-lib/Card/FormStep';
import React from 'react';
import { Control, UseFormSetValue } from 'react-hook-form/dist/types/form';
import { SmallBusinessFormFields } from '../../api/api.schema';
import { BusinessAddress } from './BusinessDetailsForm';
import { useMobile } from '../../utils/mobile.hook';
import { DividerWrapper } from './common';

const convertDollarAmountToNumber = (amount: string): number => {
  return parseInt(amount.replace(/\D/g, ''));
};

export interface BusinessInfoStepProps {
  control: Control<SmallBusinessFormFields>;
  setValue: UseFormSetValue<SmallBusinessFormFields>;
  setSelectedAddressSuggestionValues: (address: BusinessAddress) => void;
}

export const BusinessInfoStep = ({ control, setValue, setSelectedAddressSuggestionValues }: BusinessInfoStepProps) => {
  const isMobile = useMobile();
  const businessSectionTitle = '1 / Business Info';
  const businessSectionSubTitle = "We'll use this information to verify your business";
  const businessAddressSubSectionTitle = 'Business Address';
  const noEinLabel = "I don't have an EIN";
  const noResaleNumberLabel = "I don't have a Resale Number";
  const mobileHelperText = 'We may use this number to send you payment reminders';

  const styles = makeStyles({
    checkboxLabel: {
      color: 'gray',
    },
    select: {
      verticalAlign: 'bottom',
      marginTop: '24px',
    },
  })();

  const businessTypes = [
    { value: 'llc', text: 'LLC' },
    { value: 'corporate', text: 'Corporate' },
    { value: 'partnership', text: 'Partnership' },
    { value: 'sole proprietorship', text: 'Sole proprietorship' },
  ];

  return (
    <FormStep>
      {!isMobile && <img src={businessBuilding} alt={'business icon'} />}
      <Typography variant={'h1Bold'} fontWeight={700} marginTop={'24px'} marginBottom={'24px'}>
        {businessSectionTitle}
      </Typography>
      <Typography color={'strongGray'} fontWeight={400} marginTop={'24px'} marginBottom={'24px'}>
        {businessSectionSubTitle}
      </Typography>
      <Grid container spacing={6}>
        <Grid item xs={12} md={6} style={{ order: 1 }}>
          <Controller
            control={control}
            name="businessName"
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                required
                {...field}
                margin="normal"
                fullWidth
                variant="standard"
                placeholder="Business Name"
                error={Boolean(error)}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} style={{ order: 2 }}>
          <Controller
            control={control}
            name="businessType"
            render={({ field, fieldState: { error } }) => (
              <FormControl {...field} fullWidth className={styles.select}>
                <InputLabel htmlFor="business-type-select">Business Type</InputLabel>
                <Select id="business-type-select" required {...field}>
                  {businessTypes.map((type) => (
                    <MenuItem key={type.value} value={type.value}>
                      {type.text}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error>{error?.message}</FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} style={{ order: 3 }}>
          <Controller
            control={control}
            name="ein"
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                {...field}
                margin="normal"
                fullWidth
                variant="standard"
                placeholder="EIN"
                label="Employer Identification Number"
                error={Boolean(error)}
                helperText={error?.message}
                InputProps={{
                  inputComponent: InputTextMask,
                  inputRef: field.ref,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} style={{ order: isMobile ? 5 : 4 }}>
          <Controller
            control={control}
            name="resaleNumber"
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                {...field}
                margin="normal"
                fullWidth
                variant="standard"
                placeholder="Resale Number"
                label="Resale Number"
                error={Boolean(error)}
                helperText={error?.message}
                InputProps={{
                  inputComponent: InputResaleNumberMask,
                  inputRef: field.ref,
                }}
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          style={{
            order: isMobile ? 4 : 5,
            paddingTop: 0,
            paddingBottom: 0,
          }}
        >
          <FormControlLabel
            control={
              <Controller name={'dontHaveEin'} control={control} render={(props) => <Checkbox color={'primary'} />} />
            }
            label={noEinLabel}
            className={styles.checkboxLabel}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          style={{
            order: 6,
            paddingTop: 0,
            paddingBottom: 0,
          }}
        >
          <FormControlLabel
            control={
              <Controller
                name={'dontHaveResaleNumber'}
                control={control}
                render={(props) => <Checkbox color={'primary'} />}
              />
            }
            label={noResaleNumberLabel}
            className={styles.checkboxLabel}
          />
        </Grid>
        <Grid item xs={12} md={6} style={{ order: 7 }}>
          <Controller
            control={control}
            name="monthlySales"
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                {...field}
                required
                margin="normal"
                fullWidth
                variant="standard"
                placeholder="Monthly Sales ($)"
                label="Monthly Sales"
                error={Boolean(error)}
                helperText={error ? 'required' : ''}
                InputProps={{
                  inputComponent: InputMonthlySalesMask,
                  inputRef: field.ref,
                }}
                onChange={(event) => {
                  event.target.value === ''
                    ? setValue('monthlySales', 0)
                    : setValue('monthlySales', convertDollarAmountToNumber(event.target.value));
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} style={{ order: 8 }}>
          <Controller
            control={control}
            name="website"
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                required
                {...field}
                margin="normal"
                fullWidth
                variant="standard"
                placeholder="Website / Social Media Page"
                error={Boolean(error)}
                helperText={error ? 'required' : ''}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={12} style={{ order: 9 }}>
          <Controller
            control={control}
            name="mobilePhoneNumber"
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                {...field}
                required
                margin="normal"
                fullWidth
                variant="standard"
                placeholder="Mobile Phone Number"
                label="Mobile Phone Number"
                error={Boolean(error)}
                helperText={error ? error?.message : mobileHelperText}
                InputProps={{
                  inputComponent: PhoneNumberMask,
                  inputRef: field.ref,
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <DividerWrapper>
        <Divider variant="fullWidth" />
      </DividerWrapper>
      <Typography variant={'body1Bold'} fontWeight={700} marginTop={'24px'} marginBottom={'24px'}>
        {businessAddressSubSectionTitle}
      </Typography>
      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="businessAddress"
            render={({ field, fieldState: { error } }) => (
              <PlacesAddressAutocomplete
                required
                restrictCountries={['us']}
                {...field}
                defaultValue={field.value}
                placeholder={'Business Address'}
                onAddressSelected={setSelectedAddressSuggestionValues}
                error={Boolean(error)}
                helperText={error?.message}
                onValueChanged={(newValue: string) => setValue('businessAddress', newValue)}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="address2"
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                {...field}
                margin="normal"
                fullWidth
                variant="standard"
                placeholder="Address line 2"
                error={Boolean(error)}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="city"
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                required
                {...field}
                margin="normal"
                fullWidth
                variant="standard"
                placeholder="City"
                error={Boolean(error)}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="state"
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                required
                {...field}
                margin="normal"
                fullWidth
                variant="standard"
                placeholder="State"
                error={Boolean(error)}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            control={control}
            name="zipCode"
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                required
                {...field}
                margin="normal"
                fullWidth
                variant="standard"
                placeholder="Zip Code"
                error={Boolean(error)}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            control={control}
            name="googleMapsLink"
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                {...field}
                margin="normal"
                fullWidth
                variant="standard"
                placeholder="Google Maps Link (optional)"
                error={Boolean(error)}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
      </Grid>
    </FormStep>
  );
};

import StoreBase from './StoreBase';
import {
  getMerchantSettings,
  getVendorDetails,
  MerchantSettings,
  setOnboardingToken,
} from '../apps/VendorOnboarding/src/api';
import { makeObservable, observable } from 'mobx';
import { getQueryParams } from '../utils/query-params.util';
import { config } from '../config/config';
import * as FullStory from '@fullstory/browser';
import {
  PaymentData,
  VendorOnboardingProps,
} from '../apps/VendorOnboarding/src/hooks/useVendorOnboarding/vendor-onboarding.types';
import history from '../routing/history';
import {
  ERROR_PAGE,
  HOME_PAGE,
  UNAUTHORIZED_ERROR_PAGE,
} from '../apps/VendorOnboarding/src/components/Form/VendorOnboardingLayout';
import { HttpStatus } from '../utils/HttpStatusCode';

export default class VendorOnboardingStore extends StoreBase {
  public merchant: MerchantSettings | null = {} as MerchantSettings;
  public isLoading: boolean = true;
  public token: string | null = '';
  public redirectUrl: string = '';
  public vendorDetails: VendorOnboardingProps | undefined = undefined;
  public paymentData: PaymentData | undefined = undefined;

  constructor() {
    super();
    makeObservable(this, {
      merchant: observable,
      isLoading: observable,
      vendorDetails: observable,
    });
    this.vendorOnboardingInit();
  }

  public get isVendorUpdate(): boolean {
    const { vendorPublicId } = getQueryParams();
    return !!vendorPublicId;
  }

  private vendorOnboardingInit = async () => {
    try {
      this.startLoading();
      const { token, redirectUrl, vendorPublicId } = getQueryParams();
      if (!token) {
        return;
      }
      setOnboardingToken(token as string);

      const promises = [];
      promises.push(this.fetchMerchantSettings());
      vendorPublicId && promises.push(this.fetchVendorDetails(vendorPublicId as string));
      await Promise.all(promises);

      history.push({ pathname: HOME_PAGE, search: location.search });
      this.redirectUrl = redirectUrl as string;
    } catch (e) {
      if (e.response?.status === HttpStatus.UNAUTHORIZED) {
        history.push({ pathname: UNAUTHORIZED_ERROR_PAGE, search: location.search });
      } else {
        history.push({ pathname: ERROR_PAGE, search: location.search });
      }
    } finally {
      this.stopLoading();
    }
  };

  private startLoading = () => {
    this.isLoading = true;
  };

  private stopLoading = () => {
    this.isLoading = false;
  };

  private fetchMerchantSettings = async (): Promise<void> => {
    const { data } = await getMerchantSettings();
    this.merchant = data;

    if (config.fullStoryOrgId) {
      FullStory.setUserVars({
        merchant: this.merchant?.name,
      });
    }
  };

  private fetchVendorDetails = async (vendorPublicId: string): Promise<void> => {
    const res = await getVendorDetails(vendorPublicId);
    this.paymentData = res.data.paymentData;
    this.vendorDetails = res.data.sellerInfo;
  };
}

import * as React from 'react';
import { PaymentMethodSummary, SectionTitle } from './Review';
import styled from '@emotion/styled';
import { Theme } from '../../../theme/theme';
import { Typography } from '../../../ui-lib';

export interface PaymentMethodProps {
  paymentMethodSummary: PaymentMethodSummary;
  note?: string;
}

export const PaymentMethodLayout = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridTemplateRows: 'auto auto',
  gap: theme.gutters.base * 1,
  gridTemplateAreas: `
    'bank account'
    'note note'
    `,
}));

export const WithUnderscore = styled(Typography)<{ theme: Theme }>(({ theme }) => ({
  borderBottom: '1px solid #e5e5e5',
  paddingBottom: theme.gutters.base / 2,
}));

export const Note = styled('p')<{ theme: Theme }>(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  margin: 0,
  ...theme.typography.meta.body1,
  borderBottom: '1px solid #e5e5e5',
  paddingBottom: theme.gutters.base / 2,
  color: theme.palette.text.main,
}));

export const Label = styled(Typography)<{ theme: Theme }>(({ theme }) => ({
  ...theme.typography.meta.caption,
  marginBottom: theme.gutters.base * 1,
}));

export const PaymentMethod: React.FunctionComponent<PaymentMethodProps> = ({ paymentMethodSummary, note }) => {
  return (
    <div>
      <SectionTitle>Payment Method</SectionTitle>
      <PaymentMethodLayout>
        <div>
          <Label {...{ color: 'grey' }}>{paymentMethodSummary.box1.label}</Label>
          <WithUnderscore {...{ variant: 'body1' }}>{paymentMethodSummary.box1.text}</WithUnderscore>
        </div>
        <div {...{ style: { gridArea: 'account' } }}>
          {paymentMethodSummary.box2 && (
            <>
              <Label {...{ color: 'grey' }}>{paymentMethodSummary.box2.label}</Label>
              <WithUnderscore {...{ variant: 'body1' }}>{paymentMethodSummary.box2.text}</WithUnderscore>
            </>
          )}
        </div>
        <div {...{ style: { gridArea: 'note', marginTop: 16 } }}>
          <Label {...{ color: 'grey' }}>Note</Label>
          {note && <Note {...{ dangerouslySetInnerHTML: { __html: note } }} />}
        </div>
      </PaymentMethodLayout>
    </div>
  );
};

export default PaymentMethod;

import { StepCard } from '../../../../../Components/StepCard';
import LocationSvg from '../../../../../assets/location.svg';
import React, { VFC } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Grid } from '@material-ui/core';
import { StyledTextField } from '../../../../../../ui-lib/TextField/StyledTextField';
import * as Yup from 'yup';
import { PhoneInputField } from '../../../../../Components/PhoneInputField/PhoneInputField';
import { BusinessAddress } from '../../../../../../sections/qualification/BusinessDetailsForm';
import { PlacesAddressAutocomplete } from '../../../../../../ui-lib/Address';
import { StyledNumberInput } from '../../../../../Components/StyledNumber';
import { getCountryConfig } from '../../../countries/countries.utils';
import { BillingAddressProps, VendorOnboardingProps } from '../../../hooks/useVendorOnboarding/vendor-onboarding.types';

export const yupBillingAddressValidationSchema = Yup.object().shape({
  addressLine1: Yup.string().required('Required'),
  addressLine2: Yup.string().optional().nullable(),
  city: Yup.string().required('Required'),
  state: Yup.string().optional(),
  zipCode: Yup.string().required('Required'),
});

export const BILLING_ADDRESS_DEFAULT_VALUE: BillingAddressProps = {
  countryCode: 'US',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  zipCode: '',
};

export const BillingAddress: VFC = () => {
  const { control, setValue } = useFormContext<VendorOnboardingProps>();

  const countryCode = useWatch({
    control: control,
    name: 'country',
  });

  const setSelectedAddressSuggestionValues = (rawAddress: BusinessAddress) => {
    let { address1, country } = rawAddress;
    const transformedAddress = { ...rawAddress, addressLine1: address1, countryCode: country };
    Object.entries(transformedAddress).forEach((entry) => {
      const [key, value] = entry;
      const fieldName = `address.${key}` as keyof VendorOnboardingProps;
      if (!value) {
        setValue(fieldName, '');
      } else {
        setValue(fieldName, value as string, { shouldValidate: true });
      }
    });
  };

  const fields = getCountryConfig(countryCode);

  const ZipCodeComponent = fields.address?.zipCode?.Component ?? StyledNumberInput;

  return (
    <StepCard iconSrc={LocationSvg} headerText={['Fill out your', 'contact details']}>
      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="address.addressLine1"
            render={({ field, fieldState: { error } }) => (
              <PlacesAddressAutocomplete
                {...field}
                inputRef={field.ref}
                defaultValue={field.value}
                onAddressSelected={setSelectedAddressSuggestionValues}
                error={Boolean(error)}
                helperText={error?.message}
                onValueChanged={(newValue: string) => {
                  setValue('address.addressLine1', newValue);
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="address.addressLine2"
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                {...field}
                inputRef={field.ref}
                margin="normal"
                fullWidth
                variant="standard"
                placeholder="Address line 2 (Optional)"
                label="Address line 2"
                error={Boolean(error)}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="address.city"
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                {...field}
                inputRef={field.ref}
                margin="normal"
                fullWidth
                variant="standard"
                placeholder="City"
                error={Boolean(error)}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        {fields?.address?.state && (
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="address.state"
              render={({ field, fieldState: { error } }) => (
                <StyledTextField
                  {...field}
                  inputRef={field.ref}
                  margin="normal"
                  fullWidth
                  variant="standard"
                  placeholder="State"
                  label="State"
                  error={Boolean(error)}
                  helperText={error?.message}
                  {...fields?.address?.state}
                />
              )}
            />
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="address.zipCode"
            render={({ field, fieldState: { error } }) => (
              <ZipCodeComponent
                {...field}
                inputRef={field.ref}
                margin="normal"
                fullWidth
                variant="standard"
                placeholder="Postal Code"
                label="Postal Code"
                error={Boolean(error)}
                helperText={error?.message}
                {...fields?.address?.zipCode}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <PhoneInputField defaultCountry={countryCode} {...fields?.phone} />
        </Grid>
      </Grid>
    </StepCard>
  );
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./fonts/Aeonik-Regular.woff";
import ___CSS_LOADER_URL_IMPORT_1___ from "./fonts/Aeonik-Regular.woff2";
import ___CSS_LOADER_URL_IMPORT_2___ from "./fonts/Aeonik-Bold.woff";
import ___CSS_LOADER_URL_IMPORT_3___ from "./fonts/Aeonik-Bold.woff2";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',\n    'Droid Sans', 'Helvetica Neue', sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;\n}\n\n@font-face {\n  font-family: 'Aeonik';\n  font-style: normal;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('woff'), url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('woff2');\n}\n\n@font-face {\n  font-family: 'Aeonik-bold';\n  font-style: normal;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('woff'), url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format('woff2');\n}\n\nb {\n  font-family: 'Aeonik-Bold', serif;\n}\n", "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;8CAC4C;EAC5C,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE,+EAA+E;AACjF;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,oHAA2G;AAC7G;;AAEA;EACE,0BAA0B;EAC1B,kBAAkB;EAClB,oHAAqG;AACvG;;AAEA;EACE,iCAAiC;AACnC","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',\n    'Droid Sans', 'Helvetica Neue', sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;\n}\n\n@font-face {\n  font-family: 'Aeonik';\n  font-style: normal;\n  src: url('./fonts/Aeonik-Regular.woff') format('woff'), url('./fonts/Aeonik-Regular.woff2') format('woff2');\n}\n\n@font-face {\n  font-family: 'Aeonik-bold';\n  font-style: normal;\n  src: url('./fonts/Aeonik-Bold.woff') format('woff'), url('./fonts/Aeonik-Bold.woff2') format('woff2');\n}\n\nb {\n  font-family: 'Aeonik-Bold', serif;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

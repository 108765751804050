import * as React from 'react';
import SVGIcon, { SVGIconProps } from './../../ui-lib/SVGIcon/SVGIcon';
//import styled from '@emotion/styled';

export interface CalendarIconProps {
  color?: SVGIconProps['color'];
}

export const CalendarIcon: React.FunctionComponent<CalendarIconProps> = ({ color }) => {
  return (
    <SVGIcon {...{ width: 20, height: 20, viewBox: '0 0 20 20', color }}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7778 2.10526H16.6667V0.526316C16.6667 0.23564 16.4179 0 16.1111 0H15C14.6932 0 14.4444 0.23564 14.4444 0.526316V2.10526H5.55556V0.526316C5.55556 0.23564 5.30683 0 5 0H3.88889C3.58206 0 3.33333 0.23564 3.33333 0.526316V2.10526H2.22222C0.994923 2.10526 0 3.04782 0 4.21053V17.8947C0 19.0574 0.994923 20 2.22222 20H17.7778C19.0051 20 20 19.0574 20 17.8947V4.21053C20 3.04782 19.0051 2.10526 17.7778 2.10526ZM17.7778 17.8949H2.22222V6.31592H17.7778V17.8949ZM8.82223 14.7385C8.67491 14.7385 8.53415 14.6808 8.43334 14.579L6.06667 12.3159C5.85493 12.1112 5.85493 11.7837 6.06667 11.579L6.65556 11.0422C6.87536 10.8403 7.22466 10.8403 7.44445 11.0422L8.81112 12.3474L12.5445 8.81059C12.7642 8.60871 13.1135 8.60871 13.3333 8.81059L13.9333 9.36848C14.1464 9.57671 14.1464 9.90763 13.9333 10.1159L9.21112 14.579C9.1103 14.6808 8.96955 14.7385 8.82223 14.7385Z"
      />
    </SVGIcon>
  );
};

import * as React from 'react';
import { Typography } from '../../../ui-lib';
import styled from '@emotion/styled';
import success from './../../../images/success-mountain.png';
import { Theme } from '../../../theme';
import { flexCenterCenter } from '../../../styles/common';
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/setupContext';
import { FinanceAssessmentDecision, SubmitPayLaterResponse } from '../../../api/api.schema';

export interface PayWithTermsResponseProps {
  termDays: number;
  approvedSum: string;
  responseOption: SubmitPayLaterResponse['decision'];
}

export const Container = styled('div')<{ theme: Theme }>(({ theme }) => ({
  ...flexCenterCenter,
  flexDirection: 'column',
}));

export const PayWithTermsResponse: React.FunctionComponent<PayWithTermsResponseProps> = ({
  responseOption,
  termDays,
  approvedSum,
}) => {
  return (
    <Container>
      <Typography {...{}}>{`NET ${termDays} APPROVED FOR`}</Typography>
      <Typography {...{ marginTop: 8, marginBottom: 8, bolder: true, center: true }}>{approvedSum}</Typography>
      <Typography {...{ center: true }}>{`You're eligible to pay in up to ${termDays} days.`}</Typography>
      {responseOption === FinanceAssessmentDecision.Approved && <img {...{ src: success }} />}
    </Container>
  );
};

const Observed = observer(PayWithTermsResponse);

const WithStoreConnection = () => {
  const { checkout } = useStore();

  const { submitPayLaterResponse, amountToCharge } = checkout;
  if (!submitPayLaterResponse) {
    return null;
  }
  return (
    <Observed
      {...{
        termDays: checkout.payWithTermsDays,
        approvedSum: amountToCharge,
        responseOption: submitPayLaterResponse.decision,
      }}
    />
  );
};

export default observer(WithStoreConnection);

import StoreBase from './StoreBase';
import { getUrlSearchParams } from '../utils/route.utils';
import { computed, makeObservable, observable, runInAction, toJS } from 'mobx';
import { PlaidMetadata, PlaidSuccessResponse, Token } from '../types/Plaid';
import { cond } from 'lodash/fp';
import {
  deleteQualificationFile,
  getBuyerQualification,
  login,
  persistQualification,
  setToken,
  uploadQualificationFile,
} from '../api/api';
import { FileRejection } from 'react-dropzone';
import { QualificationType, UploadingFile } from '../sections/qualification/QualificationStepper';
import { QUALIFICATION_UPLOAD_FILE_SIZE_LIMIT_IN_BYTES } from '../sections/qualification/FileUploadDropzone';
import history from './../routing/history';
import { BusinessDetailsFormFields } from '../sections/qualification/BusinessDetailsForm';
import { BusinessInfo, BuyerQualification, OwnerInfo, PlaidData, SmallBusinessFormFields } from '../api/api.schema';
import jwtDecode from 'jwt-decode';
import { getQueryParams } from '../utils/query-params.util';
import { config } from '../config/config';
import * as FullStory from '@fullstory/browser';

export type QualificationStatus =
  | 'loading'
  | 'pending'
  | 'started'
  | 'completed'
  | 'inReview'
  | 'declined'
  | 'expired'
  | 'error';
export type QualificationStep = 'initial' | 'link-bank' | 'no-bank' | 'retail-buyer' | 'success';

export type FileStatus = 'uploading' | 'failed' | 'uploaded' | 'deleting';
export const INCLUDED_STATUS_STEP_ROUTES: [QualificationStatus, QualificationStep | undefined][] = [
  ['pending', 'initial'],
  ['started', 'link-bank'],
  ['started', 'no-bank'],
  ['started', 'retail-buyer'],
  ['started', 'success'],
  ['pending', undefined],
  ['inReview', undefined],
  ['declined', undefined],
  ['completed', undefined],
  ['error', undefined],
  ['expired', undefined],
];

function createStatusStepRoute(status: QualificationStatus, step?: QualificationStep) {
  return `/${status}${step ? `/${step}` : ''}`;
}

export const INCLUDED_STATUS_STEP_ROUTES_STRINGS: Set<string> = new Set(
  INCLUDED_STATUS_STEP_ROUTES.map((tuple) => {
    return createStatusStepRoute(tuple[0], tuple[1]);
  })
);

export default class QualificationStore extends StoreBase {
  public status: QualificationStatus = 'loading';
  public step: QualificationStep | undefined = 'initial';
  public filesToUpload: File[] = [];
  public uploadedFiles: File[] = [];
  public deletingFiles: File[] = [];
  public failedFileUploads: File[] = [];
  public fileFailureReasons: Map<string, string> = new Map<string, string>();
  public qualificationToken: string | null = '';
  public bankLinkToken: string | null = '';
  public buyerQualification: BuyerQualification | null = {} as BuyerQualification;
  public redirectUrl: string = '';
  public plaidToken: Token = '';
  public plaidMetadata: PlaidMetadata | undefined;
  public acceptedTermsAndconditionsAt: Date | undefined;
  public acceptedPrivacyPolicyAt: Date | undefined;
  public authorizedBankAccountDebitOnLatePaymentsAt: Date | undefined;

  constructor() {
    super();
    makeObservable(this, {
      status: observable,
      step: observable,
      filesToUpload: observable,
      failedFileUploads: observable,
      uploadedFiles: observable,
      buyerQualification: observable,
      qualificationFiles: computed,
    });
    this.qualificationInit();
  }

  public recordTermsAndConditionAccepted(): void {
    this.acceptedTermsAndconditionsAt = new Date();
  }

  public recordPrivacyPolicyAccepted(): void {
    this.acceptedPrivacyPolicyAt = new Date();
  }

  public recordAuthorizationToDebitdBankAccountOnLatePayments(): void {
    this.authorizedBankAccountDebitOnLatePaymentsAt = new Date();
  }

  private qualificationInit = () => {
    const { redirectUrl } = getQueryParams();
    this.redirectUrl = redirectUrl as string;
  };

  public login = async () => {
    this.setStatusStep('loading');
    if (!this.qualificationToken) {
      return;
    }
    try {
      const { data } = await login({
        qualificationKey: this.qualificationToken,
        emailAddress: null,
        retryCode: false,
        smsCode: false,
      });

      setToken(data.accessToken);
      const { email, merchantPublicId } = jwtDecode(data.accessToken) as { email: string; merchantPublicId: string };
      if (amplitude && email) {
        dataLayer.push({ event: 'setUserId', userId: email });
        amplitude.getInstance().setUserId(email);
        if (merchantPublicId) {
          amplitude.getInstance().setUserProperties({ 'Merchant ID': merchantPublicId });
        }
      }
    } catch (err) {
      this.setStatusStep('expired');
      console.error('err', err);
      throw err;
    }
  };

  private getBuyerQualification = async () => {
    const qualification = await getBuyerQualification();
    this.buyerQualification = qualification.data;
    this.setStatusStep(this.buyerQualification.status);
  };

  public fetchQualification = async () => {
    try {
      const queryParams = getUrlSearchParams();
      this.qualificationToken = queryParams.get('qid');
      await this.login()
        .then(this.getBuyerQualification)
        .catch((error) => {
          console.error('qualification error', error);
          this.setStatusStep('expired');
        });

      if (config.fullStoryOrgId) {
        FullStory.setUserVars({
          buyerId: this.buyerQualification?.buyerId,
          merchant: this.buyerQualification?.merchant?.name,
        });
      }
    } catch (err) {
      this.setStatusStep('error');
      console.error('err', err);
    }
  };

  public onBankLinkSuccess = async (token: Token, metadata: PlaidMetadata) => {
    this.setStatusStep('loading', 'link-bank');
    if (this.buyerQualification?.qualificationType === QualificationType.SMB) {
      this.recordAuthorizationToDebitdBankAccountOnLatePayments();
      this.setPlaidTokenAndData(token, metadata);
      this.setStatusStep('started', 'retail-buyer');
      return;
    }
    await this.persistPlaid({
      token,
      metadata,
    })
      .then(() => {
        this.setStatusStep('started', 'success');
      })
      .catch((e) => {
        this.setStatusStep('error');
      });
  };

  private persistPlaid = async (plaidResponse: PlaidSuccessResponse) => {
    if (!plaidResponse) {
      return;
    }
    try {
      await persistQualification({
        plaidData: {
          publicToken: plaidResponse.token,
          meta: toJS(plaidResponse.metadata),
          accountId: plaidResponse.metadata.account_id,
        },
        authorizedBankAccountDebitOnLatePaymentsAt: this.authorizedBankAccountDebitOnLatePaymentsAt,
        acceptedTermsAndConditionsAt: this.acceptedTermsAndconditionsAt,
        acceptedPrivacyPolicyAt: this.acceptedPrivacyPolicyAt,
      });
    } catch (err) {
      console.error('err', err);
      throw err;
    }
  };

  public onBusinessDetailsFormSubmitted = async (formFields: BusinessDetailsFormFields) => {
    await this.setStatusStep('loading');
    await this.persistPreApprovalInfo(formFields)
      .then(() => {
        this.setStatusStep('started', 'success');
      })
      .catch((e) => {
        this.setStatusStep('error');
        console.log(e);
      });
  };

  public onSmallBusinessFormSubmitted = async (formFields: SmallBusinessFormFields) => {
    if (!this.plaidMetadata) {
      this.setStatusStep('error');
      return;
    }
    this.recordPrivacyPolicyAccepted();
    this.recordTermsAndConditionAccepted();
    this.setStatusStep('loading');
    await this.persistSmbPreApprovalInfo(
      {
        token: this.plaidToken,
        metadata: this.plaidMetadata,
      },
      formFields
    )
      .then(() => {
        this.setStatusStep('started', 'success');
      })
      .catch((e) => {
        this.setStatusStep('error');
      });
  };

  private persistPreApprovalInfo = async (formFields: BusinessDetailsFormFields) => {
    try {
      const businessAddress = {
        state: formFields.state,
        city: formFields.city,
        zipCode: formFields.zipCode,
        address1: formFields.address1,
        address2: formFields.address2,
        country: formFields.country,
      };
      await persistQualification({
        preApprovalInfo: {
          businessName: formFields.businessName,
          ein: formFields.ein,
          dnb: formFields.dnb,
          businessAddress,
        },
      });
    } catch (err) {
      console.error('err', err);
      throw err;
    }
  };

  private persistSmbPreApprovalInfo = async (
    plaidResponse: PlaidSuccessResponse,
    formFields: SmallBusinessFormFields
  ) => {
    try {
      const businessInfo: BusinessInfo = {
        businessName: formFields.businessName,
        businessType: formFields.businessType,
        ein: formFields.ein,
        resaleNumber: parseInt(formFields.resaleNumber),
        dontHaveEin: formFields.ein === undefined,
        dontHaveResaleNumber: formFields.resaleNumber === undefined,
        monthlySales: formFields.monthlySales,
        website: formFields.website,
        mobilePhoneNumber: formFields.mobilePhoneNumber,
        businessAddress: formFields.businessAddress,
        addressLine2: formFields.address2,
        city: formFields.city,
        state: formFields.state,
        zipCode: formFields.zipCode,
        googleMapsLink: formFields.googleMapsLink,
      };
      const ownerInfo: OwnerInfo = {
        firstName: formFields.firstName,
        lastName: formFields.lastName,
        homeAddress: formFields.ownerAddress,
        city: formFields.ownerCity,
        state: formFields.ownerState,
      };
      const plaidData: PlaidData = {
        publicToken: plaidResponse.token,
        meta: toJS(plaidResponse.metadata),
        accountId: plaidResponse.metadata.account_id,
      };
      await persistQualification({
        plaidData,
        qualificationFormData: {
          businessInfo,
          ownerInfo,
        },
        authorizedBankAccountDebitOnLatePaymentsAt: this.authorizedBankAccountDebitOnLatePaymentsAt,
        acceptedPrivacyPolicyAt: this.acceptedPrivacyPolicyAt,
        acceptedTermsAndConditionsAt: this.acceptedTermsAndconditionsAt,
      });
    } catch (err) {
      console.error('err', err);
      throw err;
    }
  };

  async submitFullQualificationFlow() {
    try {
      await persistQualification({ status: 'inReview' });
    } catch (err) {
      throw err;
    }
  }

  public onBankLinkExit = () => {
    if (this.step !== 'success' && this.step !== 'no-bank') {
      this.setStatusStep('pending', 'initial');
    }
  };

  public onNoBankAccessClicked = async () => {
    this.setStatusStep('started', 'no-bank');
  };

  public onFileRejected = async (file: FileRejection) => {
    const errorMessage =
      file.errors[0]?.message.replaceAll(`${QUALIFICATION_UPLOAD_FILE_SIZE_LIMIT_IN_BYTES.toString()} bytes`, '10MB') ??
      'File is larger than 10MB';
    this.fileFailureReasons.set(file.file.name, errorMessage);
    this.filesToUpload.push(file.file);
    this.failedFileUploads.push(file.file);
  };

  public uploadQualificationFile = async (file: File) => {
    this.filesToUpload.push(file);
    uploadQualificationFile(file, this.qualificationToken || '')
      .then(() => this.uploadedFiles.push(file))
      .catch((e) => {
        const errorMessage =
          e.code === 'ECONNABORTED'
            ? 'File took too much time to upload. Try uploading a file with a size of maximum 10MB.'
            : e.response?.data?.message;
        this.fileFailureReasons.set(file.name, errorMessage);
        this.failedFileUploads.push(file);
      });
  };
  public deleteQualificationFile = async (fileName: string) => {
    const file = this.uploadedFiles.find((file) => file.name === fileName);
    if (!file) {
      this.fileFailureReasons.set(fileName, 'Failed to delete file, please contact support.');
      return;
    }
    this.deletingFiles.push(file);
    deleteQualificationFile(file, this.qualificationToken || '')
      .then(() => {
        const index = this.filesToUpload.indexOf(file, 0);
        if (index > -1) {
          this.filesToUpload.splice(index, 1);
        }
      })
      .catch((e) => {
        this.fileFailureReasons.set(file.name, 'Failed to delete file, please contact support.');
        this.failedFileUploads.push(file);
      });
  };

  get qualificationFiles(): UploadingFile[] {
    return this.filesToUpload.map((file) => ({
      name: file.name,
      status: this.getFileStatus(file),
      message: this.getFileMessage(file),
    }));
  }

  private getFileMessage(file: File): string | undefined {
    const failureMessage = this.fileFailureReasons.get(file.name) ?? 'Maximum file upload size is 10MB';
    return cond<File, string | undefined>([
      [(file) => this.uploadedFiles.includes(file), () => 'Uploaded successfully'],
      [(file) => this.failedFileUploads.includes(file), () => failureMessage],
      [() => true, () => undefined],
    ])(file);
  }

  private getFileStatus(file: File): FileStatus {
    return cond<File, FileStatus>([
      [(file) => this.deletingFiles.includes(file), () => 'deleting'],
      [(file) => this.uploadedFiles.includes(file), () => 'uploaded'],
      [(file) => this.failedFileUploads.includes(file), () => 'failed'],
      [() => true, () => 'uploading'],
    ])(file);
  }

  public tryAgain = async () => {
    this.start();
  };

  public setStatusStep = (status: QualificationStatus, step?: QualificationStep, excludeHistory?: boolean) => {
    const newPath = createStatusStepRoute(status, step);
    runInAction(() => {
      this.status = status;
      this.step = step;
    });
    if (!excludeHistory && INCLUDED_STATUS_STEP_ROUTES_STRINGS.has(newPath)) {
      history.push({ pathname: newPath, search: location.search });
    }
  };

  private setPlaidTokenAndData = (token: Token, plaidMetadata: PlaidMetadata) => {
    runInAction(() => {
      this.plaidToken = token;
      this.plaidMetadata = plaidMetadata;
    });
  };

  public start = async () => {
    try {
      this.buyerQualification?.merchant.qualificationWithPlaid
        ? this.setStatusStep('started', 'link-bank')
        : this.setStatusStep('started', 'no-bank');
    } catch (err) {
      console.error('err', err);
    }
  };
}

import styled from '@emotion/styled';
import { Theme } from '../../theme';

export const Table = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'grid',
  gridTemplateRows: 'min-content min-content 1fr',
}));

export const Row = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 100px 100px 100px',
  columnGap: theme.gutters.base * 2,
  margin: `${theme.gutters.base * 2}px 0`,
}));

export const Divider = styled('div')<{ theme: Theme }>(({ theme }) => ({
  backgroundColor: theme.palette.main.lightGrey,
  height: 2,
}));

export type TypographyVariant =
  | 'h1'
  | 'h1Bold'
  | 'h148Bold'
  | 'body1'
  | 'body1Bold'
  | 'caption'
  | 'captionBold'
  | 'button'
  | 'link'
  | 'linkBlue'
  | 'label'
  | 'body2'
  | 'labelBold'
  | 'smallHeader';

interface TypoMeta
  extends Pick<
    React.CSSProperties,
    | 'fontSize'
    | 'fontWeight'
    | 'lineHeight'
    | 'letterSpacing'
    | 'textTransform'
    | 'textDecoration'
    | 'textAlign'
    | 'color'
    | 'fontFamily'
  > {}

export interface Typography {
  imports: string[];
  fontFamily: string;
  meta: Record<TypographyVariant, TypoMeta>;
}

export const typography = (): Typography => ({
  imports: ['https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&display=swap'],
  fontFamily: `"Aeonik", sans-serif`,
  meta: {
    h1: {
      fontSize: 24,
      fontWeight: 400,
      lineHeight: 1.15,
    },
    h148Bold: {
      fontSize: 48,
      fontWeight: 700,
      lineHeight: 1.15,
      fontFamily: `"Aeonik-bold", sans-serif`,
    },
    h1Bold: {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: 1.15,
      fontFamily: `"Aeonik-bold", sans-serif`,
    },
    body1: {
      fontSize: 16,
      lineHeight: 1.2,
      fontWeight: 400,
    },
    body1Bold: {
      fontSize: 16,
      lineHeight: 1.2,
      fontWeight: 700,
      fontFamily: `"Aeonik-bold", sans-serif`,
    },
    button: {
      fontSize: 16,
      fontWeight: 700,
      textTransform: 'uppercase',
    },
    caption: {
      fontSize: 14,
      lineHeight: 1.15,
      fontWeight: 400,
    },
    captionBold: {
      fontSize: 14,
      lineHeight: 1.15,
      fontWeight: 700,
      fontFamily: `"Aeonik-bold", sans-serif`,
    },
    // ? From Tom's design
    link: {
      fontSize: 14,
      lineHeight: '22px',
      fontWeight: 400,
    },
    linkBlue: {
      fontSize: 14,
      lineHeight: '22px',
      fontWeight: 400,
      color: 'primary',
    },
    label: {
      fontSize: 12,
      lineHeight: 1.2,
      fontWeight: 400,
    },
    body2: {
      fontSize: 24,
      lineHeight: 1.2,
    },
    labelBold: {
      fontSize: 12,
      lineHeight: 1.2,
      fontWeight: 700,
      fontFamily: `"Aeonik-bold", sans-serif`,
    },
    smallHeader: {
      fontSize: 32,
      lineHeight: '39px',
      fontWeight: 700,
    },
  },
});

import { Box, InputAdornment } from '@material-ui/core';
import { Typography } from '../../../../../ui-lib';
import { Theme } from '../../../../../theme';
import styled from '@emotion/styled';
import React, { VFC } from 'react';
import { InfoIcon } from '../../../../../icons';
import { StyledTooltip } from '../../../../Components/StyledTooltip';

export const Container = styled('div')<{ theme: Theme }>(({ theme }) => ({
  backgroundColor: theme.palette.main.purpleGray,
}));

export const SubTitle = styled(Typography)<{ theme: Theme }>(({ theme }) => ({
  color: theme.palette.main.gray,
}));

export const Body = styled(Box)({
  display: 'flex',
  width: '840px',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: '40px auto 0 auto',
  gap: '40px',
});

export const Logo = styled('img')({
  maxWidth: 129,
  maxHeight: 100,
  marginTop: 48,
});

const IconContainer = styled(Box)({
  paddingBottom: 7,
  cursor: 'pointer',
});

interface InfoAdornmentProps {
  lines: string[];
}

export const InfoAdornment: VFC<InfoAdornmentProps> = ({ lines }) => (
  <InputAdornment position="end">
    <StyledTooltip
      arrow
      placement={'right'}
      title={
        <Box>
          {lines.map((line, index) => (
            <Typography key={index} variant={'caption'}>
              {line}
            </Typography>
          ))}
        </Box>
      }
    >
      <IconContainer>
        <InfoIcon width={15} />
      </IconContainer>
    </StyledTooltip>
  </InputAdornment>
);

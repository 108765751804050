import styled from '@emotion/styled';
import { Theme } from '../../theme';
import React from 'react';
import { QualificationButton } from '../../sections/qualification/QualificationButton';
import {
  Image,
  QualificationColumn,
  QualificationHeading,
  QualificationMobileHeading,
  QualificationStepContainer,
  QualificationStepContent,
  QualificationStepDescription,
} from '../../sections/qualification/common';
import coverTopBarSvg from '../../images/coverNavigationBar.png';
import defaultCoverPhoto from '../../images/defaultCoverPhoto.png';
import { Typography } from '../../ui-lib';
import { withHttps } from '../../utils/string.utils';
import { flexStartCenter } from '../../styles/common';
import { useMobile } from '../../utils/mobile.hook';
import { SeccessGreenIcon } from '../../icons/Success/Success';
import { PoweredByBalance } from '../../sections/qualification/PoweredByBalance';

export const CoverPhoto = styled('div')<{ theme: Theme }>(({ theme }) => ({
  backgroundColor: 'transparent',
  backgroundSize: 'contain',
  backgroundPosition: 'center center',
  borderRadius: theme.radius.card,
  boxSizing: 'border-box',
  display: 'block',
  height: 400,
  width: 640,
  backgroundPositionX: 1,
  backgroundPositionY: 64,
  filter: 'drop-shadow(0px 2px 16px rgba(0, 0, 0, 0.1))',
  backgroundRepeat: 'no-repeat',
}));

export const SiteDomainText = styled(Typography)<{ theme: Theme }>(() => ({
  fontSize: 12,
  position: 'absolute',
  top: 24,
  left: '45%',
  color: 'rgba(155, 155, 155, 1)',
}));

export const CompanyName = styled(Typography)<{ theme: Theme; withBorder?: boolean }>((withBorder) => ({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: '24px',
  lineHeight: '29px',
  display: 'flex',
  alignItems: 'center',
  height: '72px',
  borderBottom: withBorder ? '1px solid lightgray' : 'none',
  width: '100%',
}));

export interface QualificationGettingStartedProps {
  logo?: string | undefined;
  buyerName?: string | undefined;
  title: string | React.ReactNode;
  description: string | React.ReactNode;
  coverPhotoUrl?: string;
  siteDomain?: string;
  onStarted?: VoidFunction;
}

export const CoverPhotoHeader = styled('img')<{ theme: Theme }>(({ theme }) => ({
  borderTopRightRadius: theme.radius.card,
  borderTopLeftRadius: theme.radius.card,
}));

export const VendorLink = styled('a')<{ theme: Theme }>(() => ({
  ...flexStartCenter,
  cursor: 'pointer',
  gap: 20,
  textDecoration: 'none',
  color: 'unset',
}));

export const InfoPage = ({
  title,
  description,
  siteDomain,
  coverPhotoUrl,
  onStarted,
  buyerName,
  logo,
}: QualificationGettingStartedProps) => {
  const isMobile = useMobile();

  return (
    <QualificationStepContainer>
      {!isMobile && (
        <CoverPhoto
          className="fade-in"
          style={
            coverPhotoUrl
              ? { backgroundImage: `url(${coverPhotoUrl})` }
              : {
                  backgroundImage: `url(${defaultCoverPhoto})`,
                  backgroundPosition: 'right 50% top 25px',
                }
          }
        >
          {siteDomain && (
            <VendorLink
              target="_blank"
              href={siteDomain ? withHttps(siteDomain) : ''}
              data-analytics-id="qualification-coverPhoto-vendorSite"
            >
              <SiteDomainText {...{ variant: 'body1', center: true }}>{siteDomain}</SiteDomainText>
            </VendorLink>
          )}
          <CoverPhotoHeader {...{ src: coverTopBarSvg }} />
        </CoverPhoto>
      )}
      <QualificationStepContent>
        <QualificationColumn>
          {isMobile && <CompanyName withBorder>{logo ? <Image src={logo} /> : buyerName}</CompanyName>}
          {isMobile && !onStarted && <SeccessGreenIcon />}
          {isMobile ? (
            <QualificationMobileHeading>{title}</QualificationMobileHeading>
          ) : (
            <QualificationHeading>{title}</QualificationHeading>
          )}
          <QualificationStepDescription>{description}</QualificationStepDescription>
          {onStarted && (
            <QualificationButton isTransparent onClick={onStarted} data-analytics-id="qualification-start">
              {isMobile ? `CONTINUE` : `Continue`}
            </QualificationButton>
          )}
          {isMobile && !onStarted && <PoweredByBalance />}
        </QualificationColumn>
      </QualificationStepContent>
    </QualificationStepContainer>
  );
};

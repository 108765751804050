import { observer } from 'mobx-react';
import * as React from 'react';
import { useStore } from '../../../stores/setupContext';
import { Button } from '../../../ui-lib';
import styled from '@emotion/styled';
import { Theme } from '../../../theme';
import CheckoutStore from '../../../stores/CheckoutStore';
import { SlashIcon } from '../../../icons';
import { PoweredBy } from '../../common/PoweredBy';

export interface ButtonGroupProps {
  isNextDisabled: boolean;
  nextButton: CheckoutStore['nextButton'];
  isShowNextButton: CheckoutStore['isShowNextButton'];
  backgroundColor?: string;
}

export const ButtonWrap = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  alignContent: 'center',
  '& button': {
    marginBottom: theme.gutters.base * 4,
    maxWidth: 300,
  },
}));
export const ButtonWithIcon = styled(Button)<{ theme: Theme; disabled: boolean }>(({ theme, disabled }) => ({
  ...(!disabled && {
    '&:hover svg': {
      transition: 'all ease-in 300ms',
      transform: 'rotate(180deg)',
    },
  }),
  borderRadius: 8,
  backgroundColor: disabled ? theme.palette.main.disabled : theme.palette.main.primary ?? 'initial',
}));

export const ButtonGroup: React.FunctionComponent<ButtonGroupProps> = ({
  isNextDisabled,
  isShowNextButton,
  nextButton,
  backgroundColor,
}) => {
  return (
    <ButtonWrap>
      {nextButton.callback && isShowNextButton && (
        <ButtonWithIcon
          {...{
            fullWidth: true,
            onClick: nextButton.callback,
            disabled: isNextDisabled,
            leftIcon: backgroundColor ? undefined : <SlashIcon {...{ color: 'brand' }} />,
          }}
        >
          {nextButton.text}
        </ButtonWithIcon>
      )}
      <PoweredBy linkParams="?utm_source=Product&utm_medium=Checkout&utm_campaign=referral" />
    </ButtonWrap>
  );
};

const Observed = observer(ButtonGroup);

const WithStoreConnection = () => {
  const { checkout } = useStore();

  const { isNextDisabled, nextButton, customNextButton, isShowNextButton, loading, colorOverrides } = checkout;

  return (
    <Observed
      {...{
        nextButton: customNextButton || nextButton,
        isNextDisabled: isNextDisabled || loading,
        isShowNextButton,
        backgroundColor: colorOverrides?.primary,
      }}
    />
  );
};

export default observer(WithStoreConnection);

import * as React from 'react';

import styled from '@emotion/styled';
import { Theme } from '../../theme';

export interface ButtonProps {
  /** Should the button get a disabled design and functionallity?
   * @default false
   */
  disabled?: boolean;
  /**
   * What's the type
   * @default 'submit'
   */
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
  onClick?: (e: any) => void;
  variant?: 'contained' | 'text' | 'outline' | 'action';
  leftIcon?: React.ReactElement;
  rightIcon?: React.ReactElement;
  children: React.ReactNode;
  color?: keyof Theme['palette'];
  styleOverride?: React.CSSProperties;
  form?: string;

  /** Would you like the button to take up 100% of the possible width?
   * @default false
   */
  fullWidth?: boolean;
}

const StyledButton = styled('button')<
  {
    theme?: Theme;
    color?: ButtonProps['color'];
  } & Pick<ButtonProps, 'color' | 'styleOverride' | 'variant' | 'disabled' | 'fullWidth'>
>(
  ({ theme, fullWidth = false, disabled }) => ({
    ...(!disabled && {
      '&:hover': {},
    }),
    fontFamily: theme.typography.fontFamily,
    margin: 0,
    boxSizing: 'border-box',
    display: 'grid',
    gridAutoFlow: 'column',
    gridColumnGap: theme.gutters.base * 2,
    whiteSpace: 'nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    textShadow: '0 -1px 0 rgba(0, 0, 0, 0.12)',
    lineHeight: 1.2,
    width: fullWidth ? '100%' : 'auto',
    borderRadius: theme.gutters.base / 2,
    fontSize: theme.typography.meta.body1.fontSize,
    padding: `${theme.gutters.base * 2}px ${theme.gutters.base * 3}px`,
    cursor: 'pointer',
    transition: 'all 0.2s ease-in',
    '&:focus': {
      outline: 'none',
    },
  }),
  ({ theme, disabled, variant = 'contained', color }) => ({
    ...{},
    ...(variant === 'contained' && {
      border: 'none',
      backgroundColor: theme.palette.text.main,
      color: theme.palette.text.contrast,
      ...(disabled && {
        color: theme.palette.blacks.zeta,
        opacity: 0.3,
        '&:hover': {
          opacity: 0.3,
        },
      }),
    }),
    ...(variant === 'text' && {
      border: 'none',
      backgroundColor: 'transparent',
      backgroundImage: 'none',
      boxShadow: 'none',
      color: theme.palette.text.main,
      padding: 0,
      fontWeight: theme.typography.meta.body1.fontWeight,
      ...(disabled && {
        color: theme.palette.blacks.zeta,
        '&:hover': {
          opacity: 1,
        },
      }),
      ...(!disabled && {
        color: theme.palette.main.primary,
        '&:hover': {
          opacity: 0.7,
          backgroundColor: 'transparent',
          color: theme.palette.main.primary,
        },
      }),
    }),
  }),
  ({ disabled }) =>
    disabled && {
      cursor: 'not-allowed',
    }
);

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, leftIcon, rightIcon, ...rest }, ref) => {
    return (
      <StyledButton {...rest} {...{ ref }}>
        {leftIcon && React.cloneElement(leftIcon)}
        {children}
        {rightIcon && React.cloneElement(rightIcon)}
      </StyledButton>
    );
  }
);

export default Button;

import styled from '@emotion/styled';
import { flexCenterCenter } from '../../styles/common';
import React, { PropsWithChildren } from 'react';
import { Theme } from '../../theme';
import { device } from './../../utils/devices/devices.utils';

export const SectionWrapper = styled('section')<{ theme: Theme }>(({ theme }) => ({
  ...flexCenterCenter,
  maxWidth: 1200,
  willChange: 'transform, opacity',
  height: 'inherit',
  [`@media ${device.mobileL}`]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
}));

export const QualificationSectionWrapper = ({ children }: PropsWithChildren<{}>) => {
  return <SectionWrapper>{children}</SectionWrapper>;
};

import * as React from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/setupContext';

import CreditCardManagement from '../../../components/CreditCardManagement/CreditCardManagement';

const Observed = observer(CreditCardManagement);

const WithStoreConnection = () => {
  const { checkout } = useStore();

  const {
    stripeClientSecret,
    generateClientSecret,
    setSetupIntent,
    getListOfStripeCards,
    creditCardList,
    creditCardWhatToShow,
    setCreditCardWhatToShow,
    setSelectedCreditCard,
    selectedCreditCard,
    setLoading,
    loading: isLoading,
    deleteCreditCard,
    notification,
    handleStripeError,
    creditCard,
  } = checkout;

  const { showCardForm, setShowCardForm } = creditCard;

  return (
    <Observed
      {...{
        clientSecret: stripeClientSecret,
        getListOfStripeCards,
        onSuccess: setSetupIntent,
        creditCardList,
        onError: handleStripeError,
        whatToShow: creditCardWhatToShow,
        setCreditCardWhatToShow,
        setSelectedCreditCard,
        selectedCreditCard,
        deleteCreditCard,
        isLoading,
        setLoading,
        notification,
        generateClientSecret,
        showCardForm,
        setShowCardForm,
      }}
    />
  );
};

export default observer(WithStoreConnection);

import { useEffect, useState } from 'react';
import {
  createVendor as createVendorRequest,
  updateVendor as updateVendorRequest,
  setOnboardingToken,
} from '../../api';
import { getQueryParams } from '../../../../../utils/query-params.util';
import { PostVendor, UpdateVendor } from './vendor-onboarding.types';
import history from '../../../../../routing/history';
import { SUCCESS_PAGE, UNAUTHORIZED_ERROR_PAGE } from '../../components/Form/VendorOnboardingLayout';
import { AxiosError, AxiosResponse } from 'axios';
import { HttpStatus } from '../../../../../utils/HttpStatusCode';

const VENDOR_ALREADY_EXIST_ERROR = 'This email already exists';

type Error = string | undefined;
type VendorHook = () => {
  createVendor: PostVendor;
  updateVendor: UpdateVendor;
  isLoading: boolean;
  error: Error;
};

export const useVendorOnboarding: VendorHook = () => {
  const { token } = getQueryParams();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error>();
  const resetError = () => setError(undefined);

  useEffect(() => {
    if (token) {
      setOnboardingToken(token as string);
    }
  }, []);

  const applyAction = async (action: () => Promise<AxiosResponse>): Promise<void> => {
    try {
      resetError();
      setIsLoading(true);
      await action();
      onSuccess();
    } catch (e) {
      onError(e);
    } finally {
      setIsLoading(false);
    }
  };

  const createVendor: PostVendor = async (data) => {
    const action = async () => {
      return createVendorRequest(data);
    };
    return applyAction(action);
  };

  const updateVendor: UpdateVendor = async (data) => {
    const action = async () => {
      const { vendorPublicId } = getQueryParams();
      return updateVendorRequest(vendorPublicId as string, data);
    };
    return applyAction(action);
  };

  const onSuccess = () => {
    history.push({ pathname: SUCCESS_PAGE, search: location.search });
  };

  const onError = (error?: AxiosError) => {
    if (error?.response?.status === HttpStatus.CONFLICT) {
      setError(VENDOR_ALREADY_EXIST_ERROR);
    } else if (error?.response?.status === HttpStatus.UNAUTHORIZED) {
      history.push({ pathname: UNAUTHORIZED_ERROR_PAGE, search: location.search });
    } else {
      setError(error?.response?.data?.message);
    }
  };

  return { createVendor, updateVendor, isLoading, error };
};

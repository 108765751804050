import * as Yup from 'yup';
import { get } from 'lodash/fp';
import { getCountryConfig } from '../countries/countries.utils';

export const getYupTestValidationForFromCountryConfig = (fieldName: string): Yup.TestConfig => {
  return {
    name: `${fieldName}-custom-validation`,
    test: function (value) {
      // @ts-ignore
      // This is how to access values from all schemas according to this:
      // https://github.com/jquense/yup/pull/556#issuecomment-641988687
      const { value: formValues } = this.from[this.from.length - 1];
      const field = get(fieldName, getCountryConfig(formValues.country));

      // field does not exist no validation needed
      if (!field) {
        return true;
      }

      // required config (default is required)
      if (!get(fieldName, formValues) && field.isRequired !== false) {
        return this.createError({ message: 'Required' });
      }

      // custom regex validation
      const { validation } = field;
      if (validation?.value) {
        const regex = new RegExp(validation.value);
        if (!regex.test(value as string)) {
          return this.createError({ message: validation.message });
        }
      }

      return true;
    },
  };
};

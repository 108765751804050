import React from 'react';
import { QualificationStepContainer } from '../../sections/qualification/common';

export const LoadingOverlay = () => {
  return (
    <QualificationStepContainer>
      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </QualificationStepContainer>
  );
};

import LogRocket from 'logrocket';
import { paymentOptions } from '../sections/checkout/HowToPaySelection/HowToPaySelection';
import { SetupIntent, StripeError } from '@stripe/stripe-js';
import { isUri } from 'valid-url';
import { PlaidMetadata, PlaidSuccessResponse, Token } from '../types/Plaid';
import {
  buyerQualification,
  confirm,
  deleteBankAccount,
  deleteCreditCard,
  generateClientSecret,
  generateLinkTokenForPlaid,
  getBankAccounts,
  getCheckoutData,
  getListOfStripeCards,
  getPaymentMethods,
  getSettings,
  login,
  persistPlaid,
  setToken,
  setWireTransfer,
  submitElectroniceQuote,
  submitPayLater,
} from '../api/api';
import { action, computed, makeObservable, observable, reaction, toJS } from 'mobx';
import { add, addMonths, addYears, format, parseISO } from 'date-fns';
import qs from 'qs';
import StoreBase from './StoreBase';
import { PaymentMethodSummary } from '../sections/checkout/Review/Review';
import { Events, identify, Services, track } from '../api/userTracking';
import { config, environment } from '../config/config';
import { NotificationProps } from '../components/Notification/Notification';
import CreditCardModel from './models/CreditCard';
import {
  AddressSchema,
  BuyerQualificationStatus,
  BuyerSettings,
  ColorOverrides,
  FinanceAssessmentDecision,
  FinanceAssessmentReason,
  GetBankAccountsResponse,
  GetListOfStripeCardsResponse,
  GetPaymentMethodsResponse,
  GetTransactionDataResponse,
  LineRequest,
  MilestoneRequest,
  PaymentMethodName,
  RecurringPayment,
  SetWireTransferResponse,
  SubmitPayLaterResponse,
  SubmitPaymentRequest,
} from '../api/api.schema';

export type Phase =
  | 'howToPaySelection'
  | 'creditCardManagement'
  | 'bankAccountManagement'
  | 'plaid'
  | 'linkBank'
  | 'payLater'
  | 'summary'
  | 'electronicQuote'
  | 'afterPay'
  | 'afterInvoiceCreated'
  | 'afterQuoteCreated'
  | 'payWithTermsResponse';
export type PaymentTerm = 'payNow' | 'payWithTerms' | 'electronicQuote';
export type FundingInstrument = 'bankTransfer' | 'cardPayment' | 'invoice';

export type HowToPay = 'withTerms' | 'withCard' | 'withBankTransfer';

export type CheckoutMode = 'iframe' | 'standalone';
export type RecurringPaymentTimeUnit = 'monthly' | 'yearly' | undefined;

export const availablePaymentOptions: Record<PaymentMethodName, { key: HowToPay | '' }> = {
  creditCard: { key: 'withCard' },
  check: { key: '' },
  bank: { key: 'withBankTransfer' },
  payWithTerms: { key: 'withTerms' },
  invoice: { key: '' },
};

export const formatPrice =
  (currency: string = 'USD') =>
  (price: string | number) => {
    return Intl.NumberFormat('en-US', {
      currency,
      minimumFractionDigits: 2,
      style: 'currency',
    }).format(Number(price));
  };

export type CreditCardMeta = { name: string; mask: string; id: string; exp: string };
export type CreditCardMode = 'full' | 'form';
export type BankAccountMeta = {
  bankName: string;
  /** account name */
  name: string;
  mask: string;
  type: string;
  accountId: string;
  /**
   * itemId refers to the id we got after persisting
   */
  itemId: string;
};

class CheckoutStore extends StoreBase {
  public checkoutType: 'checkout' | 'quote' =
    (window.xprops?.type || qs.parse(window.location.search.slice(1)).type) ?? 'checkout';

  public currentPhase: Phase = this.checkoutType === 'checkout' ? 'howToPaySelection' : 'electronicQuote';
  public selectedPaymentTerm: PaymentTerm | null = null;
  public selectedHowToPay: HowToPay | null = null;
  public selectedFundingInstrument: FundingInstrument | null = null;
  /**
   * The required token for plaid to work
   */
  public linkToken: string = '';
  public loading: boolean = true;
  public checkoutToken: string = (window.xprops?.checkoutToken || qs.parse(window.location.search.slice(1)).cid) ?? '';
  public vendorId: string = this.checkoutToken?.split('-')[0];
  public checkoutTokens: string[] = window.xprops?.checkoutTokens ?? [];
  public isAuth: boolean = window.xprops?.isAuth ?? qs.parse(window.location.search.slice(1)).isAuth === 'true';
  private successRedirectURL: string = String(qs.parse(window.location.search.slice(1))?.redirectURL);
  private standaloneBackURL: string = String(qs.parse(window.location.search.slice(1))?.backURL);
  private serverRedirectURL: string | undefined = undefined;
  public checkoutData: GetTransactionDataResponse | null = null;
  private allCheckoutData: GetTransactionDataResponse[] | null = null;
  public paymentMethodsData: GetPaymentMethodsResponse | null = null;
  public stripeClientSecret: string = '';
  public creditCardWhatToShow: 'form' | 'list' = 'list';
  public creditCardMode: CreditCardMode = 'full';
  public selectedCreditCard: CreditCardMeta | null = null;
  public selectedBankAccount: BankAccountMeta | null = null;
  public electronicQuoteState: {
    shouldContactSales: boolean | null;
    msg: string;
  } = {
    shouldContactSales: null,
    msg: '',
  };
  public notification: NotificationProps | null = null;
  public submitPayLaterResponse: SubmitPayLaterResponse | null = null;
  public creditCard: CreditCardModel = new CreditCardModel(this);
  public isBuyerSettingsLoading: boolean = false;
  public colorOverrides: ColorOverrides | null = null;

  private plaidResponse: PlaidSuccessResponse | null = null;
  private setupIntentResponse: SetupIntent | null = null;
  private listOfCardsResponse: GetListOfStripeCardsResponse['data'] | null = null;
  private bankAccountsResponse: GetBankAccountsResponse | null = null;
  private wireTransferResponse: SetWireTransferResponse | null = null;
  private accessToken: string = '';
  private featureFlagsRes: BuyerSettings = {};

  // ? Using arrow functions here is mandatory.
  constructor() {
    super();

    makeObservable(this, {
      // Observables
      currentPhase: observable,
      loading: observable,
      selectedPaymentTerm: observable,
      selectedFundingInstrument: observable,
      linkToken: observable,
      paymentMethodsData: observable,
      checkoutToken: observable,
      checkoutTokens: observable,
      isAuth: observable,
      // @ts-ignore
      plaidResponse: observable,
      checkoutData: observable,
      allCheckoutData: observable,
      setupIntentResponse: observable,
      listOfCardsResponse: observable,
      stripeClientSecret: observable,
      creditCardWhatToShow: observable,
      creditCardMode: observable,
      selectedCreditCard: observable,
      electronicQuoteState: observable,
      submitPayLaterResponse: observable,
      notification: observable,
      bankAccountsResponse: observable,
      selectedBankAccount: observable,
      selectedHowToPay: observable,
      checkoutType: observable,
      customNextButton: observable,
      colorOverrides: observable,

      // Computed
      nextPhase: computed,
      previousPhase: computed,
      amountToCharge: computed,
      sellerName: computed,
      isNextDisabled: computed,
      isShowBackButton: computed,
      paymentMethodSummary: computed,
      transactions: computed,
      totalShipping: computed,
      paymentMethodNote: computed,
      nextButton: computed,
      previousButton: computed,
      hasSelectedCard: computed,
      debitDate: computed,
      payWithTermsStatus: computed,
      payWithTermsNotification: computed,
      checkoutStatus: computed,
      bankAccountsList: computed,
      setupIntentPaymentMethod: computed,
      shippingDetails: computed,
      hasListOfBankAccounts: computed,
      merchantLogo: computed,
      hideBackOnFirstScreen: computed,
      hasListOfCreditCards: computed,
      isPayingWithTerms: computed,
      shouldShowPayWithTerms: computed,
      shouldPayWithTermsBeDisabled: computed,
      checkoutMode: computed,
      payWithTermsLegal: computed,
      payWithTermsDays: computed,
      howWouldYouLikeToPayTitle: computed,
      hasInstallments: computed,
      howToPayOptions: computed,
      buyerEmail: computed,
      buyerBusinessName: computed,
      buyerName: computed,
      buyerPhone: computed,
      allowedPaymentMethods: computed,
      shouldShowInvoice: computed,
      createdDate: computed,
      notes: computed,
      featureFlags: computed,

      // Actions
      start: action,
      setLoading: action,
      setPhase: action,
      setSelectedPaymentTerm: action,
      setSelectedFundingInstrument: action,
      generateLinkTokenForPlaid: action,
      handlePlaidSuccess: action,
      handlePlaidExit: action,
      setSetupIntent: action,
      setCreditCardWhatToShow: action,
      setSelectedCreditCard: action,
      setCreditCardMode: action,
      setSelectedHowToPay: action,
      openCardManagement: action,
      openBankManagement: action,
      payWithInvoice: action,
      handleStripeError: action,
      setNotification: action,
      clearNotification: action,
      clearSelectedCreditCard: action,
      clearSelectedBankAccount: action,
      onAddBankClick: action,
      overrideNextButton: action,

      // XHR -
      login: action,
      getPaymentMethods: action,
      getCheckoutData: action,
      generateClientSecret: action,
      getListOfStripeCards: action,
      submitPayLater: action,
      persistPlaid: action,
      getBankAccounts: action,
      deleteBankAccount: action,
      deleteCreditCard: action,
      getBuyerSettings: action,
    });

    reaction(
      () => this.checkoutStatus,
      (newStatus) => {
        if (newStatus === 'closed' || newStatus === 'auth') {
          this.setPhase('afterPay');
        }
      }
    );
  }

  get payWithTermsStatus(): SubmitPayLaterResponse['decision'] | null {
    return this.submitPayLaterResponse?.decision ?? null;
  }

  get payWithTermsDeclinedReason(): SubmitPayLaterResponse['reason'] | null {
    return this.submitPayLaterResponse?.reason ?? null;
  }

  get checkoutStatus(): GetTransactionDataResponse['status'] | null {
    return this.checkoutData?.status ?? null;
  }

  get autoBilled(): GetTransactionDataResponse['autoBilled'] | undefined | null {
    return this.checkoutData?.autoBilled;
  }

  get setupIntentPaymentMethod(): string | null {
    return this.setupIntentResponse?.payment_method ?? null;
  }

  get shippingDetails(): AddressSchema | null {
    const address = this.checkoutData?.shippingAddress ?? this.checkoutData?.billingAddress ?? null;

    if (!address) {
      return null;
    }
    return address;
  }

  get howToPayOptions(): typeof paymentOptions {
    const options = paymentOptions;

    if (!this.checkoutData || !this.allowedPaymentMethods) {
      return [];
    }

    if (this.hasInstallments) {
      return this.isPayingWithTerms
        ? options.filter((option) => option.key === 'withBankTransfer' || option.key === 'withCard')
        : options.filter((option) => option.key === 'withTerms');
    } else {
      return paymentOptions.filter((paymentOption) => {
        return this.isPayingWithTerms
          ? this.allowedTermsPaymentMethods[paymentOption.paymentMethodKey]
          : this.allowedPaymentMethods[paymentOption.paymentMethodKey];
      });
    }
  }

  get installments(): number {
    return this.checkoutData?.plan?.installments ?? 1;
  }

  get hasInstallments(): boolean {
    const installments = this.checkoutData?.plan?.installments;
    return Boolean(installments && installments > 1);
  }

  get milestones(): MilestoneRequest[] {
    return this.checkoutData?.plan?.milestones ?? [];
  }

  get hasMilestones(): boolean {
    return Boolean(this.checkoutData?.plan?.milestones);
  }

  get recurringPayment(): RecurringPayment | undefined {
    return this.checkoutData?.plan?.recurringPayment;
  }

  get hasRecurringPayment(): boolean {
    return Boolean(this.checkoutData?.plan?.recurringPayment);
  }

  get recurringPaymentTimeUnit(): RecurringPaymentTimeUnit {
    return this.checkoutData?.plan?.recurringPayment?.timeUnit;
  }

  get recurringPaymentEnd(): Date | undefined {
    const recurringPayment = this.checkoutData?.plan?.recurringPayment;
    return recurringPayment &&
      recurringPayment?.startDate &&
      recurringPayment?.timeUnit &&
      recurringPayment?.numberOfTotalRepeats
      ? recurringPayment.timeUnit === 'monthly'
        ? addMonths(new Date(recurringPayment.startDate), recurringPayment.numberOfTotalRepeats)
        : addYears(new Date(recurringPayment.startDate), recurringPayment.numberOfTotalRepeats)
      : undefined;
  }

  get payWithTermsLegal(): string {
    return '';
  }

  get payWithTermsDays(): number {
    return this.checkoutData?.financingConfig?.financingNetDays ?? 30;
  }

  get howWouldYouLikeToPayTitle(): string {
    return this.isPayingWithTerms && !this.hasInstallments
      ? `In ${this.payWithTermsDays} days, how would you like to pay?`
      : 'How would you like to pay?';
  }

  get checkoutMode(): CheckoutMode {
    return window.xprops === undefined ? 'standalone' : 'iframe';
  }

  get shouldShowInvoice(): boolean {
    if (!this.paymentMethodsData) {
      return false;
    }

    return this.isPayingWithTerms
      ? Object.keys(this.allowedTermsPaymentMethods).includes('invoice')
      : Object.keys(this.allowedPaymentMethods).includes('invoice');
  }

  get payWithTermsNotification(): NotificationProps | null {
    if (!this.payWithTermsStatus) {
      return null;
    }

    const options: Record<SubmitPayLaterResponse['decision'], string> = {
      declined:
        this.payWithTermsDeclinedReason === FinanceAssessmentReason.FinanceAssessmentFailed
          ? 'Paying on terms is currently disabled. Please proceed to pay without terms'
          : 'Unfortunately, we are not able to approve your payment for terms, please choose a different payment method.',
      approved: this.hasInstallments
        ? `You're approved for ${this.installments} installments`
        : `You're approved for Net ${this.payWithTermsDays || 30}`,
    };

    const types: Record<SubmitPayLaterResponse['decision'], NotificationProps['type']> = {
      approved: 'success',
      declined: 'error',
    };

    return {
      type: types[this.payWithTermsStatus],
      text: options[this.payWithTermsStatus],
    };
  }

  get merchantLogo(): string {
    return window?.xprops?.logoURL ?? '';
  }

  get hideBackOnFirstScreen(): boolean {
    return window?.xprops?.hideBackOnFirstScreen ?? false;
  }

  get transactions(): LineRequest[] | null {
    if (!this.checkoutData) {
      return null;
    }

    return this.checkoutData.lines;
  }

  get totalShipping(): number {
    if (!this.checkoutData) {
      return 0;
    }

    return this.checkoutData.totalShipping;
  }

  get currency(): string {
    if (!this.checkoutData) {
      return '';
    }

    return this.checkoutData.currency;
  }

  get creditCardList(): CreditCardMeta[] {
    if (!this.listOfCardsResponse) {
      return [];
    }
    return this.listOfCardsResponse.existingMethods.map((method) => ({
      mask: method.card.last4,
      name: method.card.brand,
      id: method.id,
      exp: `${method.card.exp_month}/${method.card.exp_year}`,
    }));
  }

  get bankAccountsList(): BankAccountMeta[] {
    if (!this.bankAccountsResponse) {
      return [];
    }
    return this.bankAccountsResponse.map((bankAccount) => ({
      bankName: bankAccount.institution.name,
      mask: bankAccount.account.mask,
      name: bankAccount.account.name,
      accountId: bankAccount.account.id,
      itemId: bankAccount.itemId,
      type: bankAccount.account.type,
    }));
  }

  get paymentMethodNote(): string {
    const selectedPaymentTerm = this.selectedPaymentTerm;
    const selectedFundingInstrument = this.selectedFundingInstrument;
    if (!selectedPaymentTerm || !selectedFundingInstrument) {
      return '';
    }

    const debitDate = this.debitDate;
    const today = this.today;
    const hasInstallments = this.hasInstallments;
    const installments = this.installments;

    const optionsByFundingInstrument: Record<FundingInstrument, string> = {
      get bankTransfer() {
        return selectedPaymentTerm === 'payNow'
          ? 'Your bank account will be auto debited in the next business day.'
          : hasInstallments
          ? `You’ll be paying in ${installments} monthly installments, starting on ${today}`
          : `Your account will be auto debited on ${debitDate}.`;
      },
      get cardPayment() {
        return selectedPaymentTerm === 'payNow'
          ? `Your card will be charged`
          : `Your card will be charged on ${debitDate}.`;
      },

      get invoice() {
        return selectedPaymentTerm === 'payNow'
          ? `Make sure to complete the payment as soon as possible.`
          : `Make sure to complete payments until ${debitDate}.`;
      },
    };

    return optionsByFundingInstrument[selectedFundingInstrument];
  }

  get paymentMethodSummary(): PaymentMethodSummary | null {
    const selectedFundingInstrument = this.selectedFundingInstrument;
    if (!selectedFundingInstrument) {
      return null;
    }
    const cardIssuer = this.selectedCreditCard?.name ?? '';
    const cardMask = this.selectedCreditCard?.mask;
    const buyerEmail = this.checkoutData?.buyer.email;
    const options: Record<FundingInstrument, PaymentMethodSummary> = {
      bankTransfer: {
        box1: { label: 'Bank', text: this.selectedBankAccount?.bankName ?? '' },
        box2: {
          label: 'Account No.',
          text: `••••${this.selectedBankAccount?.mask ?? ''}`,
        },
      },
      cardPayment: {
        box1: {
          label: 'Credit Card',
          text: `${cardIssuer.charAt(0).toUpperCase() + cardIssuer.slice(1)} ending with ${cardMask}`,
        },
      },
      invoice: {
        box1: {
          label: 'Invoice',
          text: `Details will be sent to ${buyerEmail}`,
        },
      },
    };

    return options[selectedFundingInstrument];
  }

  get prices(): {
    subtotal: string;
    shipping: string;
    estimatedTax: string;
    total: string;
    discount: string;
  } | null {
    if (!this.checkoutData) {
      return null;
    }

    let totalLineItems = 0,
      totalPrice = 0,
      totalShipping = 0,
      totalTax = 0,
      totalDiscount = 0;

    this.allCheckoutData?.forEach((data) => {
      totalLineItems += Number(data.totalLineItems);
      totalShipping += Number(data.totalShipping);
      totalPrice += Number(data.totalPrice);
      totalTax += Number(data.totalTax);
      // show discount as a negative number
      totalDiscount -= Number(data.totalDiscount);
    });

    return {
      subtotal: this.formatPrice(totalLineItems),
      total: this.formatPrice(totalPrice),
      shipping: this.formatPrice(totalShipping),
      estimatedTax: this.formatPrice(totalTax),
      discount: this.formatPrice(totalDiscount),
    };
  }

  private formatPrice = (prop: number) => {
    const formatPriceUsingCurrency = formatPrice(this.currency);
    return prop ? formatPriceUsingCurrency(prop.toString()) : '';
  };

  get chosenCreditCard(): { name: string; mask: string } {
    return {
      name: '',
      mask: '',
    };
  }

  get amountToCharge(): string {
    const totalPrice =
      this.allCheckoutData?.reduce((totalPrice, currData) => totalPrice + Number(currData.totalPrice), 0) ?? '0.00';
    const currency = this.checkoutData?.currency ?? 'USD';

    const price = Intl.NumberFormat(undefined, {
      currency,
      minimumFractionDigits: 2,
      style: 'currency',
    }).format(Number(totalPrice));
    return price || '';
  }

  get sellerName(): string {
    return this.checkoutData?.vendorName ?? '';
  }
  get sellerLogo(): string {
    return this.checkoutData?.vendorLogo ?? '';
  }

  get buyerEmail(): string {
    return this.checkoutData?.buyer.email ?? '';
  }

  get buyerBusinessName(): string {
    return this.checkoutData?.buyer.businessName ?? '';
  }

  get buyerName(): string {
    const firstName = this.checkoutData?.buyer.firstName;
    const lastName = this.checkoutData?.buyer.lastName;
    return (firstName ? firstName + ' ' : '') + (lastName ?? '');
  }

  get buyerPhone(): string {
    return this.checkoutData?.buyer.phone ?? '';
  }

  get createdDate(): Date | string {
    if (!this.checkoutData?.createdAt) return '';
    const date = parseISO(this.checkoutData.createdAt.toString());
    return format(date, 'MMMM dd, yyyy');
  }

  get allowedPaymentMethods(): any | {} {
    if (!this.paymentMethodsData?.allowedPaymentMethods) {
      return {};
    }

    return this.paymentMethodsData.allowedPaymentMethods
      .map((pm) => pm.name)
      .reduce(
        (acc, pm) => ({
          ...acc,
          [pm]: availablePaymentOptions[pm],
        }),
        {}
      );
  }

  get allowedTermsPaymentMethods(): any | {} {
    if (!this.paymentMethodsData?.allowedTermsPaymentMethods) {
      return {};
    }

    return this.paymentMethodsData.allowedTermsPaymentMethods
      .map((pm) => pm.name)
      .reduce(
        (acc, pm) => ({
          ...acc,
          [pm]: availablePaymentOptions[pm],
        }),
        {}
      );
  }

  get hasSelectedCard(): boolean {
    return Boolean(this.selectedCreditCard);
  }

  get hasSelectedBankAccount(): boolean {
    return Boolean(this.selectedBankAccount);
  }

  get isNextDisabled(): boolean {
    const options: Record<Phase, boolean> = {
      // paymentTermSelection: !this.selectedPaymentTerm,
      // howToPaySelection: this.creditCard.showCardForm &&!this.selectedHowToPay || this.creditCard.canSubmit,
      howToPaySelection:
        this.selectedHowToPay === 'withCard' && !this.hasSelectedCard
          ? !this.creditCard.canSubmit
          : !this.selectedHowToPay,
      // creditCardManagement: !this.hasSelectedCard || !this.creditCard.canSubmit,
      creditCardManagement: this.creditCard.showCardForm ? !this.creditCard.canSubmit : !this.hasSelectedCard,
      bankAccountManagement: !this.hasSelectedBankAccount,
      plaid: true,
      linkBank: false,
      payLater: true,
      summary: false,
      electronicQuote: this.electronicQuoteState.shouldContactSales === null,
      payWithTermsResponse: false,
      afterPay: false,
      afterInvoiceCreated: false,
      afterQuoteCreated: false,
    };

    return options[this.currentPhase];
  }

  get isShowBackButton(): boolean {
    const options: Record<Phase, boolean> = {
      howToPaySelection: !this.hideBackOnFirstScreen || this.selectedPaymentTerm === 'payWithTerms',
      creditCardManagement: true,
      bankAccountManagement: true,
      plaid: true,
      linkBank: true,
      payLater: true,
      summary: true,
      electronicQuote: true,
      payWithTermsResponse: true,
      afterPay: true,
      afterInvoiceCreated: true,
      afterQuoteCreated: true,
    };

    return options[this.currentPhase];
  }

  get isShowNextButton(): boolean {
    const options: Record<Phase, boolean> = {
      howToPaySelection: true,
      creditCardManagement: true,
      bankAccountManagement: true,
      plaid: true,
      linkBank: true,
      payLater: true,
      summary: true,
      electronicQuote: true,
      payWithTermsResponse: true,
      afterPay: this.checkoutMode === 'iframe',
      afterInvoiceCreated: this.checkoutMode === 'iframe',
      afterQuoteCreated: true,
    };

    return options[this.currentPhase];
  }

  get debitDate(): string {
    return format(add(new Date(), { days: Number(this.payWithTermsDays) }), 'MMMM dd, yyyy');
  }

  get today(): string {
    return format(add(new Date(), { days: 0 }), 'MMMM dd, yyyy');
  }

  get shouldShowPayWithTerms(): boolean {
    return this.payWithTermsStatus !== FinanceAssessmentDecision.Approved;
  }

  get shouldPayWithTermsBeDisabled(): boolean {
    return this.payWithTermsStatus === FinanceAssessmentDecision.Declined;
  }

  get previousButton(): { text: string; callback: (() => void) | null } {
    const currentPhase = this.currentPhase;

    const textByPhase: Record<Phase, string> = {
      howToPaySelection: '',
      creditCardManagement: 'Back',
      bankAccountManagement: 'Back',
      // paymentTermSelection: '',
      electronicQuote: 'Go back to payment term selection',
      plaid: 'Go back to Funding Instrument selection',
      payLater: '',
      linkBank: 'Next',
      summary: 'Go back',
      payWithTermsResponse: 'Go back',
      afterPay: '',
      afterInvoiceCreated: '',
      afterQuoteCreated: '',
    };

    const callback: Record<Phase, (() => void) | null> = {
      howToPaySelection: () => {
        if (this.isPayingWithTerms || this.shouldPayWithTermsBeDisabled || !this.shouldShowPayWithTerms) {
          this.submitPayLaterResponse = null;
          this.selectedPaymentTerm = null;
          this.setPhase('howToPaySelection');
        } else {
          if (this.checkoutMode === 'iframe') {
            window?.xprops?.onCancel?.();
            window?.xprops?.onClose?.();
          } else if (this.checkoutMode === 'standalone') {
            if (this.standaloneBackURL && isUri(this.standaloneBackURL)) {
              window.open(this.standaloneBackURL, '_self');
            } else {
              window.history.back();
            }
          }
        }
      },
      creditCardManagement: () => {
        if (this.hasListOfCreditCards && !this.hasSelectedCard) {
          this.setSelectedCreditCard(this.creditCardList[0]);
          this.creditCard.setShowCardForm(false);
          this.overrideNextButton(null);
          this.clearNotification();
        }
        this.setPhase(this.previousPhase);
      },
      bankAccountManagement: () => this.setPhase(this.previousPhase),
      // paymentTermSelection: null,

      plaid: () => this.setPhase(this.previousPhase),
      payLater: null,
      linkBank: () => {
        this.selectedPaymentTerm = null;
        this.setPhase(this.previousPhase);
      },
      summary: () => {
        this.setPhase(this.previousPhase);
        this.clearNotification();
      },
      electronicQuote: () => window?.xprops?.onClose?.(),
      payWithTermsResponse: () => this.setPhase(this.previousPhase),
      afterPay: null,
      afterInvoiceCreated: null,
      afterQuoteCreated: null,
    };

    return {
      text: textByPhase[currentPhase],
      callback: callback[currentPhase],
    };
  }

  /**
   * In case you'd like to override the default behaviour of the next button, use this.
   * Don't forget to clear it by passing `null` once you're done.
   */
  overrideNextButton = (opts: { cb: () => void; text: string } | null): void => {
    if (opts === null) {
      this.customNextButton = null;
    } else {
      const { cb, text } = opts;
      this.customNextButton = { text, callback: cb };
    }
  };

  customNextButton: { text: string; callback: () => void | null } | null = null;

  get nextButton(): { text: string; callback: (() => void) | null } {
    const currentPhase = this.currentPhase;
    const payWithTermsResponseStatus = this.submitPayLaterResponse?.decision;
    const nextPhase = this.nextPhase;
    const isNow = !this.isPayingWithTerms;

    const textByPhase: Record<Phase, string> = {
      howToPaySelection: 'Next',
      creditCardManagement: 'Next',
      bankAccountManagement: 'Next',
      linkBank: 'Next',
      plaid: '',
      payLater: '',
      summary: isNow && this.selectedFundingInstrument !== 'invoice' ? 'Pay now' : 'Confirm',
      electronicQuote: 'Send me an email',
      payWithTermsResponse: 'Continue to Payment',
      afterPay: 'Close',
      afterInvoiceCreated: 'Close',
      afterQuoteCreated: 'Close',
    };

    const callback: Record<Phase, (() => void) | null> = {
      creditCardManagement: () => {
        !this.isPayingWithTerms && this.setSelectedPaymentTerm('payNow');
        this.setSelectedFundingInstrument('cardPayment');
        this.setPhase('summary');

        track(Events.FundingInstrumentSelected, { fundingInstrument: this.selectedFundingInstrument });
      },
      bankAccountManagement: () => {
        !this.isPayingWithTerms && this.setSelectedPaymentTerm('payNow');
        this.setSelectedFundingInstrument('bankTransfer');

        this.setPhase('summary');

        track(Events.FundingInstrumentSelected, { fundingInstrument: this.selectedFundingInstrument });
      },
      linkBank: () => {
        this.setPhase('plaid');
        track(Events.LinkBank);
      },
      howToPaySelection: () => {
        track(Events.HowToPaySelected, {
          howToPay: this.selectedHowToPay,
          payWithTerms: this.isPayingWithTerms,
          selectedCreditCard: !!this.selectedCreditCard,
          selectedBankAccount: !!this.selectedBankAccount,
        });

        // @ts-ignore
        if (this.selectedHowToPay === 'withCard' || (this.isPayingWithTerms && this.selectedHowToPay === 'withCard')) {
          this.setSelectedFundingInstrument('cardPayment');

          !this.isPayingWithTerms && this.setSelectedPaymentTerm('payNow');
          if (this.selectedCreditCard) {
            this.setPhase('summary');
          } else {
            this.setPhase('creditCardManagement');
          }
        } else if (
          this.selectedHowToPay === 'withBankTransfer' ||
          // @ts-ignore
          (this.isPayingWithTerms && this.selectedHowToPay === 'withBankTransfer')
        ) {
          !this.isPayingWithTerms && this.setSelectedPaymentTerm('payNow');
          this.setSelectedFundingInstrument('bankTransfer');
          if (this.selectedBankAccount) {
            this.setPhase('summary');
          } else {
            this.setPhase('bankAccountManagement');
          }
        } else if (this.selectedHowToPay === 'withTerms') {
          this.setSelectedPaymentTerm('payWithTerms');
          if (this.selectedBankAccount) {
            this.setPhase('payLater');
          } else if (this.isPreQualificationFinished) {
            this.setPhase('payLater');
          } else if (!this.hasListOfBankAccounts) {
            this.setPhase('linkBank');
          } else {
            this.setPhase('bankAccountManagement');
          }
        }
      },

      plaid: () => {
        const selectedHowToPay = this.selectedHowToPay;

        if (selectedHowToPay === 'withTerms') {
          this.setPhase('howToPaySelection');
        } else {
          this.setPhase('bankAccountManagement');
        }
      },
      payLater: null,
      summary: () => this.submitPayment(),
      electronicQuote: () => this.submitElectroniceQuote(),
      payWithTermsResponse: () => {
        if (payWithTermsResponseStatus === FinanceAssessmentDecision.Approved) {
          this.setPhase(nextPhase);
        }
      },
      afterPay: () => window?.xprops?.onClose?.(),
      afterInvoiceCreated: () => window?.xprops?.onClose?.(),
      afterQuoteCreated: () => window?.xprops?.onClose?.(),
    };

    return {
      text: textByPhase[currentPhase],
      callback: callback[currentPhase],
    };
  }

  get hasListOfBankAccounts(): boolean {
    return Boolean(this.bankAccountsResponse && this.bankAccountsResponse.length > 0);
  }

  get hasListOfCreditCards(): boolean {
    return Boolean(this.creditCardList && this.creditCardList.length > 0);
  }

  get nextPhase(): Phase | null {
    if (!this.currentPhase) {
      return 'howToPaySelection';
    }

    const selectedFundingInstrument = this.selectedFundingInstrument;
    const selectedPaymentTerm = this.selectedPaymentTerm;

    const phases: Record<Phase, Phase | null> = {
      get howToPaySelection(): 'howToPaySelection' {
        return 'howToPaySelection';
      },
      get creditCardManagement(): 'summary' {
        return 'summary';
      },
      get bankAccountManagement(): 'summary' | 'bankAccountManagement' {
        if (!selectedFundingInstrument) {
          return 'bankAccountManagement';
        }
        return 'summary';
      },
      get linkBank(): 'plaid' {
        return 'plaid';
      },
      get payLater(): 'howToPaySelection' {
        return 'howToPaySelection';
      },
      get plaid(): 'bankAccountManagement' | 'payWithTermsResponse' | 'howToPaySelection' | 'summary' {
        return selectedPaymentTerm === 'payWithTerms' ? 'howToPaySelection' : 'bankAccountManagement';
      },
      summary: null,
      afterPay: null,
      afterInvoiceCreated: null,
      afterQuoteCreated: null,
      electronicQuote: null,
      get payWithTermsResponse(): 'howToPaySelection' | null {
        return 'howToPaySelection';
      },
    };

    return phases[this.currentPhase];
  }

  get isPayingWithTerms(): boolean {
    return this.selectedPaymentTerm === 'payWithTerms';
  }

  get featureFlags(): BuyerSettings {
    return this.featureFlagsRes || {};
  }

  get previousPhase(): Phase | null {
    const phases: Record<Phase, Phase | null> = {
      howToPaySelection: null,
      creditCardManagement: 'howToPaySelection',
      bankAccountManagement: 'howToPaySelection',
      plaid: 'howToPaySelection',
      payLater: null,
      summary: 'howToPaySelection',
      linkBank: 'howToPaySelection',
      electronicQuote: null,
      payWithTermsResponse: 'howToPaySelection',
      afterPay: null,
      afterInvoiceCreated: null,
      afterQuoteCreated: null,
    };

    return phases[this.currentPhase];
  }

  get notes(): string | undefined {
    return this.checkoutData?.notes;
  }

  public openCardManagement = () => {
    track(Events.ManageCards);
    this.setPhase('creditCardManagement');
  };

  public openBankManagement = () => {
    track(Events.ManageBankAccounts);
    this.setPhase('bankAccountManagement');
  };

  public start = async () => {
    if (this.checkoutTokens.length) {
      this.checkoutToken = this.checkoutTokens[0];
    }
    if (!this.checkoutToken) {
      console.error('missing checkout token');
    }
    if (this.checkoutToken && !this.checkoutTokens.length) {
      this.checkoutTokens = [this.checkoutToken];
    }
    try {
      await this.login();

      await Promise.all([
        this.getCheckoutData(),
        this.getPaymentMethods(),
        this.getListOfStripeCards(),
        this.getBankAccounts(),
        this.generateClientSecret(),
        this.getBuyerSettings(),
      ]);

      if (config.logRocketKey && config.isProduction) {
        LogRocket.identify(this.checkoutData?.buyer.id.toString() as string, {
          buyerId: this.checkoutData?.buyer.id.toString() as string,
          buyerEmail: this.checkoutData?.buyer.email as string,
          merchantName: this.checkoutData?.vendorName as string,
        });
      }

      this.setLoading(false);
    } catch (err) {
      console.error('err', err);
      this.setLoading(false);
    }
  };

  public setLoading = (flag: boolean) => {
    this.loading = flag;
  };

  public setCreditCardWhatToShow = (option: 'form' | 'list') => {
    this.creditCardWhatToShow = 'list';
  };

  public setSelectedCreditCard = (creditCardMeta: CreditCardMeta) => {
    this.selectedCreditCard = creditCardMeta;
  };

  public clearSelectedCreditCard = () => {
    this.selectedCreditCard = null;
  };

  public clearSelectedBankAccount = () => {
    this.selectedBankAccount = null;
  };

  public setCreditCardMode = (mode: CreditCardMode) => {
    this.creditCardMode = mode;
  };

  public onAddBankClick = (): void => {
    this.setPhase(
      this.selectedPaymentTerm === 'payWithTerms' && !this.hasInstallments && !this.hasListOfBankAccounts
        ? 'linkBank'
        : 'plaid'
    );
  };

  public setSelectedBankAccount = (bankAccount: BankAccountMeta) => {
    this.selectedBankAccount = bankAccount;
  };

  public setPhase = (phase: Phase | null) => {
    if (phase === null) {
      return;
    }

    this.currentPhase = phase;
  };

  public setElectronicQuoteState = (newState: Partial<CheckoutStore['electronicQuoteState']>) => {
    this.electronicQuoteState = {
      ...this.electronicQuoteState,
      ...newState,
    };
  };

  public setSelectedPaymentTerm = (paymentTerm: PaymentTerm) => {
    this.selectedPaymentTerm = paymentTerm;
  };

  public handleStripeError = (error: StripeError) => {
    console.error('error', error);
    this.setNotification({
      type: 'error',
      text: error.message,
    });
    if (error.decline_code) error.code = error.decline_code;
    window?.xprops?.onError?.({ buyerEmail: this.buyerEmail, error });
  };

  public setSelectedHowToPay = (howToPay: HowToPay | null) => {
    this.selectedHowToPay = howToPay;
  };

  public setSelectedFundingInstrument = (payMethod: FundingInstrument) => {
    this.selectedFundingInstrument = payMethod;
  };

  public generateLinkTokenForPlaid = async (mode?: string) => {
    try {
      const { data } = await generateLinkTokenForPlaid(mode);

      this.linkToken = data.linkToken;
    } catch (err) {
      console.error('err', err);
    }
  };

  public deleteCreditCard = async (id: string) => {
    this.setLoading(true);
    try {
      const { data } = await deleteCreditCard(id);

      if (data.cardId) {
        await this.getListOfStripeCards();
      }

      // TODO - How to handle an error?
      this.setLoading(false);
    } catch (err) {
      console.error('err', err);
      this.setLoading(false);
    }
  };

  public getListOfStripeCards = async () => {
    try {
      const { data } = await getListOfStripeCards();

      this.listOfCardsResponse = data.data;
      if (this.listOfCardsResponse.existingMethods.length > 0) {
        this.setCreditCardWhatToShow('list');
        this.setSelectedCreditCard({
          id: this.listOfCardsResponse.existingMethods[0].id,
          mask: this.listOfCardsResponse.existingMethods[0].card.last4,
          name: this.listOfCardsResponse.existingMethods[0].card.brand,
          exp: `${this.listOfCardsResponse.existingMethods[0].card.exp_month}${this.listOfCardsResponse.existingMethods[0].card.exp_year}`,
        });
      } else {
        this.clearSelectedCreditCard();
      }
      this.setLoading(false);
    } catch (err) {
      console.error('err', err);
      this.setLoading(false);
    }
  };

  public setSetupIntent = async (setupIntent: SetupIntent) => {
    this.setupIntentResponse = setupIntent;
    await this.getListOfStripeCards();
    this.clearNotification();
  };

  public clearClientSecret = () => (this.stripeClientSecret = '');

  public generateClientSecret = async () => {
    try {
      const { data } = await generateClientSecret();

      this.stripeClientSecret = data.data.clientSecret;
    } catch (err) {
      console.error('err', err);
    }
  };

  public login = async () => {
    if (!this.checkoutToken) {
      return;
    }

    try {
      const { data } = await login({
        checkoutKey: this.checkoutToken,
        emailAddress: null,
        retryCode: false,
        smsCode: false,
      });

      setToken(data.accessToken);
      this.accessToken = data.accessToken;
    } catch (err) {
      console.error('err', err);
    }
  };

  public getCheckoutData = async () => {
    if (!this.checkoutToken) {
      return;
    }
    try {
      const getCheckoutDataPromise = this.checkoutTokens.map(getCheckoutData);
      const getCheckoutDataRes = await Promise.all(getCheckoutDataPromise);

      const data = getCheckoutDataRes[0].data;
      this.allCheckoutData = getCheckoutDataRes.map((res) => res.data);
      this.checkoutData = data;
      identify(this.checkoutToken, Services.Checkout, environment, data.buyer.email, data.totalPrice);
    } catch (err) {
      console.error('err', err);
    }
  };

  public deleteBankAccount = async (itemId: string) => {
    if (!this.checkoutToken) {
      return;
    }
    try {
      this.setLoading(true);
      const { data } = await deleteBankAccount(itemId);

      if (data.itemId) {
        await this.getBankAccounts();
      }
      // TODO - How to handle an error?
      this.setLoading(false);
    } catch (err) {
      console.error('err', err);
    }
  };

  public getBankAccounts = async () => {
    if (!this.checkoutToken) {
      return;
    }
    try {
      const { data } = await getBankAccounts();

      this.bankAccountsResponse = data;
      if (this.bankAccountsResponse.length > 0) {
        this.setSelectedBankAccount({
          accountId: this.bankAccountsResponse[0].account.id,
          bankName: this.bankAccountsResponse[0].institution.name,
          itemId: this.bankAccountsResponse[0].itemId,
          mask: this.bankAccountsResponse[0].account.mask,
          name: this.bankAccountsResponse[0].account.name,
          type: this.bankAccountsResponse[0].account.type,
        });
      } else {
        this.clearSelectedBankAccount();
      }
    } catch (err) {
      console.error('err', err);
    }
  };

  public getPaymentMethods = async () => {
    if (!this.checkoutToken) {
      return;
    }
    try {
      const { data } = await getPaymentMethods(this.checkoutToken);

      if (!data.allowedTermsPaymentMethods) {
        data.allowedTermsPaymentMethods = data.allowedPaymentMethods;
      }

      this.paymentMethodsData = data;
    } catch (err) {
      console.error('err', err);
    }
  };

  get paymentPayload(): SubmitPaymentRequest | null {
    if (!this.selectedPaymentTerm || (!this.selectedCreditCard && !this.selectedBankAccount)) {
      return null;
    }

    const isNow: boolean = this.selectedPaymentTerm === 'payNow';
    const fundingInsturment = this.selectedFundingInstrument;

    const gateway =
      fundingInsturment === 'cardPayment'
        ? 'stripe-cc'
        : fundingInsturment === 'bankTransfer'
        ? 'stripe-ach'
        : fundingInsturment === 'invoice'
        ? 'stripe-ach-credit-transfer'
        : null;
    if (!gateway) {
      return null;
    }

    return {
      gateway,
      paymentGatewayId:
        (fundingInsturment === 'cardPayment'
          ? this.selectedCreditCard?.id
          : fundingInsturment === 'bankTransfer'
          ? this.selectedBankAccount?.itemId
          : fundingInsturment === 'invoice'
          ? this.wireTransferResponse?.id
          : '') ?? '',
      depositPaymentId: fundingInsturment === 'invoice' ? this.selectedCreditCard?.id : undefined,
      paymentTermNumericVal: isNow ? 0 : 30,
      paymentTerm: isNow ? 'IMMEDIATE' : 'NET',
    };
  }

  public submitPayment = async () => {
    if (!this.checkoutToken) {
      return;
    }

    track(Events.CheckoutCompleted);

    try {
      this.setLoading(true);
      if (this.selectedFundingInstrument === 'invoice') {
        await this.confirmPayment();
        this.setPhase('afterInvoiceCreated');
      } else {
        if (!this.paymentPayload || !this.checkoutData) {
          return;
        }
        const getCheckoutDataRes = await this.confirmPayment();
        const data = getCheckoutDataRes[0].data;
        this.checkoutData.status = data.status;
        this.serverRedirectURL = data?.redirectURL;
      }
      this.setLoading(false);
      window?.xprops?.onSuccess?.();
      window?.xprops?.onComplete?.({ status: 'closed' });
      if (this.serverRedirectURL && isUri(this.serverRedirectURL)) {
        window.open(this.serverRedirectURL, '_self');
      } else if (this.successRedirectURL && isUri(this.successRedirectURL)) {
        window.open(this.successRedirectURL, '_self');
      }
    } catch (err) {
      console.error('err', err);
      if (err.message) {
        this.setNotification({
          type: 'error',
          text: "We couldn't process your payment",
        });
      } else {
        this.setNotification({
          type: 'error',
          text: "Oh no, something's not right",
        });
      }

      this.setLoading(false);
      err.code = err.message;
      err.message = 'The card was declined';
      window?.xprops?.onComplete?.({ status: 'error', error: err });
      window?.xprops?.onError?.({ buyerEmail: this.buyerEmail, error: err });
    }
  };

  private getPaymentMethodName = (): PaymentMethodName => {
    if (this.selectedFundingInstrument === 'cardPayment') return 'creditCard';
    if (this.selectedFundingInstrument === 'bankTransfer') return 'bank';
    return 'invoice';
  };

  public setNotification = (notification: NotificationProps | null) => {
    this.notification = notification;
  };

  public clearNotification = () => {
    this.setNotification(null);
  };

  public submitPayLater = async () => {
    if (
      !this.checkoutToken ||
      (!this.plaidResponse && !this.hasListOfBankAccounts && !this.isPreQualificationFinished)
    ) {
      return;
    }
    this.setLoading(true);

    const plaidItemId = this.plaidItemId;
    let isFailed;
    let failReason;
    try {
      const payload = plaidItemId ? { plaidItemId } : {};
      if (this.plaidResponse && !this.isPreQualificationFinished) {
        await buyerQualification({
          plaidData: {
            publicToken: this.plaidResponse.token,
            meta: toJS(this.plaidResponse.metadata),
            accountId: this.plaidResponse.metadata.account_id,
          },
        });
      }
      const submitPayLaterPromise = this.checkoutTokens.map((token) => submitPayLater(token, payload));
      const submitPayLaterRes = await Promise.all(submitPayLaterPromise);
      const data = submitPayLaterRes[0].data;

      this.submitPayLaterResponse = data;
      failReason = data.reason;

      track(Events.PayWithTermsResponse, { status: data.decision });

      isFailed = submitPayLaterRes.some((res) => res.data.decision === FinanceAssessmentDecision.Declined);
    } catch (err) {
      console.error('err', err);
      isFailed = true;
    }

    if (isFailed) {
      this.setSelectedPaymentTerm('payNow');
      this.submitPayLaterResponse = { decision: FinanceAssessmentDecision.Declined, reason: failReason };
    }

    this.setPhase(this.nextPhase);
    this.setLoading(false);
  };

  private get plaidItemId() {
    return this.checkoutData?.buyer.qualificationStatus !== 'completed'
      ? this.hasSelectedBankAccount
        ? this.selectedBankAccount!.itemId
        : this.bankAccountsList[0]?.itemId
      : undefined;
  }

  private get isPreQualificationFinished() {
    return (
      this.checkoutData?.buyer.qualificationStatus === BuyerQualificationStatus.APPROVED ||
      this.checkoutData?.buyer.qualificationStatus === BuyerQualificationStatus.DECLINED
    );
  }

  public persistPlaid = async () => {
    if (!this.plaidResponse) {
      return;
    }
    try {
      this.setLoading(true);
      const { data } = await persistPlaid({
        publicToken: this.plaidResponse.token,
        meta: toJS(this.plaidResponse.metadata),
        accountId: this.plaidResponse.metadata.account_id,
      });

      this.bankAccountsResponse = data;
      if (this.bankAccountsResponse.length > 0) {
        this.setSelectedBankAccount({
          accountId: this.bankAccountsResponse[0].account.id,
          bankName: this.bankAccountsResponse[0].institution.name,
          itemId: this.bankAccountsResponse[0].itemId,
          mask: this.bankAccountsResponse[0].account.mask,
          name: this.bankAccountsResponse[0].account.name,
          type: this.bankAccountsResponse[0].account.type,
        });

        if (this.selectedHowToPay === 'withTerms') {
          this.setPhase('howToPaySelection');
        } else {
          this.setPhase('bankAccountManagement');
        }
      }

      this.setLoading(false);
    } catch (err) {
      console.error('err', err);
      this.setLoading(false);
    }
  };

  public payWithInvoice = async () => {
    this.setLoading(true);
    await this.setWireTransfer();
    if (this.isPayingWithTerms) {
      this.setSelectedPaymentTerm('payWithTerms');
    } else {
      this.setSelectedPaymentTerm('payNow');
    }

    this.setSelectedFundingInstrument('invoice');
    this.setPhase('summary');
    this.setLoading(false);
  };

  public submitElectroniceQuote = async () => {
    if (!this.checkoutToken) {
      return;
    }
    try {
      const { data } = await submitElectroniceQuote({
        checkoutToken: this.checkoutToken,
        ...(this.electronicQuoteState.shouldContactSales && {
          message: this.electronicQuoteState.msg,
        }),
      });

      this.setPhase('afterQuoteCreated');
      return data;
    } catch (err) {
      console.error('err', err);
    }
  };

  private confirmPayment = () => {
    const submitPaymentPromise = this.checkoutTokens.map((token) => {
      const isFinanced = this.selectedPaymentTerm === 'payWithTerms';
      return confirm(token, {
        paymentMethodType: this.getPaymentMethodName(),
        isFinanced,
        isAuth: this.isAuth,
        paymentMethodId:
          (this.selectedFundingInstrument === 'cardPayment'
            ? this.selectedCreditCard?.id
            : this.selectedFundingInstrument === 'bankTransfer'
            ? this.selectedBankAccount?.itemId
            : '') ?? '',
      });
    });
    return Promise.all(submitPaymentPromise);
  };

  public setWireTransfer = async () => {
    try {
      const { data } = await setWireTransfer();

      this.wireTransferResponse = data;
    } catch (err) {
      console.error('err', err);
    }
  };

  public handlePlaidExit = () => {
    this.selectedFundingInstrument = null;
    this.selectedPaymentTerm = null;
    this.setPhase('howToPaySelection');
  };

  public getBuyerSettings = async () => {
    this.isBuyerSettingsLoading = true;
    const {
      data: {
        settings: { primaryColor: primary, secondaryColor: secondary, ...settings },
      },
    } = await getSettings();
    this.colorOverrides = { primary, secondary };
    this.featureFlagsRes = settings;
    this.isBuyerSettingsLoading = false;
  };

  handlePlaidSuccess = async (token: Token, metadata: PlaidMetadata) => {
    this.plaidResponse = {
      token,
      metadata,
    };

    await this.persistPlaid();

    if (this.selectedPaymentTerm === 'payNow') {
      this.setPhase(this.nextPhase);
    } else if (this.selectedPaymentTerm === 'payWithTerms') {
      this.submitPayLater();
    }
  };
}

export default CheckoutStore;

import { Tooltip, withStyles } from '@material-ui/core';
import React from 'react';

export const StyledTooltip = withStyles({
  arrow: {
    color: 'black',
  },
  tooltip: {
    backgroundColor: 'black',
    padding: '12px',
    fontSize: 14,
  },
})(Tooltip);

import * as React from 'react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import { useStore } from './../../../stores/setupContext';
import { Typography } from '../../../ui-lib';
import { flexCenterCenter } from './../../../styles/common';
import success from './../../../images/success.png';
import { Theme } from '../../../theme';

export interface AfterQuoteCreatedProps {
  email: string;
}

export const Container = styled('div')<{ theme: Theme }>(({ theme }) => ({
  marginTop: theme.gutters.base * 5,
  ...flexCenterCenter,
  flexDirection: 'column',
}));

export const TextBlock = styled('div')<{ theme: Theme }>(({ theme }) => ({
  marginTop: theme.gutters.base * 3,
  display: 'grid',
  gap: theme.gutters.base * 2,
  textAlign: 'center',
}));

export const Image = styled('img')<{ theme: Theme }>(({ theme }) => ({
  display: 'block',
  width: 420,
  objectFit: 'contain',
  height: 240,
}));

export const AfterQuoteCreated: React.FunctionComponent<AfterQuoteCreatedProps> = ({ email }) => {
  return (
    <Container>
      <Image {...{ src: success }} />
      <TextBlock>
        <Typography {...{ variant: 'h1Bold', center: true }}>Great!</Typography>
        <Typography {...{ variant: 'h1', center: true }}>
          Your quote is on its way, you should see it shortly in the email you provided - {email}
        </Typography>
      </TextBlock>
    </Container>
  );
};

const Observed = observer(AfterQuoteCreated);

const WithStoreConnection = () => {
  const { checkout } = useStore();

  const { buyerEmail } = checkout;

  return <Observed {...{ email: buyerEmail }} />;
};

export default observer(WithStoreConnection);

import * as React from 'react';
import styled from '@emotion/styled';
import { Theme, theme } from '../../theme';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import {
  loadStripe,
  SetupIntent,
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
  StripeError,
} from '@stripe/stripe-js';
import { stripePublicKey } from '../../config/config';
import FONT from './../../fonts/Aeonik-Regular.woff';
import { flexBetweenCenter } from '../../styles/common';
import greyLock from './../../icons/grey-lock.svg';
import { observer } from 'mobx-react';
import { useStore } from '../../stores/setupContext';
import { ButtonWithIcon } from '../../sections/checkout/ButtonGroup/ButtonGroup';
import { SlashIcon } from '../../icons';
import InvoiceStore from '../../stores/InvoiceStore';
import CheckoutStore, { formatPrice } from '../../stores/CheckoutStore';
import { Controller, useForm } from 'react-hook-form';
import { PlacesAddressAutocomplete } from '../../ui-lib/Address';
import { BusinessDetailsFormFields } from '../../sections/qualification/BusinessDetailsForm';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Typography } from '../../ui-lib/Typography/Typography';
import { Grid } from '@material-ui/core';
import { StyledTextField } from '../../ui-lib/TextField/StyledTextField';

const stripePromise = loadStripe(stripePublicKey);

export interface CreditCardSplitFormProps {
  clientSecret: string;
  onSuccess: (setupIntent: SetupIntent) => void;
  onError: (error: StripeError) => void;
  setLoading: (flag: boolean) => void;
  showCardForm: boolean;
  setFormComplete: (flag?: boolean) => void;
  overrideNextButton: any;
  newCard: boolean;
  mode: 'payPage' | 'checkout' | 'paymentSettings';
  currency: CheckoutStore['currency'];
  currentCharge: InvoiceStore['currentCharge'];
  billingAddressFeatureFlag: CheckoutStore['featureFlags']['billingAddress'];
  manualBillingAddressFeatureFlag: CheckoutStore['featureFlags']['manualBillingAddress'];
}

export const Form = styled('form')<{
  theme: Theme;
  showCardForm: boolean;
}>(({ theme, showCardForm }) => ({
  margin: 0,
  width: '100%',
  ...flexBetweenCenter,
  '& > div': {
    width: '100%',
  },
  ...{
    flexDirection: 'column',
    marginTop: theme.gutters.base,
    '& button': {
      marginTop: theme.gutters.base,
    },
  },
  display: showCardForm ? 'flex' : 'none',
}));

export const CardElementsGrid = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'grid',
  gap: `${theme.gutters.base * 2}px ${theme.gutters.base * 4}px`,
  gridTemplateColumns: '3fr 1fr 1fr',
  gridTemplateAreas: `
      'number expiry cvc'
      'address . .' 
      `,
}));

const CardExtraAddressGrid = styled('div')<{ theme: Theme }>(({ theme }) => ({
  display: 'grid',
  gap: `${theme.gutters.base * 2}px ${theme.gutters.base * 4}px`,
  paddingTop: `${theme.gutters.base * 2}px`,
  gridTemplateColumns: '1fr 1fr 1fr',
  gridTemplateAreas: `
      'address2 address2 city'
      'state countryCode zip'
      `,
}));

export const TextField = styled('input')<{ theme: Theme }>(({ theme }) => ({
  position: 'relative',
  padding: theme.gutters.base,
  border: 'none',
  outline: 'none',
  borderBottom: `1px solid ${theme.palette.blacks.eta}`,
  fontFamily: theme.typography.fontFamily,
  ...theme.typography.meta.caption,
}));

export const CardElementWrap = styled('div')<{ theme: Theme; gridArea: 'number' | 'expiry' | 'cvc' | 'postal_code' }>(
  ({ theme, gridArea }) => ({
    position: 'relative',
    padding: theme.gutters.base,
    borderBottom: `1px solid ${theme.palette.blacks.eta}`,
    gridArea,
    '& > img': {
      position: 'absolute',
      right: 0,
      top: '50%',
      transform: 'translateY(-50%)',
    },
  })
);

const stripeStyle = {
  base: {
    fontFamily: theme.typography.fontFamily,

    fontSize: `${theme.typography.meta.caption.fontSize}px`,
    color: theme.palette.blacks.alpha,

    '::placeholder': {
      color: theme.palette.background.gamma,
    },
  },
  invalid: {
    color: theme.palette.indicators.error,
  },
};

export const CreditCardSplitFormNew: React.FunctionComponent<CreditCardSplitFormProps> = ({
  mode,
  newCard,
  onError,
  onSuccess,
  setLoading,
  clientSecret,
  showCardForm,
  setFormComplete,
  overrideNextButton,
  currency,
  currentCharge,
  billingAddressFeatureFlag,
  manualBillingAddressFeatureFlag,
}) => {
  if (!billingAddressFeatureFlag) return null;

  const currencyFormatter = formatPrice(currency);
  const stripe = useStripe();
  const elements = useElements();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const addressRef: React.MutableRefObject<HTMLInputElement | undefined> = React.useRef();
  const [isCardNumberCompleted, setIsCardNumberCompleted] = React.useState(false);
  const [isCardExpCompleted, setIsCardExpCompleted] = React.useState(false);
  const [isCardCvcCompleted, setIsCardCvcCompleted] = React.useState(false);
  const [isBillingAddressCompleted, setIsBillingAddressCompleted] = React.useState(false);
  const [isManualAddress, setIsManualAddress] = React.useState(false);
  const [postalCode, setLocalPostalCode] = React.useState<string>('');

  const createValidationSchema = () =>
    Yup.object().shape({
      address1: Yup.string().required('Required'),
    });

  const { control, setValue, getValues } = useForm<BusinessDetailsFormFields>({
    resolver: yupResolver(createValidationSchema()),
    shouldFocusError: true,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      address1: '',
      line1: '',
      address2: '',
      city: '',
      country: '',
      state: '',
      zipCode: '',
    },
  });

  React.useEffect(() => {
    isCardNumberCompleted && isCardExpCompleted && isCardCvcCompleted && isBillingAddressCompleted && setFormComplete();
    return () => {
      setFormComplete(false);
    };
  }, [isCardNumberCompleted, isCardExpCompleted, isCardCvcCompleted, isBillingAddressCompleted]);

  const handleSubmit = async (e?: React.FormEvent<HTMLFormElement>) => {
    setIsSubmitting(true);
    e?.preventDefault?.();
    if (!stripe || !elements) {
      return;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);
    const cardCvcElement = elements.getElement(CardCvcElement);
    const billingAddress = getValues();

    if (!cardNumberElement || !cardExpiryElement || !cardCvcElement || !billingAddress.zipCode) {
      return;
    }

    setLoading(true);
    const { error, setupIntent } = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: cardNumberElement,
        billing_details: {
          address: {
            city: billingAddress.city,
            country: billingAddress.country || 'US',
            line1: billingAddress.line1,
            line2: billingAddress.address2,
            postal_code: billingAddress.zipCode,
            state: billingAddress.state,
          },
        },
      },
    });

    if (error) {
      console.error('[error]', error);
      setLoading(false);
      setIsSubmitting(false);
      onError(error);
      return;
    }

    setIsSubmitting(false);
    onSuccess(setupIntent!);
  };

  const AddressField = (
    label: string,
    fieldName: keyof BusinessDetailsFormFields,
    isAutoFocus?: boolean,
    ref?: React.MutableRefObject<HTMLInputElement | undefined>
  ) => (
    <StyledTextField
      {...{
        label,
        defaultValue: getValues(fieldName),
        onChange: (e) => {
          setValue(fieldName, e.target.value);
          if (
            getValues('line1') &&
            getValues('city') &&
            getValues('state') &&
            getValues('country') &&
            getValues('zipCode')
          ) {
            setIsBillingAddressCompleted(true);
          } else {
            setIsBillingAddressCompleted(false);
          }
        },
        variant: 'standard',
        inputProps: {
          style: { paddingLeft: 10, paddingBottom: 8, fontSize: 14 },
        },
        InputProps: { innerRef: ref },
        autoFocus: isAutoFocus,
        style: { width: '100%' },
      }}
    />
  );

  React.useEffect(() => {
    showCardForm && overrideNextButton({ cb: handleSubmit, text: newCard ? 'Next' : 'Add Card' });

    return () => {
      overrideNextButton(null);
    };
  }, [stripe, elements, showCardForm]);

  const handleCardChange = (
    event: StripeCardNumberElementChangeEvent | StripeCardCvcElementChangeEvent | StripeCardExpiryElementChangeEvent
  ) => {
    switch (event.elementType) {
      case 'cardNumber':
        setIsCardNumberCompleted(event.complete);
        if (event.complete) {
          elements?.getElement(CardExpiryElement)?.focus();
        }
        break;
      case 'cardExpiry':
        setIsCardExpCompleted(event.complete);
        if (event.complete) {
          elements?.getElement(CardCvcElement)?.focus();
        }
        break;
      case 'cardCvc':
        setIsCardCvcCompleted(event.complete);
        break;
    }
  };

  const setSelectedAddressSuggestionValues = (address: any) => {
    Object.entries(address as any).forEach((entry) => {
      const [key, value] = entry;

      if (!value) {
        setValue(key as keyof BusinessDetailsFormFields, '');
      } else {
        if (key === 'zipCode') setLocalPostalCode(value as string);
        if (key === 'address1') {
          setValue('line1' as keyof BusinessDetailsFormFields, value as string, { shouldValidate: true });
        } else {
          setValue(key as keyof BusinessDetailsFormFields, value as string, { shouldValidate: true });
        }
      }
    });
    if (address.zipCode) {
      setIsBillingAddressCompleted(true);
    } else {
      setIsBillingAddressCompleted(false);
    }
  };

  return (
    <Form {...{ onSubmit: handleSubmit, showCardForm, manualBillingAddressFeatureFlag, isManualAddress }}>
      <CardElementsGrid>
        <CardElementWrap {...{ gridArea: 'number' }}>
          <CardNumberElement
            {...{
              options: {
                style: stripeStyle,
                placeholder: 'Card number',
              },
              onChange: handleCardChange,
            }}
          />
          <img {...{ src: greyLock }} />
        </CardElementWrap>
        <CardElementWrap {...{ gridArea: 'expiry' }}>
          <CardExpiryElement
            {...{
              options: { style: stripeStyle, placeholder: 'MM/YY' },
              onChange: handleCardChange,
            }}
          />
        </CardElementWrap>
        <CardElementWrap {...{ gridArea: 'cvc' }}>
          <CardCvcElement
            {...{
              options: { style: stripeStyle },
              onChange: handleCardChange,
            }}
          />
        </CardElementWrap>
        {!isManualAddress && (
          <Controller
            control={control}
            name="address1"
            render={({ field, fieldState: { error } }) => (
              <PlacesAddressAutocomplete
                {...field}
                defaultValue={field.value}
                outerStyle={{ gridColumn: 'span 3' }}
                inputStyle={{ paddingLeft: 5, paddingBottom: 4 }}
                label="Billing address"
                margin="none"
                onAddressSelected={setSelectedAddressSuggestionValues}
                keepAddress={true}
                error={Boolean(error)}
                helperText={error?.message}
                placeholder={''}
                onValueChanged={(newValue: string) => {
                  setIsBillingAddressCompleted(false);
                }}
              />
            )}
          />
        )}
        {manualBillingAddressFeatureFlag && isManualAddress && (
          <Grid item xs={12} style={{ gridArea: 'address', gridColumn: 'span 3 / auto' }}>
            {AddressField('Address line 1', 'line1', true, addressRef)}
          </Grid>
        )}
      </CardElementsGrid>
      {isManualAddress && (
        <CardExtraAddressGrid>
          <Grid item xs={12} style={{ gridArea: 'address2' }}>
            {AddressField('Address line 2', 'address2')}
          </Grid>
          <Grid item xs={12} style={{ gridArea: 'city' }}>
            {AddressField('City', 'city')}
          </Grid>
          <Grid item xs={12} style={{ gridArea: 'state' }}>
            {AddressField('State', 'state', false)}
          </Grid>
          <Grid item xs={12} style={{ gridArea: 'countryCode' }}>
            {AddressField('Country Code', 'country', false)}
          </Grid>
          <Grid item xs={12} style={{ gridArea: 'zip' }}>
            <StyledTextField
              {...{
                label: 'Postal Code',
                value: postalCode,
                onChange: (e) => {
                  const value = e.target.value.toUpperCase();
                  if (value.length <= 10) {
                    setValue('zipCode', value);
                    setLocalPostalCode(value);
                  }
                  if (
                    getValues('line1') &&
                    getValues('city') &&
                    getValues('state') &&
                    getValues('country') &&
                    getValues('zipCode').length <= 10
                  ) {
                    setIsBillingAddressCompleted(true);
                  } else {
                    setIsBillingAddressCompleted(false);
                  }
                },
                variant: 'standard',
                inputProps: {
                  style: { paddingLeft: 10, paddingBottom: 8, fontSize: 14 },
                },
                style: { width: '100%' },
              }}
            />
          </Grid>
        </CardExtraAddressGrid>
      )}
      {manualBillingAddressFeatureFlag && !isManualAddress && (
        <Typography
          {...{
            variant: 'link',
            color: 'primary',
            style: { cursor: 'pointer', alignSelf: 'flex-end', paddingTop: 16 },
            onClick: () => {
              setIsManualAddress(true);
              setTimeout(() => {
                addressRef.current?.parentElement?.scrollIntoView(true);
              }, 0);
            },
          }}
        >
          Enter address manually
        </Typography>
      )}
      {(mode === 'payPage' || mode === 'paymentSettings') && (
        <div style={{ marginTop: '2rem' }}>
          <ButtonWithIcon
            {...{
              fullWidth: true,
              disabled: false,
              leftIcon: <SlashIcon {...{ color: 'brand' }} />,
              style:
                mode === 'paymentSettings'
                  ? {
                      marginTop: 65,
                    }
                  : {},
            }}
          >
            <b>
              {mode === 'payPage'
                ? `Pay ${currentCharge ? currencyFormatter(String(currentCharge.amount)) : ''}`
                : 'Confirm'}
            </b>
          </ButtonWithIcon>
        </div>
      )}
    </Form>
  );
};

export type CardDetailsProps = CreditCardSplitFormProps & { generateClientSecret: () => void };

export const CardDetails: React.FunctionComponent<CardDetailsProps> = ({
  clientSecret,
  generateClientSecret,
  ...rest
}) => {
  if (!clientSecret) {
    return null;
  }

  return (
    <Elements
      {...{
        stripe: stripePromise,
        options: {
          fonts: [
            {
              family: '"Aeonik"',
              src: `url(${FONT})`,
            },
          ],
        },
      }}
    >
      <CreditCardSplitFormNew {...{ clientSecret, ...rest }} />
    </Elements>
  );
};

const Observed = observer(CardDetails);

const WithStoreConnection: React.FunctionComponent<Pick<CreditCardSplitFormProps, 'mode'>> = ({
  mode = 'checkout',
}) => {
  const { invoice, checkout, pay } = useStore();
  const {
    generateClientSecret,
    stripeClientSecret,
    creditCard,
    overrideNextButton,
    setLoading,
    handleStripeError,
    hasListOfCreditCards,
    currency,
    featureFlags,
  } = checkout;

  const { showCardForm, setFormComplete, onFreshlyCreatedCardSuccess, onAddingACardToExistingCards, setShowCardForm } =
    creditCard;
  const { payWithCreditCard, currentCharge } = invoice;
  const { savePaymentSettings } = pay;
  const billingAddressFeatureFlag = featureFlags.billingAddress;
  const manualBillingAddressFeatureFlag = featureFlags.manualBillingAddress;
  const onCardSuccessfullySaved = (() => {
    if (mode === 'checkout') {
      return hasListOfCreditCards ? onAddingACardToExistingCards : onFreshlyCreatedCardSuccess;
    } else if (mode === 'payPage') {
      return payWithCreditCard;
    } else if (mode === 'paymentSettings') {
      return savePaymentSettings;
    }
  })();

  if (!onCardSuccessfullySaved) {
    return null;
  }

  return (
    <Observed
      {...{
        newCard: !hasListOfCreditCards,
        generateClientSecret,
        clientSecret: stripeClientSecret,
        showCardForm,
        setFormComplete,
        overrideNextButton,
        setLoading,
        setShowCardForm,
        onSuccess: onCardSuccessfullySaved,
        onError: handleStripeError,
        mode,
        currency,
        currentCharge,
        billingAddressFeatureFlag,
        manualBillingAddressFeatureFlag,
      }}
    />
  );
};

export default observer(WithStoreConnection);

import React, { FC, VFC } from 'react';
import { Box, Card, IconButton } from '@material-ui/core';
import { Typography } from '../../../ui-lib';
import styled from '@emotion/styled';
import { Theme } from '../../../theme';
import { ArrowBack } from '@material-ui/icons';

const Container = styled(Card)({
  width: '100%',
  borderRadius: '24px !important',
  margin: 'auto',
  boxSizing: 'border-box',
  boxShadow: '0px 0px 27px 2px rgb(0,0,0,10%)',
  '&:hover': {
    boxShadow: '0px 0px 27px 2px rgb(0,0,0,30%)',
  },
  overflow: 'inherit !important',
});

const Circle = styled('div')<{ theme: Theme }>(({ theme }) => ({
  backgroundColor: theme.palette.main.primary,
  borderRadius: '50%',
  padding: '24px',
}));

interface StepCardHeaderProps {
  iconSrc: string;
  headerText: string[];
}

const StyledCardHeader = styled(Box)({
  margin: '60px 80px 70px 80px',
});

const CardHeader: VFC<StepCardHeaderProps> = ({ iconSrc, headerText }) => (
  <StyledCardHeader display={'flex'}>
    <Circle>
      <img src={iconSrc} alt={'icon'} />
    </Circle>
    <Box mr={5} />
    <div>
      {headerText.map((line, index) => (
        <Typography key={index} variant={'smallHeader'}>
          {line}
        </Typography>
      ))}
    </div>
  </StyledCardHeader>
);

const CardBody = styled('div')({
  width: '100%',
  padding: '0 80px 80px 80px',
});

const ButtonContainer = styled('div')({
  margin: '0 0 -60px 10px',
  paddingTop: '10px',
});

interface StepCardProps extends StepCardHeaderProps {
  onBackAction?: VoidFunction;
}

export const StepCard: FC<StepCardProps> = ({ iconSrc, headerText, onBackAction, children }) => (
  <Container>
    {onBackAction && (
      <ButtonContainer>
        <IconButton onClick={onBackAction}>
          <ArrowBack />
        </IconButton>
      </ButtonContainer>
    )}
    <CardHeader iconSrc={iconSrc} headerText={headerText} />
    <CardBody>{children}</CardBody>
  </Container>
);

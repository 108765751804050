import { PhoneNumberUtil } from 'google-libphonenumber';
import * as Yup from 'yup';

export const phoneUtil = PhoneNumberUtil.getInstance();
export const AvailableCountries = ['us', 'ca'];

export const isPhoneNumberValid = (phone: string, notRequired?: boolean): boolean => {
  try {
    if (notRequired && !phone) {
      return true;
    }
    return phoneUtil.isValidNumber(phoneUtil.parse(phone));
  } catch (e) {
    return false;
  }
};

export const yupOptionalPhoneFieldSchema = Yup.string()
  .optional()
  .test('validate-phone', 'Phone number is invalid', (value: any) => isPhoneNumberValid(value, true));

const ACCEPT_NUMBERS_AND_PLUS = /[^\d|+]/g;

export const clearPhoneRedundantCharacters = (phone: string) => phone.replace(ACCEPT_NUMBERS_AND_PLUS, '');

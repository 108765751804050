import React from 'react';
import { TextField } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField/TextField';
import styled from '@emotion/styled';

export const StyledTextField = ({ label, placeholder, value, className, inputProps, ...props }: TextFieldProps) => {
  return (
    <React.Fragment>
      <TextField
        variant="outlined"
        value={value}
        label={placeholder}
        placeholder={label?.toString() ?? (placeholder || '')}
        inputProps={{ style: { paddingBottom: 16, fontFamily: 'Aeonik', letterSpacing: 0.7, ...inputProps?.style } }}
        margin={label ? 'none' : 'normal'}
        {...props}
        className={className}
      />
    </React.Fragment>
  );
};

StyledTextField.upperCase = styled(StyledTextField)({
  input: {
    textTransform: 'uppercase',
  },
});

import React, { useEffect } from 'react';
import { QualificationFooter } from './QualificationFooter';
import { QualificationHeader } from './QualificationHeader';
import { QualificationSectionWrapper } from './QualificationSectionWrapper';
import styled from '@emotion/styled';
import { Theme } from '../../theme';
import { flexCenterCenter } from '../../styles/common';
import { ErrorPage } from '../../components/ErrorPage/ErrorPage';
import { InfoPage } from '../../components/InfoPage/InfoPage';
import { observer } from 'mobx-react';
import { useStore } from '../../stores/setupContext';
import { QualificationStepper, QualificationType } from './QualificationStepper';
import { LoadingOverlay } from '../../ui-lib/Loader/LoadingOverlay';
import { Redirect, Route, Switch } from 'react-router-dom';
import history from './../../routing/history';
import { autorun } from 'mobx';
import { QualificationStatus, QualificationStep } from '../../stores/QualificationStore';
import hexToRgba from 'hex-to-rgba';
import { device } from '../../utils/devices/devices.utils';
import { useMobile } from '../../utils/mobile.hook';
import { PoweredByBalance } from './PoweredByBalance';
import { BuyerQualification } from '../../api/api.schema';

const getStepDescription = (isSmb: boolean, buyerQualification: BuyerQualification | null, name: string): string => {
  if (isSmb) {
    return 'Link your bank account and complete a quick form to pay for your purchases on net terms';
  }
  if (buyerQualification?.merchant.qualificationWithPlaid) {
    return `Link your bank in a few quick steps to pay with terms on ${name}`;
  }
  return `Fill out a quick form to pay with terms on ${name}`;
};

const getMobileStepDescription = (isSmb: boolean): string => {
  return isSmb
    ? 'Link your bank account and complete a quick form to pay for your purchases on net terms'
    : 'Link your bank account or provide basic business information to qualify for net terms';
};

export const CenteredContainer = styled('div')<{ theme: Theme }>(() => ({
  ...flexCenterCenter,
  margin: '0 auto',
  padding: '0 2rem',
  height: '100%',
  width: '100%',
  [`@media ${device.mobileL}`]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: '0px',
  },
}));

export const LayoutContainer = styled('div')<{ theme: Theme }>(({ theme }) => ({
  backgroundColor: theme.palette.main.purpleGray,
  height: '100%',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  minWidth: 1000,
  overflowX: 'scroll',
  overflowY: 'scroll',
  [`@media ${device.mobileL}`]: {
    minWidth: 0,
  },
}));

export const BackgroundCircleContainer = styled('div')<{ theme: Theme }>(({ theme }) => ({
  position: 'absolute',
  height: '100%',
  width: '100%',
  animation: 'colorChange 5s infinite alternate',
  background: `radial-gradient(circle at left, ${hexToRgba(theme.palette.main.link, 0.1)} 45%, transparent 0%)`,
  '@media only screen and (max-width: 992px)': {
    background: `none`,
  },
  pointerEvents: 'none',
}));

export const QualificationContainer = styled('div')({
  [`@media ${device.mobileL}`]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
  },
});

export const BasicQualificationFlow = observer(() => {
  const isMobile = useMobile();
  const { qualification } = useStore();
  const { status, step, buyerQualification, setStatusStep, onBankLinkExit, tryAgain } = qualification;
  const {
    businessDomain = '',
    merchantDomain = '',
    coverPhoto = '',
    name = '',
    logo = '',
  } = buyerQualification?.merchant || {};
  const buyerName = buyerQualification?.buyerName;
  const sellerSiteDomain = businessDomain || merchantDomain;
  const isSmb = buyerQualification?.qualificationType === QualificationType.SMB;

  useEffect(() => {
    if (window.Intercom) {
      window.Intercom('boot', {
        app_id: 'uf33s4yh',
      });
    }
    return () => {
      dispose();
    };
  }, []);

  useEffect(() => {
    const unListen = history.listen(() => {
      handleStatusStepLocationChange();
    });
    return () => {
      unListen();
    };
  }, []);

  const handleStatusStepLocationChange = () => {
    const [ignore, pathStatus, pathStep] = window.location.pathname.split('/');
    if (pathStatus !== status || pathStep !== pathStep) {
      setStatusStep(pathStatus as QualificationStatus, pathStep as QualificationStep, true);
    }
    if (status !== 'started' && step !== 'link-bank') {
      removePlaidIframes();
    }
  };

  const onStarted = () => {
    qualification.start();
  };

  const removePlaidIframes = () => {
    try {
      const iframes = document.querySelectorAll("iframe[id^='plaid-link-iframe']");
      if (iframes.length > 0) {
        for (let i = 0; i < iframes.length; i++) {
          iframes[i]?.parentNode?.removeChild(iframes[i]);
        }
        onBankLinkExit();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const dispose = autorun(() => {
    if (status !== 'started' && step !== 'link-bank') {
      removePlaidIframes();
    }
  });

  const title = `Qualify for terms on ${name}`;
  const description = getStepDescription(isSmb, buyerQualification, name);
  const mobileTitle = `Qualify for terms`;
  const mobileDescription = getMobileStepDescription(isSmb);

  return (
    <>
      {(status === 'completed' || status === 'pending') && (
        <BackgroundCircleContainer>&nbsp;</BackgroundCircleContainer>
      )}
      {!isMobile && (
        <QualificationHeader
          backEnabled={status === 'started' && step !== 'success' && step !== 'retail-buyer'}
          logoEnabled={status === 'started' && step === 'retail-buyer'}
          logo={logo}
        />
      )}
      <CenteredContainer>
        <QualificationSectionWrapper>
          {status === 'loading' ? (
            <LoadingOverlay />
          ) : (
            <Switch>
              <Route key={'pending'} path={'/pending'}>
                <QualificationContainer>
                  <InfoPage
                    logo={logo}
                    buyerName={buyerName}
                    coverPhotoUrl={coverPhoto}
                    title={isMobile ? mobileTitle : title}
                    description={isMobile ? mobileDescription : description}
                    siteDomain={businessDomain || merchantDomain}
                    onStarted={onStarted}
                  />
                  {isMobile && <PoweredByBalance />}
                </QualificationContainer>
              </Route>
              <Route key={'started'} path={'/started'}>
                <QualificationStepper
                  status={status}
                  step={step ?? 'initial'}
                  vendorName={name}
                  siteDomain={sellerSiteDomain}
                />
              </Route>
              <Route key={'error'} path={'/error'}>
                <ErrorPage tryAgain={tryAgain} logoText={buyerName} logo={logo} />
              </Route>
              <Redirect from="/" to="/error" />
            </Switch>
          )}
        </QualificationSectionWrapper>
      </CenteredContainer>
      <QualificationFooter />
    </>
  );
});

import React from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { Box, Grid } from '@material-ui/core';
import { StyledTextField } from '../../ui-lib/TextField/StyledTextField';
import { QualificationButton } from './QualificationButton';
import { flexCenterCenter } from '../../styles/common';
import styled from '@emotion/styled';
import { Theme } from '../../theme';
import { PlacesAddressAutocomplete } from '../../ui-lib/Address';
import { FileUploadDropzone, FileUploadDropzoneProps } from './FileUploadDropzone';
import { DNBTextMask, InputTextMask } from '../../components/Fields/text.input.masks';
import { FileStatusChipContainer, UploadingFile } from './QualificationStepper';
import { FileStatusChip } from './FileStatusChip';
import { yupEINFieldSchema } from '../../apps/VendorOnboarding/src/forms/ein.validation.util';

export interface BusinessDetailsFormFields extends BusinessAddress {
  businessName: string;
  dnb?: string;
  ein?: string;
}

export interface AddressBase {
  state: string;
  city: string;
  zipCode: string;
  country: string;
  countryCode?: string;
}

export interface BusinessAddress extends AddressBase {
  address1: string;
  address2?: string;
  formattedAddress?: string;
  line1?: string;
}

export interface OwnerAddress {
  ownerAddress: string;
  ownerAddress2: string | undefined;
  ownerCity: string;
  ownerState: string;
  ownerZipCode: string;
}

export interface BillingAddress extends AddressBase {
  addressLine1: string;
  addressLine2?: string;
}

export interface BusinessDetailsFormProps {
  onSubmit: (fields: BusinessDetailsFormFields) => Promise<any>;
  onDeleteFile: (fileName: string) => Promise<void>;
  qualificationFiles: UploadingFile[];
  buyerAddress?: BillingAddress;
  buyerName?: string;
}

export const FormFooterContainer = styled('div')<{ theme: Theme }>(({ theme }) => ({
  paddingTop: 50,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  height: 100,
}));

export const FormContainer = styled('div')<{ theme: Theme }>(({ theme }) => ({
  ...flexCenterCenter,
  width: '100%',
}));
export const FormCenteredContainer = styled('div')<{ theme: Theme }>(({ theme }) => ({
  maxWidth: 550,
}));

export const BusinessDetailsForm = ({
  onSubmit,
  onFileRejected,
  onFileAdded,
  onDeleteFile,
  qualificationFiles,
  buyerAddress,
  buyerName,
}: BusinessDetailsFormProps & FileUploadDropzoneProps) => {
  const createValidationSchema = () =>
    Yup.object().shape({
      businessName: Yup.string().required('Required'),
      ein: yupEINFieldSchema,
      dnb: Yup.string()
        .transform((value) => (!value ? null : value))
        .nullable()
        .trim()
        .matches(/^\d{2}-\d{3}-\d{4}$/, 'Please enter a valid Duns & Bradstreet number'),
      address1: Yup.string().required('Required'),
      address2: Yup.string().nullable(),
      city: Yup.string().required('Required'),
      state: Yup.string().required('Required'),
      zipCode: Yup.string().required('Required'),
      country: Yup.string().required('Required'),
    });

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { isSubmitted, isValid },
  } = useForm<BusinessDetailsFormFields>({
    resolver: yupResolver(createValidationSchema()),
    shouldFocusError: true,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      businessName: buyerName || '',
      state: buyerAddress?.state || '',
      city: buyerAddress?.city || '',
      zipCode: buyerAddress?.zipCode || '',
      address1: buyerAddress?.addressLine1 || '',
      address2: buyerAddress?.addressLine2,
      country: buyerAddress?.countryCode || '',
    },
  });

  const renderFileUploadChips = () => {
    return qualificationFiles.map((file) => (
      <FileStatusChip
        key={file.name}
        name={file.name}
        status={file.status}
        message={file.message}
        onDeleteClicked={onDeleteFile}
      />
    ));
  };

  const setSelectedAddressSuggestionValues = (address: any) => {
    Object.entries(address as any).forEach((entry) => {
      const [key, value] = entry;
      if (!value) {
        setValue(key as keyof BusinessDetailsFormFields, '');
      } else {
        setValue(key as keyof BusinessDetailsFormFields, value as string, { shouldValidate: true });
      }
    });
  };

  const onFinishClicked = (data: BusinessDetailsFormFields) => {
    data.ein = data.ein?.replace('-', '');
    onSubmit(data);
  };

  return (
    <FormContainer>
      <FormCenteredContainer>
        <form id={'qualification-business-details'} onSubmit={handleSubmit(onFinishClicked)} noValidate={true}>
          <Grid container spacing={6} style={{ maxWidth: 800 }}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="businessName"
                render={({ field, fieldState: { error } }) => (
                  <StyledTextField
                    required
                    {...field}
                    margin="normal"
                    fullWidth
                    variant="standard"
                    placeholder="Company Name"
                    error={Boolean(error)}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="ein"
                render={({ field, fieldState: { error } }) => (
                  <StyledTextField
                    {...field}
                    required
                    margin="normal"
                    fullWidth
                    variant="standard"
                    placeholder="EIN"
                    label="Employer Identification Number"
                    error={Boolean(error)}
                    helperText={error?.message}
                    InputProps={{
                      inputComponent: InputTextMask,
                      inputRef: field.ref,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="dnb"
                render={({ field, fieldState: { error } }) => (
                  <StyledTextField
                    {...field}
                    margin="normal"
                    fullWidth
                    variant="standard"
                    placeholder="D&B Number"
                    label="Dun & Bradstreet Number"
                    error={Boolean(error)}
                    helperText={error?.message}
                    InputProps={{
                      inputComponent: DNBTextMask,
                      inputRef: field.ref,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                control={control}
                name="address1"
                render={({ field, fieldState: { error } }) => (
                  <PlacesAddressAutocomplete
                    required
                    restrictCountries={['us', 'ca']}
                    {...field}
                    defaultValue={field.value}
                    onAddressSelected={setSelectedAddressSuggestionValues}
                    error={Boolean(error)}
                    helperText={error?.message}
                    onValueChanged={(newValue: string) => setValue('address1', newValue)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="address2"
                render={({ field, fieldState: { error } }) => (
                  <StyledTextField
                    {...field}
                    margin="normal"
                    fullWidth
                    variant="standard"
                    placeholder="Address line 2"
                    error={Boolean(error)}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="city"
                render={({ field, fieldState: { error } }) => (
                  <StyledTextField
                    required
                    {...field}
                    margin="normal"
                    fullWidth
                    variant="standard"
                    placeholder="City"
                    error={Boolean(error)}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="state"
                render={({ field, fieldState: { error } }) => (
                  <StyledTextField
                    required
                    {...field}
                    margin="normal"
                    fullWidth
                    variant="standard"
                    placeholder="State"
                    error={Boolean(error)}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="zipCode"
                render={({ field, fieldState: { error } }) => (
                  <StyledTextField
                    required
                    {...field}
                    margin="normal"
                    fullWidth
                    variant="standard"
                    placeholder="Zipcode"
                    error={Boolean(error)}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="country"
                render={({ field, fieldState: { error } }) => (
                  <StyledTextField
                    required
                    {...field}
                    margin="normal"
                    fullWidth
                    variant="standard"
                    placeholder="Country"
                    error={Boolean(error)}
                    helperText={error?.message}
                  />
                )}
              />
            </Grid>
            {/*<Grid item xs={12}>*/}
            {/*  <FileUploadDropzone onFileAdded={onFileAdded} onFileRejected={onFileRejected} />*/}
            {/*  <Box pt={3}>*/}
            {/*    <FileStatusChipContainer>{renderFileUploadChips()}</FileStatusChipContainer>*/}
            {/*  </Box>*/}
            {/*</Grid>*/}
          </Grid>
          <FormFooterContainer>
            <QualificationButton type="submit" data-analytics-id="qualification-finish">
              Finish
            </QualificationButton>
          </FormFooterContainer>
        </form>
      </FormCenteredContainer>
    </FormContainer>
  );
};

import { observer } from 'mobx-react';
import * as React from 'react';
import CheckoutStore from '../../stores/CheckoutStore';
import { useStore } from '../../stores/setupContext';
import { PlaidHost } from '../../components/PlaidHost/PlaidHost';
import CheckoutLayout from '../../components/CheckoutLayout/CheckoutLayout';
import Header from './Header/Header';
import ButtonGroup from './ButtonGroup/ButtonGroup';
import styled from '@emotion/styled';
import { Theme } from '../../theme';
import Summary from './Review/Review';
import ElectronicQuote from './ElectroniceQuote/ElectronicQuote';
import PayWithTermsResponse from './PayWithTermsResponse/PayWithTermsResponse';
import AfterPay from './AfterPay/AfterPay';
import PayLater from './PayLater/PayLater';
import HowToPaySelection from './HowToPaySelection/HowToPaySelection';
import StripeCreditCard from './StripeCreditCard/StripeCreditCard';
import BankAccounts from './BankAccounts/BankAccounts';
import LinkBank from './LinkBank/LinkBank';
import AfterInvoiceCreated from './AfterInvoiceCreated/AfterInvoiceCreated';
import AfterQuoteCreated from './AfterQuoteCreated/AfterQuoteCreated';
import { StandAlone } from './StandAlone/StandAlone';
import { WithTheme } from '../../wrappers';
import { useMerchantTheme } from '../../apps/hooks/useMerchantTheme';
import { MuiThemeProvider } from '@material-ui/core';

export interface CheckoutManagerProps {
  currentPhase: CheckoutStore['currentPhase'];
  checkoutMode: CheckoutStore['checkoutMode'];
  isLoading?: boolean;
}

export const WrapperIframe = styled('div')<{ theme: Theme }>(({ theme }) => ({
  height: '100%',
  backgroundColor: 'rgba(0,0,0,0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const CheckoutManager: React.FunctionComponent<CheckoutManagerProps> = ({
  currentPhase,
  isLoading,
  checkoutMode,
}) => {
  const iframeMode = checkoutMode === 'iframe';
  const Wrapper = iframeMode ? WrapperIframe : StandAlone;

  return (
    <Wrapper>
      <CheckoutLayout
        {...{
          isLoading,
          header: <Header />,
          content: (
            <>
              {currentPhase === 'howToPaySelection' && <HowToPaySelection {...{}} />}
              {currentPhase === 'creditCardManagement' && <StripeCreditCard {...{}} />}
              {currentPhase === 'bankAccountManagement' && <BankAccounts {...{}} />}
              {currentPhase === 'plaid' && <PlaidHost {...(iframeMode ? { mode: 'standalone' } : {})} />}
              {currentPhase === 'payLater' && <PayLater {...{}} />}
              {currentPhase === 'linkBank' && <LinkBank {...{}} />}
              {currentPhase === 'summary' && <Summary {...{}} />}
              {currentPhase === 'electronicQuote' && <ElectronicQuote />}
              {currentPhase === 'payWithTermsResponse' && <PayWithTermsResponse />}
              {currentPhase === 'afterPay' && <AfterPay />}
              {currentPhase === 'afterInvoiceCreated' && <AfterInvoiceCreated />}
              {currentPhase === 'afterQuoteCreated' && <AfterQuoteCreated />}
            </>
          ),

          buttonGroup: <ButtonGroup />,
        }}
      />
    </Wrapper>
  );
};

const Observed = observer(CheckoutManager);

const WithStoreConnection = () => {
  const { checkout } = useStore();
  const { start, currentPhase, loading, checkoutMode, colorOverrides } = checkout;

  const [theme, muiTheme] = useMerchantTheme(colorOverrides);

  React.useEffect(() => {
    start();
  }, []);

  return (
    <WithTheme theme={theme}>
      <MuiThemeProvider theme={muiTheme}>
        <Observed {...{ currentPhase, isLoading: loading, checkoutMode }} />
      </MuiThemeProvider>
    </WithTheme>
  );
};

export default observer(WithStoreConnection);

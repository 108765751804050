import * as React from 'react';
import styled from '@emotion/styled';
import { Radio, Typography } from './../../../ui-lib';
import { Theme } from './../../../theme';
import { flexCenterCenter, flexCenterStart, flexStartCenter } from './../../../styles/common';
import { observer } from 'mobx-react';
import { useStore } from './../../../stores/setupContext';
import CheckoutStore from './../../../stores/CheckoutStore';
import Options from './../../../components/Options/Options';
import Option from './../../../components/Option/Option';

export interface ElectronicQuoteProps {
  electronicQuoteState: CheckoutStore['electronicQuoteState'];
  setElectronicQuoteState: CheckoutStore['setElectronicQuoteState'];
}

export const TextContainer = styled('div')<{ theme: Theme }>(({ theme }) => ({
  ...flexCenterCenter,
  flexDirection: 'column',
  marginBottom: theme.gutters.base * 4,
}));

export const TextArea = styled('textarea')<{ theme: Theme }>(({ theme }) => ({
  width: '100%',
  border: `1px solid ${theme.palette.main.border}`,
  padding: theme.gutters.base,
  fontFamily: theme.typography.fontFamily,
  marginTop: theme.gutters.base,
  ...theme.typography.meta.body1,
}));

const options: Array<{
  key: string;
  label: string;
  iconSrc: string;
  value: boolean;
}> = [
  {
    key: '1',
    label: 'Yes',
    iconSrc: '',
    value: true,
  },
  {
    key: '0',
    label: 'No',
    iconSrc: '',
    value: false,
  },
];

export const OptionContainer = styled(Option)<{ theme: Theme }>(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
}));

export const Label = styled(Typography)<{ theme: Theme }>(({ theme }) => ({
  width: '100%',
  ...flexStartCenter,
}));

export const ElectronicQuote: React.FunctionComponent<ElectronicQuoteProps> = ({
  electronicQuoteState,
  setElectronicQuoteState,
}) => {
  const { shouldContactSales, msg } = electronicQuoteState;

  return (
    <div>
      <TextContainer>
        <Typography
          {...{
            marginBottom: 16,
            bolder: true,
            variant: 'h1',
            component: 'h3',
          }}
        >
          REQUEST ELECTRONIC QUOTE
        </Typography>
        <Typography {...{ variant: 'caption' }}>
          We'll send you an email with your cart summary and a forwardable link to complete the order. Your quote will
          be valid for 30 days.
        </Typography>
      </TextContainer>

      <Typography {...{ variant: 'body1Bold', style: { marginBottom: 28 } }}>
        Do you also need to follow up with our sales team?
      </Typography>

      <Options>
        {options.map((option, ind) => {
          return (
            <OptionContainer
              {...{
                key: option.key,
                isSelected: typeof shouldContactSales === 'boolean' && Boolean(shouldContactSales) === option.value,
                onClick: () => {
                  setElectronicQuoteState({
                    shouldContactSales: option.value,
                  });
                },
              }}
            >
              <Label>{option.label}</Label>
              {option.value === true && shouldContactSales && (
                <TextArea
                  {...{
                    placeholder: '(Optional) Type your question / message for the sales team',
                    value: msg,
                    onChange: (e) => setElectronicQuoteState({ msg: e.target.value }),
                  }}
                />
              )}
            </OptionContainer>
          );
        })}
      </Options>
      <Typography {...{ variant: 'captionBold', marginTop: 16 }}>Your quote will be vaild for 30 days.</Typography>
    </div>
  );
};

const Observed = observer(ElectronicQuote);

const WithStoreConnection = () => {
  const { checkout } = useStore();

  const { electronicQuoteState, setElectronicQuoteState } = checkout;

  return <Observed {...{ electronicQuoteState, setElectronicQuoteState }} />;
};

export default observer(WithStoreConnection);

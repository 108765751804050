import {
  Image,
  QualificationHeading,
  QualificationMessageContainer,
  QualificationMessageContent,
  QualificationStepContainer,
  QualificationStepDescription,
} from '../../sections/qualification/common';
import { QualificationButton } from '../../sections/qualification/QualificationButton';
import React, { VFC } from 'react';
import { ContactSupport } from '../../sections/qualification/ContactSupport';
import { ErrorIcon, MobileErrorIcon } from '../../icons/Error/Error';
import { useMobile } from '../../utils/mobile.hook';
import { CompanyName } from '../InfoPage/InfoPage';
import { PoweredByBalance } from '../../sections/qualification/PoweredByBalance';
import { cond, constant, stubTrue } from 'lodash/fp';

export interface ErrorPageProps {
  title?: string;
  message?: string;
  tryAgain?: VoidFunction;
  logo?: string | undefined;
  logoText?: string;
}

const getTitle = (isMobile: boolean, title?: string) => {
  return cond([
    [() => !!title, constant(title)],
    [() => isMobile, constant('We’ve ran into a problem')],
    [stubTrue, constant('We have a problem...')],
  ])({});
};

const getDescription = (isMobile: boolean, message?: string) => {
  return cond([
    [() => !!message, constant(message)],
    [() => isMobile, constant('Please try again or contact support')],
    [stubTrue, constant('Please try again later or contact support.')],
  ])({});
};

export const ErrorPage: VFC<ErrorPageProps> = ({ title, tryAgain, logoText, logo, message }) => {
  const isMobile = useMobile();

  return (
    <QualificationStepContainer>
      <QualificationMessageContainer>
        {isMobile && <CompanyName withBorder>{logo ? <Image src={logo} /> : logoText}</CompanyName>}

        {isMobile ? <MobileErrorIcon /> : <ErrorIcon />}
        <QualificationHeading>{getTitle(isMobile, title)}</QualificationHeading>
        <QualificationStepDescription>{getDescription(isMobile, message)}</QualificationStepDescription>
        <QualificationMessageContent>
          {!isMobile && (
            <QualificationStepDescription>
              Need help? <ContactSupport>contact support</ContactSupport>
            </QualificationStepDescription>
          )}
          <QualificationButton onClick={tryAgain}>{isMobile ? 'TRY AGAIN' : 'Try again'}</QualificationButton>
        </QualificationMessageContent>
      </QualificationMessageContainer>
      {isMobile && <PoweredByBalance />}
    </QualificationStepContainer>
  );
};

import * as React from 'react';
import { SVGIconProps } from '../SVGIcon/SVGIcon';
import Typography from '../Typography/Typography';

export interface DotProps {
  color?: SVGIconProps['color'];
}

export const Dot: React.FunctionComponent<DotProps> = ({ color = 'gamma' }) => {
  return <Typography {...{ component: 'span', variant: 'label', color, style: { fontSize: 5 } }}>&#9679;</Typography>;
};

export default Dot;
